import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DWCWidgetComponent} from "../../core/model/dwc-widget.model";
import {TranslationHelper} from "../../core/util/translation.helper";

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteComponent implements OnInit, DWCWidgetComponent {

  id: number;
  type: string;
  data: any;
  preventUnsubscribe?: boolean;
  colSizes: object;

  constructor(
    public translationHelper: TranslationHelper
  ) {
  }

  ngOnInit() {}

}
