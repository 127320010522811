/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import { Observable }                                        from 'rxjs';
import { LectureEventsRead }                                 from '../model/lectureEventsRead';
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LectureService {

    protected basePath = 'https://eventapi.dwbn.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        protected httpClient: HttpClient,
        @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves the collection of Lecture resources.
     *
     */
    public getLectureCollection(params: Record<string, any>, observe?: 'body', reportProgress?: boolean): Observable<Array<LectureEventsRead>>;
    public getLectureCollection(params: Record<string, any>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LectureEventsRead>>>;
    public getLectureCollection(params: Record<string, any>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LectureEventsRead>>>;
    public getLectureCollection(params: Record<string, any>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        Object.keys(params).forEach((param) => {
            const val = params[param];
            if (val !== undefined && val !== null) {

                if (Array.isArray(val)) {
                    val.forEach((singleVal) => {
                        queryParameters = queryParameters.append(param + '[]', singleVal);
                    })
                } else {
                    queryParameters = queryParameters.set(param, val);
                }

            }
        })

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LectureEventsRead>>(`${this.basePath}/api/v1/lectures`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Retrieves a Lecture resource.
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLectureItem(id: string, observe?: 'body', reportProgress?: boolean): Observable<LectureEventsRead>;
    public getLectureItem(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LectureEventsRead>>;
    public getLectureItem(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LectureEventsRead>>;
    public getLectureItem(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getLectureItem.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LectureEventsRead>(`${this.basePath}/api/v1/lectures/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Retrieves a Lecture resource via teh source - this is a static link.
     *
     * @param sourceId
     * @param sourceType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLectureSourceItem(sourceId: string, sourceType: string, observe?: 'body', reportProgress?: boolean): Observable<LectureEventsRead>;
    public getLectureSourceItem(sourceId: string, sourceType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LectureEventsRead>>;
    public getLectureSourceItem(sourceId: string, sourceType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LectureEventsRead>>;
    public getLectureSourceItem(sourceId: string, sourceType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (!sourceId || !sourceType) {
            throw new Error('Required parameters sourceId and sourceType needs to be given.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LectureEventsRead>>(`${this.basePath}/api/v1/lectures/${encodeURIComponent(String(sourceType))}/${encodeURIComponent(String(sourceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    public getInternationalLectures(): any{
        let headers = this.defaultHeaders;
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        return this.httpClient.get(`${this.basePath}/api/v1/lectures`,
            {
                params: {international: 'true'},
                headers,
                withCredentials: this.configuration.withCredentials,
                observe: 'body',
                reportProgress: false
            }
        );
    }
}
