// Use Native name in any language ....
export const availableLanguages = [
  {
    code: 'en',
    name: 'English'
  }

  /*
  // removed also de for now, as it is causing quite some confusions
  {
    code: 'de',
    name: 'Deutsch'
  },
  {
    code: 'pl',
    name: 'Polski'
  },
  {
    code: 'cs',
    name: 'Česko'
  },
  {
    code: 'ru',
    name: 'Pусский'
  },
  {
    code: 'es',
    name: 'Español'
  }
 */
] as {code: string, name: string}[];

export const supportedLanguages = ['be', 'bg', 'cs', 'da', 'de', 'en', 'es', 'fi', 'fr', 'gr', 'he', 'hu', 'it', 'lt', 'lv', 'nl', 'pl', 'pt', 'ro', 'ru', 'sr'];

export const defaultLanguage = 'en';
