import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_NAMED_OPTIONS} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {EnvironmentService} from "../core/services/environment.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {TokenInterceptor} from "../core/auth/token-interceptor";
import {AppModelFactoryLink} from "../core/util/app-model-factory.link";

@NgModule({
  exports: [ApolloModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink, env: EnvironmentService) => {
        return {
          app: {
            cache: new InMemoryCache(),
            link: AppModelFactoryLink.concat(httpLink.create({
              uri: env.currentEnv.backendBasePath + '/graphql/graphql/app'
            }))
          },
          sso: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: env.currentEnv.backendBasePath + '/graphql/graphql/sso'
            })
          },
          events: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: env.currentEnv.backendBasePath + '/graphql/graphql/events'
            })
          }
        };
      },
      deps: [HttpLink, EnvironmentService]
    },
  ],
})
export class GraphQLModule {}
