import {IColSizes} from '../model/colSizes.model';

const colSizeKeys = ['xs', 'sm', 'md', 'lg', 'xl'];

export function getSubColSizes (
    outerColSizes: IColSizes,
    innerColSizes: IColSizes
) {
    let currentOuterSize = 12;
    let currentTileSize = 12;

    const subColSizes = {};

    colSizeKeys.forEach(key => {

        let hasEntry = false;

        if (outerColSizes[key]) {
            currentOuterSize = outerColSizes[key];
            hasEntry = true;
        }

        if (innerColSizes[key]) {
            currentTileSize = innerColSizes[key];
            hasEntry = true;
        }

        if (hasEntry) {
            subColSizes[key] = Math.ceil(currentOuterSize * currentTileSize / 12);
        }
    });

    return subColSizes;
}

export function getColSizeClasses (colSizes: IColSizes): string {
  return Object.keys(colSizes).map(colSize => {
    return `col-${colSize != 'xs' ? colSize + '-' : ''}${colSizes[colSize]}`;
  }).join(' ');
}
