import {IWidgetDefinition} from "../../core/model/widget.model";
import iconSelect from '../../core/util/forms/icons.select';
import {ChildPagesComponent} from "./child-pages.component";

const ifIsGrid = (fieldValues) => {
  return fieldValues.itemDisplay === 'grid';
};

const ifIsTable = (fieldValues) => {
  return fieldValues.itemDisplay === 'table';
};

export default {
  getComponent: () => ChildPagesComponent,
  category: 'structure',
  icon: {style: 'fa-solid', name: 'folder-tree'},

  // the leading definition is childpages
  fields: [
    iconSelect,
    {
      id: 'headline',
      input: 'text',
      i18n: true,
      width: 8
    },
    {
      id: 'description',
      input: 'html',
      i18n: true
    },
    {
      id: 'itemDisplay',
      input: 'select',
      options: [
        { label: 'widgets.names.grid', value: 'grid' },
        { label: 'forms.labels.table', value: 'table' }
      ],
      default: 'grid',
      width: 4,
      required: true,
    },
    {
      id: 'cols',
      label: 'How many items per row?',
      input: 'number',
      default: 3,
      width: 4,
      required: true,
      depends: ifIsGrid
    },
    {
      id: 'limit',
      description: 'forms.descriptions.limit',
      input: 'number',
      default: 0,
      width: 4
    },
    {
      id: 'aspectRatio',
      description: 'Image aspect ratio (height / width)',
      input: 'number',
      default: .6,
      width: 4,
      depends: ifIsGrid
    },
    {
      id: 'sortBy',
      input: 'select',
      options: [
        { label: 'Creation Date (Descending)', value: 'created:desc' },
        { label: 'Creation Date (Ascending)', value: 'created:asc' },
        { label: 'Title (Descending)', value: 'title:desc' },
        { label: 'Title (Ascending)', value: 'title:asc' },
      ],
      default: 'title:desc',
      width: 4,
      required: true,
    },
  ]
} as IWidgetDefinition;
