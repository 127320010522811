import { Component, OnInit } from '@angular/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import {TranslationHelper} from '../../core/util/translation.helper';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: [],
})
export class TextComponent implements OnInit, DWCWidgetComponent {

  data: any;
  id: number;
  type: string;

  colSizes: object; // information how wide the wrapper column of the component is

  headline: string;
  icon: string;

  constructor(
      public translationHelper: TranslationHelper
  ) { }

  ngOnInit() {
    this.headline = this.translationHelper.getBestLanguageValue(this.data.headline);
    this.icon = this.data.icon;
  }

}
