import { Injectable } from '@angular/core';
import { IPost } from 'src/app/core/model/post.model';

@Injectable({
  providedIn: 'root'
})

export class ImageTilingHelper {

  /* Assuming the images from a post are laid out as a CSS grid,
  this helper sets the grid properties and aspect ratios
  to make interesting tilings */

  constructor() {}

  subsetCutoff(post: IPost) {
    // number of images to show on the Newsfeed before the "more..." affordance
    // the "more" affordance appears in the n + 1 position
    return this.firstImageShouldBeTall(post) ? 4 : 3
  }

  rowClass(post: IPost, imageIndex: number) {
    if (this.firstImageShouldBeTall(post) && imageIndex == 0) {
      return 'h-100'
    } else if (this.firstImageShouldBeExtraTall(post) && imageIndex == 0) {
      return 'h-100'
    } else return 'h-50'
  }

  firstImageShouldBeTall(post) {
    const numberOfImages = post.images.length
    const odd = numberOfImages % 2
    return (numberOfImages > 1 && odd)
  }

  firstImageShouldBeExtraTall(post) {
    const numberOfImages = post.images.length
    const odd = numberOfImages % 2
    const even = !odd
    return (numberOfImages > 3 && even)
  }




  subsetOfImages(post: IPost) {
    return this.showSubsetOfImages(post) ? post.images.slice(0,this.subsetCutoff(post)) : post.images
  }

  showSubsetOfImages(post: IPost) {
    const numberOfImages = post.images.length
    return numberOfImages > this.subsetCutoff(post) ? true : false
  }

  numberOfImagesAfterCutoff(post: IPost) {
    // number to show in the "n more..." affordance when showing a subset of images
    return this.showSubsetOfImages(post) ? post.images.length - this.subsetCutoff(post) : 0
  }

  aspectRatio(post: IPost, imageIndex: number) {
    const numberOfImages = post.images.length
    // const odd = numberOfImages % 2
    // const even = !odd

    if (numberOfImages === 3) {
      return imageIndex == 0 ? 1.3 : 0.642
    } else if (numberOfImages == 2) {
      return 1
    } else if (numberOfImages > 3) {
      return imageIndex == 0 ? 1.55 : 0.507
    } else {
      return 0.67
    }
  }

}
