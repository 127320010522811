import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject} from 'rxjs';
import { IUser} from '../model/user.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {AccessService} from './access.service';
import {RoutingHelper} from '../util/routing.helper';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {

  profileMenu$ = new BehaviorSubject<any[]>([
    {route: '/my-profile', label: 'My Profile'}
  ]);

  userData: IUser
  subscriptionsCount = 0;
  subscriptionBatchCount = 0;

  constructor (
      private userService: UserService,
      private accessService: AccessService,
      private routingHelper: RoutingHelper,
  ) {

    this.userService.user$.subscribe(userData => {
      this.userData = userData;
      this.rebuildMenu();
    });

    this.userService.subscriptionsObservable.subscribe( subscriptions => {
      this.subscriptionsCount = subscriptions.length;
      this.subscriptionBatchCount = subscriptions.filter(subscription => {
        return subscription.status === 'error';
      }).length;
      this.rebuildMenu();
    });
  }

  get profileMenuObservable () {
    return this.profileMenu$.asObservable().pipe(distinctUntilChanged());
  }

  private async rebuildMenu() {

    const menu = [];

    // @todo: review once dw-connect is opened again for guests
    if (await this.accessService.isValidMember()) {

      menu.push({
        route: '/contacts',
        label: 'My Contacts'
      });

      menu.push({
        route: this.routingHelper.getCenterRoute(this.userData.center),
        label: 'My Center'
      });
    }

    menu.push({
      route: '/my-profile',
      label: 'My Profile'
    });

    if (this.subscriptionsCount > 0) {
      menu.push({
        route: '/donations',
        label: 'My Donations',
        batchCount: this.subscriptionBatchCount
      });
    }

    if (await this.accessService.isAdmin()) {
      menu.push({
        route: '/administration',
        label: 'Administration'
      })
    }

    this.profileMenu$.next(menu);
  }


}
