import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-confirmation-popover',
  templateUrl: './confirmation-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationPopoverComponent implements OnInit {
  @Input() text: string;
  @Input() confirm: any;
  @Input() showConfirmButton = true;
  @Input() dismiss: any;

  @Input() collectMessage = false;
  @Input() messagePlaceholder: string;

  constructor() {}

  ngOnInit() {}
}
