import { Component, OnInit } from '@angular/core';
import { DWCWidgetComponent } from 'src/app/core/model/dwc-widget.model';
import { TranslationHelper } from '../../core/util/translation.helper';

const JW_ID = 'jw-player';

@Component({
  selector: 'app-widget-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoWidgetComponent implements OnInit, DWCWidgetComponent {
  id: number;
  type: string;
  data: any;

  colSizes: object;
  description: string;
  maxWidth: string;

  constructor(private translationHelper: TranslationHelper) {}

  ngOnInit() {
    this.description = this.translationHelper.getBestLanguageValue(
      this.data.description
    );
    this.maxWidth = this.data.maxWidth
      ? this.data.maxWidth +
        (this.data.maxWidth.indexOf('px') > -1 ||
        this.data.maxWidth.indexOf('%') > -1
          ? ''
          : 'px')
      : '';
  }
}
