import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IAppVideo } from '../../core/model/app-video.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserService } from '../../core/services/user.service';
import { VideoHelperService } from '../../core/services/video-helper.service';

const JW_ID = 'jw-player';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnInit {
  @Input() video: IAppVideo;
  @Input() id: number;

  videoLink: SafeUrl;

  aspectRatio: string;

  videoType = 'external';
  playerId;

  jwInitialized: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private userProvider: UserService,
    private videoHelper: VideoHelperService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.videoType = this.video.type || 'external';
    let videoSrc = this.video.src;

    console.log(this.video);

    this.userProvider.fetchLoggedInUser().then((user) => {
      if (this.videoType === 'dwbn_jwplayer') {
        this.playerId =
          'video-' + (this.id || Math.round(Math.random() * 1000));

        if (!document.getElementById(JW_ID)) {
          const node = document.createElement('script');
          node.src = 'https://jwpsrv.com/library/P0QJrFQ+EeKCCCIACp8kUw.js';
          node.type = 'text/javascript';
          node.async = true;
          node.id = 'jw-player';
          document.getElementsByTagName('head')[0].appendChild(node);
        }

        this.initializeJwPlayer(() => {
          const config = {
            file: this.video.m3u8,
            // image: 'https://streaming.dwbn.org/images/ittm2015.jpg',
            width: '100%',
            height: '100%',
            // primary: 'flash'
            image: undefined,
            type: undefined,
          };

          if (this.video.m3u8.indexOf('.m3u8') < 0) {
            const cloudRegex = /https:\/\/cloud\.dwbn\.org\/s\/(\w+)$/gi;

            config.type = 'mp4';

            if (cloudRegex.test(this.video.m3u8)) {
              config.file = this.video.m3u8 + '/download';
            }
          }

          if (this.video.cover) {
            config.image = this.video.cover.src;
          }

          // @ts-ignore
          const player = window.jwplayer(this.playerId);

          if (player && player.setup) {
            player.setup(config);
          }
        });
      } else {
        const youTubeId = this.videoHelper.getYouTubeId(videoSrc);

        if (youTubeId) {
          videoSrc = `https://www.youtube-nocookie.com/embed/${youTubeId}?rel=0&showinfo=0&showsearch=0&hl=${user.userData.language}`;
        }
      }

      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(videoSrc);
      this.ref.markForCheck();
    });

    this.aspectRatio = `padding-bottom: ${
      (this.video.aspectRatio || 0.56) * 100
    }%`;
  }

  initializeJwPlayer(callback) {
    // console.log('initializeJwPlayer ' + this.playerId);

    if (!this.jwInitialized) {
      if (
        // @ts-ignore
        typeof window.jwplayer !== 'function' ||
        !document.getElementById(this.playerId)
      ) {
        window.setTimeout(this.initializeJwPlayer.bind(this, callback), 50);
      } else {
        this.jwInitialized = true;
        callback();
      }
    }
  }
}
