import {IWidgetDefinition} from '../../core/model/widget.model';
import languageFieldDef from '../../core/util/forms/language.select';

const languageField = {...languageFieldDef};
languageField.required = true;

const isEBook = (formValues) => {
    return formValues.asEBook === true;
};

const isNotEBook = (formValues) => {
    return formValues.asEBook !== true;
};

export default ({
    fields: [
        {
            id: 'title',
            input: 'text',
            i18n: true,
            required: true
        },
        {
            id: 'asEBook',
            input: 'boolean',
            label: 'Is an E-Book',
            description: 'If checked, pdf and ePub upload fields will be available',
        },
        {
            id: 'subTitle',
            input: 'text',
            i18n: true,
        },
        {
            id: 'author',
            input: 'text',
            required: false
        },
        {
            id: 'availableTypes',
            label: 'Available Types',
            default: 'Hardcover, softcover, eBook',
            input: 'text',
            i18n: true,
            required: false
        },
        {
            id: 'description',
            input: 'html',
            i18n: true,
            required: false
        },
        {
            id: 'languageVersions',
            input: 'complex-list',
            depends: isNotEBook,
            subtype: [
                languageField,

                {
                    id: 'publisher',
                    input: 'text'
                },
                {
                    id: 'isbn',
                    input: 'text'
                },

                {
                    id: 'shopUrl',
                    input: 'text',
                    required: true
                }

            ]
        },
        {
            id: 'image',
            input: 'image_upload',
            i18n: true
        },
        {
            id: 'pdf',
            label: 'PDF File',
            input: 'file_upload',
            depends: isEBook,
            i18n: false
        },
        {
            id: 'epub',
            label: 'epub File',
            input: 'file_upload',
            depends: isEBook,
            i18n: false
        }
    ]
} as IWidgetDefinition);
