import {Injectable } from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {AccessService} from '../services/access.service';

@Injectable({
  providedIn: 'root'
})
export class IsMemberGuard {
  constructor(
      private accessService: AccessService,
      private router: Router
  ) { }

  public async canActivate(): Promise<boolean|UrlTree> {
    const isMember = await this.accessService.isValidMember();

    if (isMember) {
      return true;
    }

    return this.router.parseUrl('/access-denied');
  }
}
