import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-box',
  templateUrl: './icon-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBoxComponent implements OnInit {

  @Input() icon: string;
  @Input() headline: string;
  @Input() noBlock = false;
  @Input() align: 'left' | 'stack' = 'left';

  constructor() { }

  ngOnInit() {}

}
