import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {CenterFragment} from './fragments/center.fragments';
import {ICenter} from '../model/center.model';
import {firstValueFrom} from "rxjs";
import {cloneDeep} from "lodash-es";

const GET_CENTER_MEMBERS = gql`
query appQuery($id: String, $limit: Int){
  center_members (centerId: $id, limit: $limit) {
    count
    members {
      id
      username
      dwbnId
      givenName
      familyName
      enabled
      userData {
        picture {
          url
        }
      }

    }
  }
}`;

// TODO: Make centers query with dynamic params
const GET_CENTERS_QUERY = gql`
  query ssoQuery {
    center_list(limit: 1000, filter: "{\\"andX\\": [{ \\"neq\\": {\\"country\\": null}}, {\\"eq\\": {\\"active\\":1}}, { \\"neq\\": {\\"type\\": 32}}, { \\"neq\\": {\\"type\\": 33}}]}") {
      centers {
        id
        displayName
        type
        slug
        name
        pictures {
          url
        }
        country {
          id
          code
        }
        latitude
        longitude
      }
    }
  }
`;

const GET_ALL_CENTERS_QUERY = gql`
query ssoQuery {
  center_list(limit: 2000){
    centers {
      id
      name
      displayName
      latitude
      longitude
      founded
      active
      lastModified
    }
  }
}
`;

const GET_CENTER_DATA_BY_ID = gql`
  query ssoQuery($id: String) {
    center(id: $id) {
      ...CenterFragment
    }
  }
  ${CenterFragment}
`;

const GET_CENTER_DATA_BY_SLUG = gql`
  query ssoQuery($slug: String) {
    center(slug: $slug) {
      ...CenterFragment
    }
  }
  ${CenterFragment}
`;

@Injectable({
  providedIn: 'root'
})
export class CenterService {

  constructor(
    private apollo: Apollo,
  ) { }

  async getCenters() {
    const result : any = await firstValueFrom(this.apollo
      .use('sso')
      .query({
        query: GET_CENTERS_QUERY
      })
    );
    return result?.data.center_list?.centers;
  }

  async getAllCenters() : Promise<ICenter[]> {
    const result : any = await firstValueFrom(this.apollo
      .use('sso')
      .query({
        query: GET_ALL_CENTERS_QUERY
      }));

    return cloneDeep(result?.data.center_list?.centers);
  }


  async getCenterById(id: string) {
    const result : any = await firstValueFrom(this.apollo
      .use('sso')
      .query({
        query: GET_CENTER_DATA_BY_ID,
        variables: {id}
      }));
    return result?.data?.center as ICenter;
  }

  async getCenterBySlug(slug: string) {
    const result : any = await firstValueFrom(this.apollo
      .use('sso')
      .query({
        query: GET_CENTER_DATA_BY_SLUG,
        variables: {slug}
      }));

    return result?.data?.center as ICenter;
  }

  async getCenterMembers (centerId: string, limit: number) {
    const result : any = await firstValueFrom(this.apollo.use('app').query({
        query: GET_CENTER_MEMBERS,
        variables: {id: centerId, limit}
    }));

    return result?.data?.center_members
  }
}
