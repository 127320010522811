<div class="position-fixed p-4 bottom-0 end-0 z-100" *ngIf="editable">

  <button *ngIf="!editMode" class="btn btn-yellow btn--float" type="button" (click)="structureService.toggleEditMode()">
    <i class="fa-solid fa-pen"></i>
    <span class="ms-2 btn__description">Edit</span>
  </button>

  <button *ngIf="editMode" type="button" class="btn btn-success" (click)="structureService.savePage()">
    <i class="fa-solid fa-check"></i>
    <span class="ms-2">Save</span>
  </button>

  <div *ngIf="editMode" class="btn-group ms-2">

    <button type="button" class="btn btn-light" (click)="structureService.disableEditMode()">
      <i class="fa-solid fa-xmark"></i>
      <span class="ms-2">Cancel</span>
    </button>

    <div class="btn-group" ngbDropdown role="group" placement="top-end" aria-label="Additional Settings and actions of this page">
      <button type="button" class="btn btn-light dropdown-toggle-split rounded-end" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button *ngIf="isEditingProjects" ngbDropdownItem (click)="this.projectService.presentAddMemberModal(currentProject)"><i class="fa-solid fa-user-group"></i> Members</button>
        <button *ngIf="isEditingProjects && projectAdminAllowed" ngbDropdownItem (click)="structureService.editProject()"><i class="fa-solid fa-gear"></i> Project Settings</button>
        <button *ngIf="updateAllowed" ngbDropdownItem (click)="structureService.editPage()"><i class="fa-solid fa-gear"></i> Page Settings</button>
      </div>
    </div>

  </div>

</div>
