
<div *ngIf="display != 'preview'; else preview">
  <div title="{{title}}"
       class="block image-preview"
       [class.image-preview--linked]="data?.linkToPage"
       [class.image-preview--title]="title"
       [class.mx-auto]="data?.maxWidth"
       [style.width]="data?.maxWidth"
       (click)="navigate()"
  >
    <app-img [aspectRatio]="aspectRatio"
             [image]="data?.image"
             [colSizes]="colSizes"
             [linked]="data?.linkToPage"
             width="{{data?.maxWidth || '100%'}}"
             title="{{title}}"
             [roundedCorners]="true"
             [align]="display == 'slide' ? 'alone' : 'center'"
    ></app-img>

    <h5 *ngIf="title" class="image-preview__title">{{ title.substr(0, 50) + (title.length < 50 ? '' : '...')  }}</h5>
  </div>
  <div *ngIf="description" class="fs-7 text-gray" [innerHTML]="description"></div>
</div>

<ng-template #preview>
  <div class="image-preview"
       title="{{title}}"
       (click)="navigate()"
       [class.image-preview--linked]="data?.linkToPage"
       [class.image-preview--title]="title"
  >
    <app-img [aspectRatio]="aspectRatio"
             [image]="data?.image"
             [colSizes]="colSizes"
             [linked]="data?.linkToPage"
             width="{{data?.maxWidth ? data?.maxWidth : '100%'}}"
             title="{{title}}"
             [roundedCorners]="true"
             [align]="'alone'"
    ></app-img>

    <h5 *ngIf="title" class="image-preview__title">{{title}}</h5>

  </div>
</ng-template>
