<app-menu-bar></app-menu-bar>

<div class="page">
  <app-user-alert></app-user-alert>
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>

<app-edit-button *ngIf="initializeEditButton"></app-edit-button>
<!-- renders messages of the api -->
<app-messages></app-messages>
