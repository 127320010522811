import { ProminentLinkComponent } from './prominent-link.component';
import { IWidgetDefinition } from '../../core/model/widget.model';

export default {
  getComponent: () => ProminentLinkComponent,
  category: 'content',
  icon: {style: 'fa-solid', name: 'link'},
  fields: [
    {
      id: 'href',
      label: 'Link to page',
      input: 'href',
      // i18n: true,
    },
      /*
    {
      id: 'title',
      label: 'Enter the title:',
      input: 'text',
      i18n: true,
    },

       */
    // iconSelect,
    {
      id: 'buttonText',
      label: 'Enter the text of the button:',
      input: 'text',
      i18n: true,
    },
    {
      id: 'color',
      input: 'select',
      label: 'Button Color',
      default: 'primary',
      width: 6,
      options: [
        { label: 'Red', value: 'primary' },
        { label: 'Yellow', value: 'secondary' },
        { label: 'Light Gray', value: 'light' },
        { label: 'Medium Gray', value: 'medium' },
        { label: 'Dark Gray', value: 'dark' },
      ]
    },
    {
      id: 'fill',
      input: 'select',
      label: 'Button Style',
      default: 'solid',
      width: 6,
      options: [
        { label: 'Solid', value: 'solid' },
        { label: 'Outline', value: 'outline' },
        { label: 'Text only', value: 'clear' }
      ]
    },
    {
      id: 'align',
      input: 'select',
      width: 6,
      options: [
        { label: 'Left', value: 'align-left' },
        { label: 'Center', value: 'align-center' },
        { label: 'Right', value: 'align-right' },
      ],
    },
    {
      id: 'arrow',
      input: 'select',
      label: 'Arrow',
      default: 'forward',
      width: 6,
      options: [
        { label: '>', value: 'forward' },
        { label: '<', value: 'back' },
        { label: 'None', value: 'none' },
      ],
    },
  ],
} as IWidgetDefinition;


// todo:
// i18n --> linkText
// display icon
// make all icons available
// linkTo: the link - this should be put as href, if it does not start with /
