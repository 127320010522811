<ng-container [ngSwitch]="display">

  <div *ngSwitchCase="'preview'">

    <app-img *ngIf="cover"
             [aspectRatio]="0.6"
             [image]="cover"
             [colSizes]="colSizes"
             [linked]="true"
             title="{{data.title}}"
             [roundedCorners]="true"
             [align]="'alone'"
             routerLink="{{data.route}}"
             class="d-block mb-2"
    ></app-img>

    <div class="mb-2 text-gray">
      {{data.created.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}}
      <span *ngIf="(data.created.getTime() + 600000) < data.lastModified.getTime()" title="last updated {{data.lastModified.toLocaleTimeString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}}"> - updated</span>
    </div>

    <h3 class="h4" [innerHTML]="translationHelper.getBestLanguageValue(data.title)"></h3>

    <p [innerHTML]="preview" class="mb-2"></p>

    <a *ngIf="id && id > 0" href="{{data.route}}" routerLink="{{data.route}}" routerDirection="forward">Read more</a>

  </div>

  <div *ngSwitchCase="'side_preview'">
    <app-img *ngIf="data.images?.length"
             [aspectRatio]="0.6"
             [image]="data.images[0].image"
             [colSizes]="colSizes"
             [linked]="true"
             title="{{data.title}}"
             [roundedCorners]="true"
             [align]="'alone'"
             routerLink="{{data.route}}"
             class="tw-mb-4"
    ></app-img>

    <p>
      {{preview}}<span *ngIf="isShortened">...</span>
    </p>

    <a href="{{data.route}}" routerLink="{{data.route}}">Read more</a>
  </div>

  <div *ngSwitchDefault class="md:tw-max-w-2xl md:tw-mx-auto" >
    <div class="tw-py-3 md:tw-p-12 md:tw-px-24 md:tw-pt-8 news-post-shadow--desktop md:tw-rounded-md md:tw-bg-white">

      <div *ngIf="!data.includeVideo && data.images.length" class="tw-mb-4" [ngClass]="{ 'tw-grid tw-grid-cols-2 tw-gap-1': data.images.length > 1}">
        <div *ngFor="let image of data.images; let imageIndex = index;" [ngClass]="imageTilingHelper.rowClass(data, imageIndex)">
          <app-img [aspectRatio]="imageTilingHelper.aspectRatio(data, imageIndex)"
                   [image]="image.image" width="800px"
                   align="alone"
                   [smartCrop]="true"
                   [roundedCorners]="true"
          ></app-img>
        </div>
      </div>

      <div *ngIf="data.includeVideo" class="tw-mb-4">
        <app-video [video]="video"></app-video>
      </div>

      <div class="tw-text-black tw-leading-tight poppins tw-font-semibold tw-text-2xl tw-mb-3">
        {{ translationHelper.getBestLanguageValue(data.title) }}
      </div>

      <div class="tw-mb-3 tw-text-gray-500 d-flex tw-justify-between">
        <span>
          {{data.created.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}}
          <span *ngIf="(data.created.getTime() + 600000) < data.lastModified.getTime()" title="last updated {{data.lastModified.toLocaleTimeString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}}"> - updated</span>
        </span>
        <span><i class="fa-regular fa-eye me-1"></i> {{data.views}}</span>
      </div>

      <div class="tw-text-gray-700 post-card__content" [innerHTML]="translationHelper.getBestLanguageValue(data.content)"></div>

    </div>
  </div>



</ng-container>
