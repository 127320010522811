import {IWidgetDefinition} from '../../core/model/widget.model';
import iconSelect from '../../core/util/forms/icons.select';
import currencySelect from '../../core/util/forms/currency.select';

const isStripe = (formValues) => {
    return formValues.psp === 'stripe';
};

export default ({

    icon: {style: 'fa-regular', name: 'credit-card'},
    category: 'payment',
    fields: [
        iconSelect,
        {
            id: 'headline',
            input: 'text',
            i18n: true,
            width: 8
        },
        {
            id: 'content',
            label: 'forms.labels.htmlInput',
            input: 'html',
            required: true,
            i18n: true
        },
        {
            id: 'psp',
            input: 'select',
            required: true,
            options: [
                {value: 'stripe', label: 'Stripe'},
            ]
        },

        currencySelect,

        {
            id: 'suggestedAmounts',
            input: 'text',
            label: 'Suggested Amounts (Comma seperated)',
            placeholder: '27,108,...',
            description: 'If you don\'t want to show suggested amount buttons, simply leave this field blank'
        },

        {
            id: 'donationLabel',
            input: 'text',
            i18n: true,
            required: true,
            label: 'Statement Text',
            placeholder: 'Donation for ...',
            description: 'This text will appear on the Bank or Credit Card Statement.'
        },

        {
            id: 'allowDonationReceipt',
            input: 'boolean',
            label: 'Allow Donation Receipts',
            description: 'If checked, this will enable a checkbox for collecting address information which is required for the tax receipt. Please leave it unchecked, if you have nobody who can deal with this. Also it might be only useful for germans...',
        },

		{
            id: 'donationReceiptTooltip',
            input: 'html',
            i18n: true,
            label: 'Donation Receipt Explanation Tooltip',
            depends: (formValues) => {
                return isStripe(formValues) && formValues.allowDonationReceipt
            }
        },

        {
            id: 'asSubscription',
            input: 'boolean',
            label: 'Enable Monthly Subscriptions',
            description: 'This will setup customers in stripe and needs to be monitored in the dashboard.',
            depends: isStripe
        },

        {
            id: 'subscriptionTooltip',
            input: 'html',
            i18n: true,
            label: 'Subscription Explanation Tooltip',
            depends: (formValues) => {
                return isStripe(formValues) && formValues.asSubscription
            }
        },

        {
            id: 'publicKey',
            input: 'text',
            required: true,
            label: 'Publishable Key',
            description: 'The Publishable key from the stripe backend (Developers > Api Keys)',
            depends: isStripe
        },

        {
            id: 'secretKey',
            input: 'text',
            label: 'Secret Key',
            required: true,
            backendOnly: true,
            description: 'The corresponding secret key',
            depends: isStripe
        },

        {
            id: 'notificationSigningSecret',
            input: 'text',
            label: 'Signing Secret',
            required: true,
            backendOnly: true,
            description: 'required for the notification endpoint in order to handle subscriptions correctly',
            depends: isStripe
        }
    ],

} as IWidgetDefinition);
