export const WIDGET_TYPE = {
  LETTER: 'letter',
  BOOK: 'book',
  INSPIRATION: 'inspiration',
  ORG_BOARD: 'orgboard',
  EXHIBITION: 'exhibition',
  POST: "post",
  IMAGE: "image",
  VIDEO: "video",
  ROOM: 'room'
};
