import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IAppIcon} from "../../core/model/app-icon.model";
import {IconHelper} from "../../core/util/ui/icon.helper";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit, OnChanges {

  @Input() icon: IAppIcon | string;

  useIcon: IAppIcon

  constructor(
    public iconHelper: IconHelper
  ) {
  }

  ngOnInit(): void {

    this.useIcon = this.iconHelper.assureIcon(this.icon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.useIcon = this.iconHelper.assureIcon(changes.icon.currentValue);
    }
  }

}
