import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {AccessService} from '../../core/services/access.service';
import {MemberService} from '../../core/services/member.service';
import {PeopleListModalComponent} from '../people-list-modal/people-list-modal.component';
import {ModalService} from "../../core/util/ui/modal.service";

@Component({
    selector: 'app-follow-button',
    templateUrl: './follow-button.component.html'
})
export class FollowButtonComponent implements OnInit {

    @Input() entityType: string;
    @Input() entityId: string | number;
    @Input() entityName: string;
    @Input() asFavorite = false;

    loading = true;
    following = false;
    leaving = false;
    invited = false;
    member = false;

    editable = false;
    limit = 1;
    followerCount = 0;


    constructor(
        private accessService: AccessService,
        public userProvider: UserService,
        private memberService: MemberService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService
    ) {

    }

    ngOnInit() {
        this.userProvider.isFollowing(this.entityType, this.entityId.toString()).then((following) => {
            this.following = following;
            this.loading = false;
            this.ref.markForCheck();
        });

        this.accessService.editableObservable.subscribe(async editable => {
            this.editable = editable;

            if (editable) {
                const res = await this.memberService.getFollowers(
                    this.entityId.toString(),
                    this.entityType,
                    this.limit,
                    0
                );
                this.followerCount = res.count;
            }
        });

        if (this.entityType === 'project') {
            this.userProvider.projectInvitationObservable.subscribe(invitations => {
                invitations.forEach((entry) => {
                    if (entry.project.id === this.entityId) {
                        this.invited = true;
                    }
                });
            });

            this.accessService.isProjectMember(this.entityId).then(member => this.member = member);
        }
    }

    async main() {
        if (!this.following) {
            await this.follow();
        } else {
            await this.unfollow();
        }
    }

    async follow() {
        this.loading = true;
        this.following = await this.userProvider.follow(this.entityType, this.entityId.toString());
        this.loading = false;
        this.followerCount++;
        this.ref.markForCheck();
    }

    async unfollow() {
        this.loading = true;
        this.following = !await this.userProvider.unfollow(this.entityType, this.entityId.toString());
        this.loading = false;
        this.followerCount--;
        this.ref.markForCheck();
    }

    ignore() {
        this.loading = true;
        this.userProvider.ignoreInvitationToProject(parseInt(this.entityId.toString(), 10)).then(() => {
            this.loading = false;
            this.invited = false;
            this.following = false;
        })
    }

    accept() {
        this.loading = true;
        this.userProvider.acceptInvitationToProject(parseInt(this.entityId.toString(), 10)).then(() => {
            this.loading = false;
            this.invited = false;
            this.member = true;
        });
    }

    leave() {
        this.leaving = true;
    }

    leaveConfirm() {
        this.leaving = false;
        this.loading = true;
        this.userProvider.leaveProject(this.entityId).then(() => {
            this.loading = false;
            this.member = false;
            this.following = false;
        })
    }

    leaveCancel() {
        this.leaving = false;
    }

    async showFollowersModal() {

        const title = this.followerCount < 1 ? `0 followers` : `All ${this.followerCount} ${this.followerCount === 1 ? 'follower' : 'followers'}`;

        this.modalService.open(PeopleListModalComponent, {
          title: title,

          total: this.followerCount,
          categories: ['All members'],
          entityType: this.entityType,
          entityId: this.entityId,
        })

    }


}
