import { Component, OnInit } from '@angular/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import {CenterService} from "../../core/services/center.service";

@Component({
  selector: 'app-widget-follow-button',
  templateUrl: './follow-button.component.html'
})
export class WidgetFollowButtonComponent implements OnInit, DWCWidgetComponent {

  colSizes: object;
  data: any;
  id: number;
  type: string;


  entityName = '';


  constructor(
      private centerService: CenterService,
  ) { }

  ngOnInit() {

    switch (this.data.entityType) {
      default:
        // console.error('Unknown entity type ' + this.data.entityType)
        break;
      case 'Center':
        this.centerService.getCenterById(this.data.entityId).then((center) => {
          if (center) {
            this.entityName = center.displayName;
          }
        })
        break;
    }

  }
}
