import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy, OnInit, Output, ViewChild, ViewContainerRef
} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {StructureService} from "../../core/services/cms/structure.service";
import {sampleTime, Subject, Subscription} from "rxjs";

@Component({
  selector: 'app-modal-frame',
  templateUrl: './modal-frame.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFrameComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title: string = '';
  @Input() loading = false;
  @Input() showFooter = false;
  @Input() showHeader = true;
  @Input() allowClose = true;

  scrollEvents = new Subject<any>();

  @Output() scrollPosition = new EventEmitter<number>();

  @ViewChild('template', { static: true }) template;
  @ViewChild('modalBody') body;

  overlayAjax$: Subscription;
  scrollEvents$: Subscription;

  intervalId: number;

  constructor(
    public activeModal: NgbActiveModal,
    private structureService: StructureService,
    private ref: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef
  ) {

    this.overlayAjax$ = this.structureService.overlayAjaxObservable.subscribe((loading)=> {
      this.loading = loading;
      this.ref.markForCheck();
    })

    this.scrollEvents$ = this.scrollEvents.pipe(sampleTime(400)).subscribe((event) => {
      this.emitScrollPosition(event.target);
    })

    // we can not trust the scroll in some edge cases, so we emit every 500ms
    this.intervalId = window.setInterval(() => {
      this.emitScrollPosition(this.body.nativeElement);
    }, 500)

  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  ngAfterViewInit() {
    this.emitScrollPosition(this.body.nativeElement);
  }

  emitScrollPosition(el: HTMLElement) {
    this.scrollPosition.emit(this.calculateScrollPosition(el));
  }

  calculateScrollPosition(el: HTMLElement) {
    const padding = parseFloat(getComputedStyle(el).getPropertyValue("padding-top")) + parseFloat(getComputedStyle(el).getPropertyValue("padding-bottom"));
    let innerHeight = 0;
    // @ts-ignore
    [...el.children].forEach((c) => {
      innerHeight += c.offsetHeight;
    })

    return Math.round((el.scrollTop + el.offsetHeight - padding) / innerHeight * 100);
  }

  ngOnDestroy(): void {
    this.overlayAjax$.unsubscribe();
    this.scrollEvents$.unsubscribe();

    window.clearInterval(this.intervalId);
  }

}
