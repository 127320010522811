import {Component, OnInit, Input } from '@angular/core';
import {RoutingHelper} from '../../core/util/routing.helper';
import {BehaviorSubject} from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {AnalyticsService, CATEGORY_FOLLOWERS_SEARCH} from "../../core/services/analytics.service";
import {SearchService} from "../../core/services/search.service";
import {IUser} from "../../core/model/user.model";
import {MemberService} from "../../core/services/member.service";
import {CenterService} from "../../core/services/center.service";
import {ProjectService} from "../../core/services/project.service";


@Component({
  selector: 'app-preview-list-modal',
  templateUrl: './people-list-modal.component.html',
  styleUrls: [],
})

export class PeopleListModalComponent implements OnInit {

  @Input() total?: number;
  @Input() source : 'followers' | 'members' = 'followers';
  @Input() title: string;
  @Input() categories: [];
  @Input() entityType: string;
  @Input() entityId: string | number;

  currentPage = 0;
  perPage = 16;

  query = '';
  isSearchFocused = false;
  searchUpdated : BehaviorSubject<string> = new BehaviorSubject('');

  allPeopleLoaded = false;
  people: IUser[] = [];
  list: IUser[] = [];

  loading = false;

  constructor(
    public routingHelper: RoutingHelper,
    private searchService: SearchService,
    private memberService: MemberService,
    private readonly tracker: AnalyticsService,
    private centerService: CenterService,
    private projectService: ProjectService,
  ) {
    this.searchUpdated.asObservable()
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged()).subscribe(async query => {
          this.query = query;
          this.list = [];
          this.list = await this.filter(query);
        });
   }

  ngOnInit() {
    if (!this.title) {
     this.title = 'All members';
    }

    this.loadNextPage();
  }

  async filter(searchStr = '') {
    if (!this.people) {
      return [];
    }

    if (!searchStr) {
      return this.people;
    }

    let res : IUser[];

    this.loading = true;

    if (this.source === 'followers') {
      res = (await this.searchService.runFollowerSearch(searchStr, this.entityId.toString(), this.entityType, this.perPage)).preview;
    } else {
      res = this.people.filter(member => {
        if (member.givenName?.toLowerCase().includes(searchStr.toLowerCase())) {
          return true
        }
        if (member.familyName?.toLowerCase().includes(searchStr.toLowerCase())) {
          return true
        }
        if (member.username?.toLowerCase().includes(searchStr.toLowerCase())) {
          return true
        }
        return false
      })
    }

    this.tracker.trackSearch(searchStr, res.length, CATEGORY_FOLLOWERS_SEARCH);

    this.loading = false;

    return res;
  }

  // loads the next page on the "see all" modal
  async loadNextPage() {

    if (!this.allPeopleLoaded && !this.loading) {
      this.loading = true;

      if (this.source === 'followers') {

        const res = await this.memberService.getFollowers(
          this.entityId.toString(),
          this.entityType,
          this.perPage,
          this.perPage * this.currentPage
        );
        this.currentPage++;
        this.people = this.people.concat(res.members);
      } else {
        // members case

        if (this.people.length === 0) {
          // just one call for center and project members -
          // members total is greater then the actually returned members count, if some members are hidden

          switch (this.entityType) {
            case 'center':
              const response: any = await this.centerService.getCenterMembers(this.entityId.toString(), 1000);
              this.people = this.people.concat(response.members);
              break;
            case 'project':
              const memberships = await this.projectService.getProjectMembers(this.entityId);
              this.people = this.people.concat(memberships.filter((mem) => {
                return mem.user.enabled
              }).map((mem) => mem.user));
              break;
          }
        }

      }


      this.loading = false;

      if (this.people.length >= this.total) {
        this.allPeopleLoaded = true;
      }
    }

    this.list = this.people;
  }

  async addMore (scrollPositionPercent: number) {
    if (scrollPositionPercent > 80) {
      await this.loadNextPage();
      this.list = await this.filter(this.query);
    }
  }
}
