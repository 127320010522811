import {IWidgetDefinition} from '../../core/model/widget.model';
import {WidgetFollowButtonComponent} from './follow-button.component';

export default ({
    getComponent: () => WidgetFollowButtonComponent,
    icon: {style: 'fa-regular', name: 'thumbs-up'},
    category: 'special',
    fields: [

        {
            id: 'entityType',
            input: 'select',
            label: 'Entity Type',
            required: true,
            options: [
                { label: 'Center', value: 'Center' },
                { label: 'Project', value: 'Project' },
                { label: 'Widget', value: 'Widget' },
                { label: 'Page', value: 'Page' }
            ],
            width: 6
        },

        {
            id: 'entityId',
            label: 'Entity Id',
            input: 'text',
            required: true,
            width: 6
        },

        {
            id: 'align',
            input: 'select',
            label: 'Button Align',
            options: [
                { label: 'Left', value: 'align-left' },
                { label: 'Center', value: 'align-center' },
                { label: 'Right', value: 'align-right' },
            ],
        },
    ]
} as IWidgetDefinition)
