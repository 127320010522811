<app-modal-frame title="{{title}}" (scrollPosition)="addMore($event)">
  <ng-container slot="body">
    <div class="mb-3">
      <input type="text" #memberSearch class="form-control" placeholder="Type a member name to search" (keyup)="searchUpdated.next(memberSearch.value.toString().trim().toLocaleLowerCase())" (focus)="isSearchFocused = true" (blur)="isSearchFocused = false">
    </div>

    <div class="row">
      <div *ngFor="let item of list;" class="col-12 col-md-6">
        <a [routerLink]="routingHelper.getMemberRoute(item)" class="d-flex link-text align-items-center justify-content-start p-2 cell--hover">
          <app-member-preview [member]="item" [tooltip]="false" [linkToProfile]="false"></app-member-preview>

          <span class="text-primary">{{ item.givenName ? item.givenName : ''}} {{ item.familyName ? item.familyName : ''}}</span>
        </a>

      </div>
    </div>
    <app-page-loader *ngIf="loading"></app-page-loader>
  </ng-container>
</app-modal-frame>

