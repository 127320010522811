<div *ngIf="messageService.toasts.length > 0" class="toast-container position-fixed p-3 bottom-0 start-50 translate-middle-x">
  <ngb-toast
    *ngFor="let toast of messageService.toasts"
    header="{{toast.severity === 'success' ? 'Success' : 'Error'}}"
    [class.text-bg-danger]="toast.severity === 'danger'"
    [class.text-bg-success]="toast.severity === 'success'"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="messageService.remove(toast)"
  >
    <i *ngIf="toast.severity === 'success'" class="fa-regular fa-circle-check me-2"></i>
    <i *ngIf="toast.severity === 'danger'" class="fa-solid fa-circle-exclamation me-2"></i>
    {{ toast.text }}
  </ngb-toast>
</div>
