import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccessService, PERMISSION_ADMINS, PERMISSION_UPDATE} from '../../core/services/access.service';
import {StructureService} from '../../core/services/cms/structure.service';
// import { AddMembersModalComponent } from 'src/app/components/add-members-modal/add-members-modal.component';
import {IProject} from '../../core/model/project.model';
import {Subscription} from 'rxjs';
import {ProjectService} from "../../core/services/project.service";

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
})
export class EditButtonComponent implements OnInit, OnDestroy {

  editable = false;
  editMode: boolean;
  updateAllowed = false;
  projectAdminAllowed = false;
  isEditingProjects = false;
  currentProject?: IProject;

  editModeSubscription: Subscription;
  editableSubscription: Subscription;

  constructor(
    private accessService: AccessService,
    public structureService: StructureService,
    public projectService: ProjectService,
  ) {
    this.editModeSubscription = this.structureService.editModeObservable.subscribe(editMode => {
      this.editMode = editMode;
      this.checkIsInProject();
    });

    this.editableSubscription = this.accessService.editableObservable.subscribe(editable => {
      this.editable = editable;
    });
  }

  ngOnInit(){
    // as this component is present all the time, the logic needs to happen in the editMode subscription
  }

  ngOnDestroy(): void {
    this.editModeSubscription.unsubscribe();
    this.editableSubscription.unsubscribe();
  }

  async checkIsInProject(){

    this.updateAllowed = await this.structureService.rightCurrentlyGranted(PERMISSION_UPDATE);
    this.projectAdminAllowed = await this.structureService.rightCurrentlyGranted(PERMISSION_ADMINS);

    if (this.structureService.getCurrentProject()) {
      this.isEditingProjects = true;
      this.currentProject = this.structureService.getCurrentProject();
    } else {
      this.isEditingProjects = false;
      this.currentProject = null;
    }
  }

}
