import { Injectable } from '@angular/core';
import {EnvironmentService} from './environment.service';
import {Environment, SsoEnvironment} from '../auth/environment.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';

const geoUrlTemplates = {
    default: 'https://www.google.com/maps/place/{latitude},{longitude}',
    Mac: 'https://maps.apple.com/?ll={latitude},{longitude}&q={name}',
    Android: 'geo:{latitude},{longitude}'
  };


@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  origin: string

  constructor(
      private env: EnvironmentService,
      private environment: Environment<SsoEnvironment>,
      private deviceDetectorService: DeviceDetectorService,
      private sanitizer: DomSanitizer,
      private router: Router
  ) {
    this.origin = this.getOrigin(this.env.currentEnv.ssoWebRedirectUri);
  }

  openBrowser(url: string) {
    if (!url || url.trim().length === 0) return;
    window.open(url, '_blank');
  }

  getOrigin (url: string) {
    return (new URL(url)).origin;
  }

  isExternalUrl (url : string) {
    return !(
        url.startsWith('/') ||
        url.startsWith(this.origin)
    );
  }

  async navigateLink (link : string|boolean) {
    if (typeof link == 'string' && link) {
      if (link.startsWith(this.origin)) {
        link = link.substring(this.origin.length);
      }

      if (this.isExternalUrl(link)) {
        this.openBrowser(link);
      } else {
        await this.router.navigateByUrl(link);
      }
    }
  }

  navigateToSso (path: string, params?) {

    let url = this.environment.activeEnv.ssoPath + path + '?redirect_uri=' + encodeURIComponent(window.location.href);

    if (params) {
      Object.keys(params).forEach((param) => {
        url += '&' + param + '=' + encodeURIComponent(params[param]);
      })
    }

    return this.openBrowser(url);
  }

  getLocationShareLink (latitude: number, longitude: number, name: string = '') {

    let template = geoUrlTemplates.default;

    if (geoUrlTemplates[this.deviceDetectorService.os]) {
      template = geoUrlTemplates[this.deviceDetectorService.os];
    }

    return this.sanitizer.bypassSecurityTrustUrl(
        template
            .replace('{latitude}', latitude + '')
            .replace('{longitude}', longitude + '')
            .replace('{name}', name)
    )
  }

}
