import { IFormDefinition } from '../../model/formDefinition.model';

export default ({
    id: 'country',
    input: 'select',
    options: [
        { value: 'AR', label: 'country.AR' },
        { value: 'AU', label: 'country.AU' },
        { value: 'AT', label: 'country.AT' },
        { value: 'BY', label: 'country.BY' },
        { value: 'BE', label: 'country.BE' },
        { value: 'BR', label: 'country.BR' },
        { value: 'BG', label: 'country.BG' },
        { value: 'CA', label: 'country.CA' },
        { value: 'CL', label: 'country.CL' },
        { value: 'CO', label: 'country.CO' },
        { value: 'CR', label: 'country.CR' },
        { value: 'CE', label: 'country.CE' },
        { value: 'CU', label: 'country.CU' },
        { value: 'CZ', label: 'country.CZ' },
        { value: 'DK', label: 'country.DK' },
        { value: 'EC', label: 'country.EC' },
        { value: 'SV', label: 'country.SV' },
        { value: 'EE', label: 'country.EE' },
        { value: 'FI', label: 'country.FI' },
        { value: 'FR', label: 'country.FR' },
        { value: 'GE', label: 'country.GE' },
        { value: 'DE', label: 'country.DE' },
        { value: 'GR', label: 'country.GR' },
        { value: 'GT', label: 'country.GT' },
        { value: 'HK', label: 'country.HK' },
        { value: 'HU', label: 'country.HU' },
        { value: 'IS', label: 'country.IS' },
        { value: 'IE', label: 'country.IE' },
        { value: 'IL', label: 'country.IL' },
        { value: 'IT', label: 'country.IT' },
        { value: 'JP', label: 'country.JP' },
        { value: 'KZ', label: 'country.KZ' },
        { value: 'KR', label: 'country.KR' },
        { value: 'KG', label: 'country.KG' },
        { value: 'LV', label: 'country.LV' },
        { value: 'LT', label: 'country.LT' },
        { value: 'MK', label: 'country.MK' },
        { value: 'MT', label: 'country.MT' },
        { value: 'MX', label: 'country.MX' },
        { value: 'MD', label: 'country.MD' },
        { value: 'MN', label: 'country.MN' },
        { value: 'ME', label: 'country.ME' },
        { value: 'NP', label: 'country.NP' },
        { value: 'NL', label: 'country.NL' },
        { value: 'NZ', label: 'country.NZ' },
        { value: 'NO', label: 'country.NO' },
        { value: 'PA', label: 'country.PA' },
        { value: 'PE', label: 'country.PE' },
        { value: 'PL', label: 'country.PL' },
        { value: 'PT', label: 'country.PT' },
        { value: 'RO', label: 'country.RO' },
        { value: 'RU', label: 'country.RU' },
        { value: 'RS', label: 'country.RS' },
        { value: 'SG', label: 'country.SG' },
        { value: 'SK', label: 'country.SK' },
        { value: 'SI', label: 'country.SI' },
        { value: 'ZA', label: 'country.ZA' },
        { value: 'ES', label: 'country.ES' },
        { value: 'SE', label: 'country.SE' },
        { value: 'CH', label: 'country.CH' },
        { value: 'TW', label: 'country.TW' },
        { value: 'TH', label: 'country.TH' },
        { value: 'UA', label: 'country.UA' },
        { value: 'GB', label: 'country.GB' },
        { value: 'UY', label: 'country.UY' },
        { value: 'US', label: 'country.US' },
        { value: 'VE', label: 'country.VE' },
        { value: 'VN', label: 'country.VN' },
    ]
} as IFormDefinition)
