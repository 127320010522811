<app-tile title="{{project.name}}"
          [route]="routingHelper.getProjectRoute(project)"
          [picture]="project.picture"
          [blackAndWhite]="project.archived"
          [colSizes]="colSizes">
  <ng-container slot="badge">
    <div>
      <span *ngIf="project.archived" class="badge bg-warning text-dark text-uppercase me-2">[Archived]</span>
      <span *ngIf="project.international; else projectChip" class="badge bg-warning text-dark text-uppercase">{{'projects.titles.international' | translate}}</span>

      <ng-template #projectChip>
        <ng-container [ngSwitch]="project.scope">
          <span *ngSwitchCase="'CE'" class="badge bg-primary text-uppercase">{{project.center?.displayName}}</span>
          <span *ngSwitchCase="'RE'" class="badge bg-primary text-uppercase">{{project.region?.name}}</span>
          <span *ngSwitchCase="'CY'" class="badge bg-primary text-uppercase">{{'country.' + project?.country?.code | translate}}</span>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
</app-tile>
