import {IWidgetDefinition} from '../../core/model/widget.model';
import {InspirationComponent} from './inspiration.component';

export default ({
    getComponent: () => InspirationComponent,
    icon: {style: 'fa-solid', name: 'heart'},
    fields: [
        {
            id: 'title',
            input: 'text',
            i18n: true
        },
        {
            id: 'location',
            input: 'text'
        },
        {
            id: 'description',
            input: 'html',
            i18n: true
        },
        {
            id: 'images',
            input: 'complex-list',
            subtype: [
                {
                    id: 'image',
                    input: 'image_upload',
                    multiple: false
                },
                {
                    id: 'title',
                    input: 'text',
                }
            ],
        }
    ]
} as IWidgetDefinition)
