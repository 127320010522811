import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {defaultLanguage, supportedLanguages} from './translation.constants';
import {get, unescape} from 'lodash-es';
import {ILangField} from '../model/fields/langfield.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelper {

  translations = {};

  constructor(
      private translator: TranslateService,
  ) {
  }

  async loadLanguage (locale) {

    const lang = this.getBestLanguages(locale);
    if (!this.translations[lang.locale]) {
      this.translations[lang.locale] = await this.translator.getTranslation(lang.locale).toPromise();
    }

    if (lang.fallbackLocale && lang.fallbackLocale !== lang.locale && !this.translations[lang.fallbackLocale]) {
      this.translations[lang.fallbackLocale] = await this.translator.getTranslation(lang.fallbackLocale).toPromise();
    }
  }

  private unescapeIfNeeded(objectOrString) {
    if (typeof objectOrString !== 'string') {
      return objectOrString;
    }

    return unescape(objectOrString);
  }

  getBestLanguageValue (langObject: ILangField, forcedLocale: string = null, defaultValue = null) {
    if (typeof(langObject) !== 'object' || !langObject) {
      return langObject;
    }

    const currentLang = forcedLocale || this.translator.currentLang;
    const fallbackLang = currentLang.length > 2 ? currentLang.substring(0, 2) : null;

    if (langObject[currentLang] !== undefined && langObject[currentLang] !== null) {
      return this.unescapeIfNeeded(langObject[currentLang]);
    }

    // in case the user language is something like en_US
    if (fallbackLang && langObject[fallbackLang] !== undefined && langObject[fallbackLang] !== null) {
      return this.unescapeIfNeeded(langObject[fallbackLang]);
    }

    // return the default
    if (langObject[defaultLanguage]) {
      return this.unescapeIfNeeded(langObject[defaultLanguage]);
    }

    // otherwise we return the first key with a value.
    const langs = Object.keys(langObject);

    for (let i = 0; i < langs.length; i++) {
      const lang = langs[i];
      if (langObject[lang]) {
        return this.unescapeIfNeeded(langObject[lang]);
      }
    }

    return this.unescapeIfNeeded(defaultValue);
  }

  localTranslate(key, locale) {
    const lang = this.getBestLanguages(locale);
    return (get(this.translations[lang.locale], key) || get(this.translations[lang.fallbackLocale], key)) || this.translator.instant(key);
  }

  getBestLanguages (locale) : {locale:string, fallbackLocale: string} {
    const res = {
      locale: defaultLanguage,
      fallbackLocale: defaultLanguage
    }

    if (locale) {
      if (supportedLanguages.indexOf(locale) > -1) {
        res.locale = locale;
      } else if (locale.length > 2) {
        locale = locale.substring(0, 2);

        if (supportedLanguages.indexOf(locale) > -1) {
          res.locale = locale;
        }
      }
    }

    return res;
  }
}
