import { IWidgetDefinition } from '../../core/model/widget.model';
import languageFieldDef from '../../core/util/forms/language.select';
import countryFieldDef from '../../core/util/forms/country.select';

const languageField = {...languageFieldDef};
languageField.required = true;


const countryField = { ...countryFieldDef }; // this expands the country field dev into a new object
countryField.options.unshift(
    {value: 'INT', label: '- All Countries -'}
)
countryField.default = 'INT';
countryField.label = 'The letter should be only visible for a specific country?';
countryField.required = true;


const isPdf = (fieldValues) => {
    return fieldValues.type !== 'email';
}

const isEmail = (fieldValues) => {
    return fieldValues.type === 'email';
}

export default ({
    icon: {style: 'fa-regular', name: 'envelope'},
    fields: [
        {
            id: 'date',
            input: 'date',
            label: 'Date',
            required: true,
        },
        {
            id: 'type',
            input: 'select',
            options: [
                { value: 'email', label: 'widgets.letter.email' },
                { value: 'nyLetter', label: 'widgets.letter.nyLetter' },
                { value: 'pdf', label: 'widgets.letter.pdf' }
            ],
            label: 'Type',
            required: true,
        },
        countryField,
        {
            id: 'originalLanguage',
            input: 'select',
            options: [
                { value: 'english', label: 'language.en' },
                { value: 'german', label: 'language.de' },
                { value: 'danish', label: 'language.da' },
            ],
            default: 'english',
            label: 'Original language',
            required: false,
        },
        {
            id: 'location',
            input: 'text',
            label: 'Location',
            required: false,
            description: 'The location, where the letter was written or send from.'
        },
        {
            id: 'sendTo',
            input: 'text',
            label: 'Send to',
            required: true,
        },
        {
            id: 'subject',
            input: 'text',
            label: 'Subject',
            required: true,
        },
        {
            id: 'teaser',
            label: 'First 2 lines as preview',
            input: 'html',
            required: false,
            description: 'This will be displayed in the listing view as a short preview. Please don\'t make it too long.'
        },
        {
            id: 'textContent',
            label: 'Text',
            input: 'html',
            required: false,
            depends: isEmail
        },
        {
            id: 'file',
            input: 'file_upload',
            label: 'File',
            required: false,
            depends: isPdf
        },
        {
            id: 'translations',
            label: 'Translations',
            input: 'complex-list',
            required: false,
            subtype: [
                    languageField,
                {
                    id: 'subject',
                    input: 'text',
                    label: 'Subject',
                },
                {
                    id: 'sendTo',
                    input: 'text',
                    label: 'To:',
                },
                {
                    id: 'textContent',
                    input: 'html',
                    label: 'Text',
                },
                {
                    id: 'file',
                    input: 'file_upload',
                    label: 'File',
                    depends: isPdf
                }
            ]
        }

    ]
} as IWidgetDefinition)
