import {Injectable} from '@angular/core';
import {IAppVideo} from '../model/app-video.model';
import {IAppImage} from '../model/app-image.model';
import {EnvironmentService} from "./environment.service";

@Injectable({
    providedIn: 'root'
})
export class VideoHelperService {

    constructor(
        private environmentService: EnvironmentService
    ) {
    }


    getYouTubeId(url: string) {
        const regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\s]+\/.+\/|(?:v|e(?:mbed)?|shorts)\/|.*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})$/gi;
        const m = regex.exec(url);

        if (m?.length > 0) {
            return m[1];
        }

        return null;
    }

    getVideoCover(video: IAppVideo): IAppImage {

        let cover = video.cover;

        if (video.type === 'external') {
            const youTubeId = this.getYouTubeId(video.src);
            if (youTubeId) {
                cover = {src: `https://img.youtube.com/vi/${youTubeId}/maxresdefault.jpg`, aspectRatio: 0.56};
            }
        }

        if (cover) {
            cover.filters = ['brightness(-25)', `watermark(${this.environmentService.currentEnv.backendBasePath.replace('https://', '').replace('http://', '')}/app/img/icon-play.png,center,center,5,24,24)`];
        }

        return cover;
    }
}
