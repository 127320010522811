import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsMemberGuard } from './core/auth/is-member.guard';
import { LoggedInGuard } from './core/auth/logged-in.guard';

const assureLoggedIn = async () => {
  return await inject(LoggedInGuard).canActivate();
}

const assureIsMember = async () => {
  return await inject(IsMemberGuard).canActivate();
}

const routes: Routes = [
  {
    path: '',
    redirectTo: '/news',
    pathMatch: 'full'
  },

  /*


  // { path: 'implicit/authcallback', loadChildren: () => import('./modules/implicit/auth-callback/auth-callback.module').then(m => m.AuthCallbackPageModule) },
  // { path: 'implicit/endsession', loadChildren: () => import('./modules/implicit/end-session/end-session.module').then(m => m.EndSessionPageModule) },

   */
  {
    path: 'access-denied',
    canActivate: [assureLoggedIn],
    loadChildren: () => import('./modules/permission-denied/permission-denied.module').then(m => m.PermissionDeniedPageModule)
  },
  {
    path: 'members/:slug',
    canActivate: [assureLoggedIn, assureIsMember],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'my-profile',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },  
  { path: 'me', redirectTo: '/my-profile', pathMatch: 'full' }, // redirect to `profile`
  
  {
    path: 'styleguide',
    canActivate: [assureLoggedIn, assureIsMember],
    loadChildren: () => import('./pages/styleguide/styleguide.module').then(m => m.StyleguideModule)
  },
  {
    path: '**',
    canActivate: [assureLoggedIn, assureIsMember],
    loadChildren: () => import('./pages/generic/generic.module').then(m => m.GenericPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
