<div [ngClass]="{
    'justify-content-start': align === 'align-left',
    'justify-content-center': align === 'align-center',
    'justify-content-end': align === 'align-right',
    'd-flex': true
  }">
  <a class="{{buttonHelper.buildButtonClass(buttonHelper.getButtonDef(data.color, data.fill))}}"  (click)="navigate()">

    <i *ngIf="data.arrow === 'back'" class="fa-solid fa-chevron-left me-2"></i>
    <span>{{ buttonText }}</span>
    <i *ngIf="data.arrow === 'forward'" class="fa-solid fa-chevron-right ms-2"></i>
  </a>
</div>
