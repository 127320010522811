import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IWidget} from '../core/model/widget.model';
import {getWidgetComponent} from '../core/util/dwc-widget-component-map';
import {DwcWidgetDirective} from '../core/directives/dwc-widget.directive';
import {DWCWidgetComponent} from '../core/model/dwc-widget.model';

@Component({
  selector: 'app-generic-widget',
  templateUrl: './generic.component.html',
  styleUrls: [],
})
export class GenericComponent implements OnInit {

  // this allows us to dynamically pull the template, as soon as we know it
  @ViewChild(DwcWidgetDirective, {static: true}) dwcWidgetHost: DwcWidgetDirective;

  @Input() widget: IWidget;
  @Input() widgetList: Array<IWidget>;
  @Input() colSizes: object;
  @Input() display = 'page';
  @Input() useEditor = false;
  @Input() sectionId: string;
  @Input() position: number;

  constructor(

  ) { }

  ngOnInit() {

    const viewContainerRef = this.dwcWidgetHost.viewContainerRef;

    if (this.widget || this.useEditor) {

      // load the editor or the real widget
      getWidgetComponent(this.useEditor ? 'widget-editor' : this.widget.type).then((component) => {
        viewContainerRef.clear();
        const templateRef =   viewContainerRef.createComponent(component);

        if (this.useEditor) {
          (templateRef.instance as any).widget = this.widget;
          (templateRef.instance as any).colSizes = this.colSizes;
          (templateRef.instance as any).sectionId = this.sectionId;
          (templateRef.instance as any).position = this.position;

          if (this.widget) {
            getWidgetComponent(this.widget.type).then(widgetCmp => {
              (templateRef.instance as any).formDefinition = widgetCmp.fieldDefinition;
            })
          }
        } else {
          (templateRef.instance as DWCWidgetComponent).entity = this.widget;
          (templateRef.instance as DWCWidgetComponent).type = this.widget.type;
          (templateRef.instance as DWCWidgetComponent).data = this.widget.data;
          (templateRef.instance as DWCWidgetComponent).id = this.widget.id;
          (templateRef.instance as DWCWidgetComponent).preventUnsubscribe = this.widget.preventUnsubscribe;
          (templateRef.instance as DWCWidgetComponent).colSizes = this.colSizes;
          (templateRef.instance as DWCWidgetComponent).display = this.display;
          (templateRef.instance as DWCWidgetComponent).widgetList = this.widgetList;
        }
      });
    }
  }
}
