import {IWidgetDefinition} from '../../core/model/widget.model';

export default {
    icon: {style: "fa-solid", name: 'globe'},
    fields: [
        {
            id: 'startDate',
            input: 'date',
            required: true,
        },
        {
            id: 'spaceImage',
            input: 'image_upload'
        },
        {
            id: 'countryBlacklist',
            input: 'text',
            description: 'a comma seperated list of iso2 country codes, that should not be highlighted on the map'
        }
    ]
} as IWidgetDefinition;
