import gql from 'graphql-tag';
import {DocumentNode} from 'graphql';
import {CenterFragment} from '../fragments/center.fragments';

const AppImageFragment = gql`
fragment AppImageFragment on AppImage {
  src
  aspectRatio
  focal
}
`

export const TitledAppImageFragment = gql`
fragment TitledAppImageFragment on TitledAppImage {
  title
  image {
    ...AppImageFragment
  }
}
${AppImageFragment}
`;

const PermissionFragment = gql`
fragment PermissionFragment on Permissions {
  all
  countries
  regions
  centers
  projects
  pages
  events
}
`;

export const MembershipInfoFragment = gql`
fragment MembershipInfoFragment on ProjectMembership {
  status
  labels
  user {
    id
    username
    givenName
    familyName
    enabled
    userData{
      picture{
        url
      }
    }
    center{
      id
      name
      slug
    }
  }
}
`;

export const FullMemberFragment = gql`
  fragment FullMemberFragment on Member {
    id
    dwbnId
    username
    givenName
    familyName
    email
    emailVerified
    hidden
    hasLoggedIn
    center {
      ...CenterFragment
    }
    userData {
      picture {
        url
      }
      homepage
      language
      birth_date
      gender
      phone_numbers {
        phone
        phone_type
      }
      addresses {
        street_address
        postal_code
        city
        country
      }
    }
    preferredContactOption
    privacySettings{
      email
      skype
      homepage
      addresses
      language
      phone_numbers
      birth_date
      preferredContactOption
    }
    enabled
    centerAccount
    allowEmailNotifications
  }
  ${CenterFragment}
`;

export const MemberPublicInfoFragment = gql`
  fragment MemberPublicInfoFragment on Member {
    id
    username
    givenName
    familyName
    email
    center {
      id
      name
      displayName
      slug
      country {
        id
        code
      }
    }
    userData{
      picture {
        url
      }
    }
  }`;

export const GET_MEMBER_DATA: DocumentNode = gql`
query appQuery($userName: String) {
  member (username: $userName) {
    ...FullMemberFragment
    projectMemberships {
      project {
        id
      }
    }
  }
}
${FullMemberFragment}
`;

export const GET_LOGGED_IN_USER: DocumentNode = gql`
  query appQuery {
    me {
      ...FullMemberFragment
    }
    my_tokens {
      category
      token
      expiresAt
    }
    my_dwbn_apps (globalNav: true, withoutSelf: true) {
      id
      order
      link {
        href
        title
      }
    }
    my_roles
    my_contacts {
      ...MemberPublicInfoFragment
    }
    my_contact_requests {
      receiver {
        id
        username
      }
      sender {
        ...MemberPublicInfoFragment
      }
    }
    my_project_invitations {
      project {
        id
        name
        slug
        picture {
          src
          aspectRatio
          focal
        }
      }
    }
    my_permissions {
      ...PermissionFragment
    }
    my_subscriptions {
      id
      status
    }
  }
  ${FullMemberFragment}
  ${MemberPublicInfoFragment}
  ${PermissionFragment}
`;

export const MY_PERMISSIONS_QUERY = gql`
query appQuery {
  my_permissions {
    ...PermissionFragment
  }
}
${PermissionFragment}
`;

export const GET_PROJECT_MEMBERS_SLUG: DocumentNode = gql`
query appQuery($Slug: String){
  project(slug: $Slug){
    id
    name
    invitedMembers {
      ...MembershipInfoFragment
    }
    members{
      ...MembershipInfoFragment
    }
  }
}
${MembershipInfoFragment}
`;

export const GET_PROJECT_MEMBERS: DocumentNode = gql`
query appQuery($id: Int){
project(id: $id){
  id
  members{
    user{
      id
      username
      givenName
      familyName
      enabled
      userData{
          picture{
            url
          }
        }
      }
    }
  }
}`;

export const GET_PROJECT_MEMBERS_FULL = gql`
query appQuery($id: Int){
project(id: $id){
  id
  members{
    labels
    user {
      id
      dwbnId
      username
      givenName
      familyName
      enabled
      email
      center {
        ...CenterFragment
      }
      userData {
          picture{
            url
          }
          phone_numbers {
            phone
            phone_type
          }
        }
      }
    }
  }
}
${CenterFragment}
`;

export const IS_USER_FOLLOWING = gql`
query is_user_following($followedEntityId: String, $followedEntityType: String) {
  is_user_following(followedEntityId: $followedEntityId, followedEntityType: $followedEntityType)
}`;

export const USER_FOLLOW = gql`
mutation userFollow ($input: FollowableInput!){
  userFollow (input: $input)
}`;

export const USER_UNFOLLOW = gql`
mutation userUnfollow ($input: FollowableInput!){
  userUnfollow (input: $input)
}`;

export const CHANGE_MY_HIDDEN_STATUS_MUTATION = gql`
  mutation changeMyHiddenStatus ($newStatus: Boolean! ) {
    changeMyHiddenStatus (newStatus: $newStatus) {
      ...FullMemberFragment
    }
  }
  ${FullMemberFragment}
`;

export const ADD_MEMBER_TO_PROJECT = gql`
mutation pcm($input: ProjectMembershipInput!) {
  projectCreateMembership(input: $input) {
    project {
      id
    }
    user {
      id
    }
    status
  }
}`;

export const REMOVE_MEMBER_FROM_PROJECT = gql`
mutation rpm($input: ProjectMembershipInput!) {
  projectDeleteMembership (input: $input)
}
`;

export const ACCEPT_INVITATION_TO_PROJECT = gql`
mutation pConfirm($input: ProjectMembershipInput!) {
  projectConfirmMembership(input: $input) {
    project {
      id
    }
    user {
      id
    }
    status
  }
}`;

export const ACCEPT_CONTACT_REQUEST = gql`
mutation cConfirm ($id: Int!) {
  accept_contact_request(id: $id) {
    ...MemberPublicInfoFragment
  }
}
${MemberPublicInfoFragment}
`;

export const IGNORE_CONTACT_REQUEST = gql`
mutation cIgnore ($id: Int!) {
  ignore_contact_request(id: $id) {
    ...MemberPublicInfoFragment
  }
}
${MemberPublicInfoFragment}
`;

export const IGNORE_INVITATION_TO_PROJECT = gql`
mutation pIgnore($input: ProjectMembershipInput!) {
  projectIgnoreMembership(input: $input)
}`;

export const LEAVE_PROJECT_MUTATION = gql`
mutation pLeave($input: ProjectMembershipInput!) {
   projectDeleteMembership(input: $input)
}`;

export const SEND_CONTACT_REQUEST = gql`
mutation send_contact_request($input: SendContactRequestInput!){
  send_contact_request(input: $input){
    status
  }
}`;

export const UPDATE_PROJECT_MEMBERSHIP_MUTATION = gql`
mutation projectUpdateMembership($input: UpdateProjectMembershipInput!) {
  projectUpdateMembership (input: $input) {
    labels
    status
  }
}`;

export const UPDATE_MEMBER_MUTATION = gql`
mutation memberSelfUpdateDetails($language: String, $addresses: [AddressInput], $birthDate:String, $homepage:String, $preferredContactOption: String, $phone_numbers:[String], $allowEmailNotifications: Boolean!, $emailPrivacy:Int, $phonePrivacy: Int, $homepagePrivacy:Int, $birthDatePrivacy:Int, $addressesPrivacy: Int, $preferredContactOptionPrivacy: Int){
	memberSelfUpdateDetails(details: {
    phone_numbers: $phone_numbers
    language: $language
    homepage: $homepage
    addresses: $addresses
    birthDate: $birthDate
    allowEmailNotifications: $allowEmailNotifications,
    preferredContactOption: $preferredContactOption,
    privacySettings: {
      phone_numbers: $phonePrivacy
      email: $emailPrivacy
      homepage: $homepagePrivacy
      birth_date: $birthDatePrivacy
      addresses: $addressesPrivacy
      preferredContactOption: $preferredContactOptionPrivacy
    }
  }){
    ...FullMemberFragment
  }
}
${FullMemberFragment}
`;
