import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    icon: {style: "fa-regular", name: 'images' },
    fields: [
        {
            id: 'images',
            input: 'complex-list',
            subtype: [
              {
                id: 'image',
                input: 'image_upload',
                multiple: false,
                required: true
              },
              {
                id: 'title',
                input: 'text',
                i18n: true,
                description: 'Appears as headline in the overlay.'
              },
              {
                id: 'description',
                input: 'html',
                i18n: true,
                description: 'The description appears in the overlay.'
              }
            ],
            required: true
        }
    ]
} as IWidgetDefinition)
