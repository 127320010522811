<ng-template #template>
  <div *ngIf="showHeader" class="modal-header justify-content-between mx-1">
    <h2 class="h6 modal-title text-primary">{{ title }}</h2>
    <button *ngIf="allowClose" type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>

  <div #modalBody class="modal-body p-1 pb-3" (scroll)="scrollEvents.next($event)">
    <div>
      <!-- ng-content will put all the children -->
      <ng-content select="[slot=body]"></ng-content>
    </div>
  </div>

  <div *ngIf="showFooter" class="modal-footer justify-content-between py-2">
    <ng-content select="[slot=footer]"></ng-content>
  </div>

  <div *ngIf="loading" class="modal__loader position-absolute top-0 start-0 bottom-0 end-0 bg-opacity-75 bg-light rounded-4">
    <app-page-loader></app-page-loader>
  </div>
</ng-template>
