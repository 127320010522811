import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {IUser} from '../../core/model/user.model';
import {RoutingHelper} from '../../core/util/routing.helper';
import {ModalService} from "../../core/util/ui/modal.service";
import {StructureService} from "../../core/services/cms/structure.service";

@Component({
  selector: 'app-welcome-overlay',
  templateUrl: './welcome-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeOverlayComponent implements OnInit {

  @Input() user: IUser;

  constructor(
      private userProvider: UserService,
      private modalService: ModalService,
      public routingHelper: RoutingHelper,
      private structureService: StructureService,
  ) { }

  ngOnInit() {

  }

  changeHiddenStatus( newStatus: boolean) {
    this.structureService.startOverlayAjax();
    this.userProvider.changeHiddenMode(newStatus).then( () => {
      this.modalService.dismiss();
    })
  }

}
