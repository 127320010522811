<a [routerLink]="route">
  <span class="d-block tile">

    <app-img *ngIf="picture"
             [image]="picture"
             [aspectRatio]="aspectRatio"
             [roundedCorners]="true"
             [align]="'alone'"
             [blackAndWhite]="blackAndWhite"
             [colSizes]="colSizes"
    >
    </app-img>

    <span *ngIf="!picture" class="tile__placeholder tile__placeholder--p60">
      <i class="dw-icon-project text-primary fs-huge position-absolute top-50 start-50 translate-middle"></i>
    </span>

      <h5 *ngIf="title" class="fs-6 text-white tile__gradient">

        <ng-content select="[slot=badge]"></ng-content>

        <span class="d-block text-white mt-2 fw-normal">{{title}}</span>
      </h5>
  </span>
</a>
