import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {IUser} from '../../core/model/user.model';
import {Subscription} from 'rxjs';
import {BrowserService} from '../../core/services/browser.service';
import {RoutingHelper} from '../../core/util/routing.helper';

@Component({
  selector: 'app-user-alert',
  templateUrl: './user-alert.component.html'
})
export class UserAlertComponent implements OnInit, OnDestroy {
  showHiddenAlert = false;
  showEmailVerificationAlert = false;

  $user : Subscription = null;

  constructor(
      public userProvider: UserService,
      public browserService: BrowserService,
      public routingHelper: RoutingHelper
  ) { }

  ngOnInit() {
    this.$user = this.userProvider.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.showHiddenAlert = user.hidden;
        this.showEmailVerificationAlert = !user.emailVerified;
      }
    })
  }

  ngOnDestroy(): void {
    this.$user.unsubscribe();
  }

}
