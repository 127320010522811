import { IWidgetDefinition } from '../../core/model/widget.model';

export default {
  icon: { name: 'grid-outline' },
  fields: [
    {
      id: 'title',
      label: 'Title',
      input: 'text',
      required: true,
    },
    {
      id: 'subtitle',
      label: 'Subtitle',
      input: 'text',
      required: true,
    },
    {
      id: 'items',
      label: 'Projects',
      input: 'complex-list',
      subtype: [
        {
          id: 'name',
          input: 'text',
          label: 'Project name',
          required: true,
        },
        {
          id: 'abbrev',
          input: 'text',
          label: 'Name abbreviation',
          required: false,
        },
        {
          id: 'image',
          input: 'image_upload',
          label: 'Project image',
          required: false,
        },
        {
          id: 'url',
          input: 'text',
          label: 'Project URL',
          required: true,
        },
        {
          id: 'country',
          input: 'text',
          label: 'Project country',
          required: true,
        },
      ],
      required: false,
    },
  ],
} as IWidgetDefinition;
