<div *ngIf="editMode && updateAllowed" class="d-grid gap-2 mb-3">
  <button type="button"
          class="btn btn-primary"
          (click)="createPage()"
  >
    <i class="fa-solid fa-plus me-2"></i> New Child Page
  </button>
</div>

<app-icon-box [icon]="data.icon" [headline]="translationHelper.getBestLanguageValue(data.headline)">
  <div *ngIf="translationHelper.getBestLanguageValue(data.description)" [innerHTML]="translationHelper.getBestLanguageValue(data.description)" class="description"></div>

  <div *ngIf="data.itemDisplay === 'table'" class="table-responsive">
    <table class="table">
      <tbody>
        <tr *ngFor="let hit of results; let i = index">
          <td><a [routerLink]="hit.route" href="{{hit.route}}">{{hit.title}}</a></td>
        </tr>
      </tbody>
    </table>
  </div>

</app-icon-box>

<div *ngIf="data.itemDisplay === 'grid'" class="row">
  <div *ngFor="let hit of results; let i = index"
       class="{{getColSizeClasses(displaySizes) + ' mb-4'}}"
  >
    <app-tile title="{{hit.title}}"
              [route]="hit.route"
              [picture]="hit.cover"
              [colSizes]="colSizes"
              [aspectRatio]="data.aspectRatio || .6"
    >
    </app-tile>
  </div>
</div>
