<div *ngIf="showHiddenAlert || showEmailVerificationAlert" class="container">
  <div class="alert alert-danger text-center">
    <div *ngIf="showHiddenAlert">
      {{'page.messages.hiddenModeActive' | translate }} <button class="btn btn-outline-primary btn-sm" type="button" (click)="userProvider.changeHiddenMode(false)">{{'page.messages.unhideAccount' | translate}}</button>
    </div>
    <div *ngIf="showEmailVerificationAlert">
      Your email is not verified, and you'll not receive notifications or emails from dw-connect. <button class="btn btn-outline-primary btn-sm" type="button" (click)="browserService.navigateToSso(routingHelper.getSsoEmailChangeRoute())">Verify Email</button>
    </div>
  </div>
</div>
