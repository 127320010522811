<div class="max-w-lg mx-md-auto position-relative fw-normal" >
  <div class="mb-4 mt-5 d-flex flex-wrap">

    <a *ngFor="let filter of newsFilters" [routerLink]="[]" [queryParams]="{filterRef: filter.ref}" class="d-inline-block mb-2 px-1">
      <span *ngIf="filter.current" class="bg-danger text-white text-md-center fw-bold rounded-3 d-inline-flex items-center py-1 px-3">
        {{ filter.displayName }}
      </span>
      <span *ngIf="!filter.current" class="d-inline-block shadow text-black text-md-center fw-bold rounded-3 py-1 px-3">
        {{ filter.displayName }}
      </span>

    </a>
  </div>

  <div *ngIf="suggestFollowing">
    <div class="list-unstyled p-5 mb-5 rounded-4 bg-white shadow">
      <div class="mb-2">You aren't following {{filteredFollowableTitle}} yet.</div>
      <div>Go to <a [href]="filteredFollowableRoute"  [routerLink]="filteredFollowableRoute" class="fw-bold">{{filteredFollowableTitle}}</a> and hit "Follow" to see updates and get notifications.</div>
    </div>
  </div>

  <div #feed>
    <ul *ngFor="let post of posts" class="m-0 p-0 post" [attr.data-post-id]="post.id" id="post-{{post.id}}">
      <li class="list-unstyled mb-5 rounded-4 bg-white shadow container px-1">
        <div *ngIf="post.images?.length === 1"
             [routerLink]="post.data?.includeVideo ? post.route : null"
             [class.cursor-pointer]="post.data?.includeVideo"
        >
          <div *ngFor="let image of imageTilingHelper.subsetOfImages(post); let imageIndex = index;" class="col py-1" [ngClass]="imageTilingHelper.rowClass(post, imageIndex)">
            <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, imageIndex)"
                     [image]="image.image" width="800px"
                     [align]="'alone'"
                     [smartCrop]="true"
                     [roundedCorners]="true"
                     (click)="!post.data.includeVideo ? openModal(post, imageIndex): null"
              ></app-img>
          </div>
        </div>
          <div *ngIf="post.images?.length > 1">
              <div class="row gx-1 gy-1">
                <div class="col-6">
                  <div class="row h-50">
                    <div class="col">
                      <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 0)"
                               [image]="imageTilingHelper.subsetOfImages(post)[0]?.image" width="264px"
                               [align]="'alone'"
                               [smartCrop]="true"
                               [roundedCorners]="true"
                               (click)="!post.data.includeVideo ? openModal(post, 0): null"
                      ></app-img>
                    </div>
                  </div>
                </div>

                <div *ngIf="post.images?.length === 2" class="col-6">
                  <div class="row h-50">
                    <div class="col">
                      <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 1)"
                               [image]="imageTilingHelper.subsetOfImages(post)[1]?.image" width="264px"
                               [align]="'alone'"
                               [smartCrop]="true"
                               [roundedCorners]="true"
                               (click)="!post.data.includeVideo ? openModal(post, 1): null"
                      ></app-img>
                    </div>
                  </div>
                </div>

                <div *ngIf="post.images?.length === 3" class="col-6 d-flex flex-column gap-1">
                  <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 1)"
                           [image]="imageTilingHelper.subsetOfImages(post)[1]?.image"
                           width="264px"
                           [align]="'alone'"
                           [smartCrop]="true"
                           [roundedCorners]="true"
                           (click)="!post.data.includeVideo ? openModal(post, 1): null"
                  ></app-img>
                  <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 2)"
                           [image]="imageTilingHelper.subsetOfImages(post)[2]?.image"
                           width="264px"
                           [align]="'alone'"
                           [smartCrop]="true"
                           [roundedCorners]="true"
                           (click)="!post.data.includeVideo ? openModal(post, 2): null"
                  ></app-img>
                </div>
                <div *ngIf="post.images?.length > 3" class="col-6 d-flex flex-column gap-1">
                  <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 1)"
                           [image]="imageTilingHelper.subsetOfImages(post)[1]?.image"
                           width="264px"
                           [align]="'alone'"
                           [smartCrop]="true"
                           [roundedCorners]="true"
                           (click)="!post.data.includeVideo ? openModal(post, 1): null"
                  ></app-img>
                  <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 2)"
                           [image]="imageTilingHelper.subsetOfImages(post)[2]?.image"
                           width="264px"
                           [align]="'alone'"
                           [smartCrop]="true"
                           [roundedCorners]="true"
                           (click)="!post.data.includeVideo ? openModal(post, 2): null"
                  ></app-img>
                <div class="position-relative">
                  <app-img [aspectRatio]="imageTilingHelper.aspectRatio(post, 3)" [image]="imageTilingHelper.subsetOfImages(post)[3]?.image" width="264px" [align]="'alone'" [smartCrop]="true" [roundedCorners]="true" (click)="!post.data.includeVideo ? openModal(post, 3): null"></app-img>
                <a [href]="post.route"  [routerLink]="post.route" class="d-block position-absolute top-0 left-0 w-100 h-100 bg-black opacity-75 rounded-3">
                  <div class="d-flex align-items-center justify-content-center h-100 w-100 text-white fs-6 z-1">
                  <div>+ {{imageTilingHelper.numberOfImagesAfterCutoff(post)}} more</div>
                  </div>
                </a>
                </div>
                </div>
              </div>
          </div>
          <a [href]="post.route"  [routerLink]="post.route" class="d-block text-decoration-none">
            <div class="py-3 px-4 text-black">
              <div class="d-flex justify-content-between align-items-center">
                <span class="d-inline-block text-truncate py-1 px-3 fs-7 text-uppercase rounded-5 bg-danger text-white fw-bold">{{ post.parentTitle }}</span>

                <span class="d-inline-block ps-1 text-nowrap text-end text-gray fs-7">
                  {{post.created.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})}}
                  <span *ngIf="(post.created.getTime() + 600000) < post.lastModified.getTime()"> - updated</span>
                </span>
              </div>
              <div class="mt-3">
                <span class="text-black fw-semibold fs-5" [innerHTML]="translationHelper.getBestLanguageValue(post.title)"></span>
              </div>

              <div *ngIf="translationHelper.getBestLanguageValue(post.content)?.length" class="mt-1 post-card__content" [innerHTML]="translationHelper.getBestLanguageValue(post.content)"></div>
              <div class="d-flex justify-content-end align-items-center text-gray fs-7">
                <i class="fa-regular fa-eye me-1"></i>
                {{post.views}}
              </div>
            </div>
          </a>
      </li>
    </ul>
  </div>
</div>
