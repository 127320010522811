import { Injectable } from '@angular/core';
import {Oauth2Service} from "./oauth2.service";

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard {

  constructor(
      private authHelperService: Oauth2Service
  ) { }

  async canActivate(): Promise<boolean> {
    const token = await this.authHelperService.getValidToken();

    if (!token) {
      // sign in again
      await this.authHelperService.signIn();
    }

    return true;
  }

}
