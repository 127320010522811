import iconSelect from '../../core/util/forms/icons.select';
import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    icon: {style: "fa-solid", name: "book"},
    fields: [
        iconSelect,
        {
            id: 'headline',
            input: 'text',
            i18n: true,
            width: 8
        },
        {
            id: 'content',
            label: 'forms.labels.htmlInput',
            input: 'html',
            required: true,
            i18n: true
        }
    ]
} as IWidgetDefinition)
