import { Injectable } from '@angular/core';
import {version} from '../../../environments/app.environments';
import {StorageMap} from "@ngx-pwa/local-storage";
import {firstValueFrom} from "rxjs";

interface IStoredData {
  v: string
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class CachedStorageService {

  private isFresh: Record<string, boolean|string> = {}

  constructor(
      private storage: StorageMap
  ) { }

  public async set (key: string, data: any) {
    const storedData: IStoredData = {v: version, data};
    await firstValueFrom(this.storage.set(key, storedData));
    this.isFresh[key] = true;
  }

  public async get (key: string) {
    const storedData: IStoredData = await firstValueFrom(this.storage.get(key)) as IStoredData;
    return storedData?.v === version ? storedData.data : null;
  }

  async getAndRefresh (key: string, retrieverFnc: Function, onUpdated: Function) {

    let data = await this.get(key);

    if (data !== null && data !== undefined) {

      if (!this.isFresh[key]) {

        console.log('refetching ' + key);

        this.isFresh[key] = 'loading';

        retrieverFnc().then(async (freshData: any) => {
          await this.set(key, freshData);
          onUpdated(freshData);
        })
      }

    } else {
      console.log('empty storage for ' + key);
      data = await retrieverFnc();
      await this.set(key, data);
    }

    return data;
  }

  async clear (key: string) {
    await firstValueFrom(this.storage.delete(key));
  }
}
