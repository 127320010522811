export const environment = {
  production: true,
  mapbox: {
    publicToken: 'pk.eyJ1IjoiZHctY29ubmVjdCIsImEiOiJja21kdThjcGQycGZrMnFqeDhzbnhvOXg1In0.kdHv4ZAzwh1SfGC5D54pTw',
    styleUrl: 'mapbox://styles/dw-connect/ckmduc3rbakht17q3iv0a98zb'
  },
  here: {
    apiKey: 'xLfuHRUEdHCtdBgeqGRIUI1_54Bf3-3V9YnSPIl7HJQ'
  }
}
