import {IWidgetDefinition} from "../../core/model/widget.model";

export default {
  icon: { style: 'fa-solid', name: 'tag'},
  category: 'content',
  fields: [
    {
      id: 'categories',
      input: 'complex-list',
      subtype: [
        {
          id: 'id',
          input: 'text',
          required: true,
          description: 'Which bundle of categories to display?'
        }
      ],
    },
  ]
} as IWidgetDefinition;
