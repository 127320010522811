import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html'
})
export class PageLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
