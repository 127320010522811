import {WidgetGridComponent} from './widget-grid.component';
import {IWidgetDefinition} from '../../core/model/widget.model';
import iconSelect from '../../core/util/forms/icons.select';
import {WIDGET_TYPE} from "../../core/util/consts/widget.consts";
import {PARENT_TYPE} from "../../core/util/consts/parentType.consts";

const ifNotAllowCreate = (fieldValues) => {
  return !fieldValues.allowCreate;
};

const ifAllowCreate = (fieldValues) => {
  return fieldValues.allowCreate;
};

const ifNotFromOtherCollection = (fieldValues) => {
  return !fieldValues.targetId;
};

const ifIsGrid = (fieldValues) => {
  return fieldValues.widgetGridDisplay === 'grid';
};

const ifIsSlider = (fieldValues) => {
  return fieldValues.widgetGridDisplay === 'slider';
};

const ifIsPost = (fieldValues) => {
  return fieldValues.type === WIDGET_TYPE.POST;
}

const ifIsBook = (fieldValues) => {
  return fieldValues.type === WIDGET_TYPE.BOOK;
}

const ifIsPostOfEvents = (fieldValues) => {
  return ifIsPost(fieldValues) && fieldValues.postsOf === 'events';
}

const ifIsPostNotOfEvents = (fieldValues) => {
  return ifIsPost(fieldValues) && fieldValues.postsOf !== 'events';
}

const isBoardMembers = (fieldValues) => {
  return fieldValues.type === WIDGET_TYPE.ORG_BOARD;
}


export default {
  getComponent: () => WidgetGridComponent,
  category: 'structure',
  icon: {style: 'fa-solid', name: 'border-all'},
  fields: [
    {
      id: 'allowCreate',
      input: 'boolean',
      depends: ifNotFromOtherCollection
    },
    {
      id: 'targetId',
      label: 'Display Items of another Collection?',
      input: 'number',
      depends: ifNotAllowCreate
    },
    {
      id: 'type',
      input: 'select',
      options: [
        { label: 'widgets.names.letter', value: WIDGET_TYPE.LETTER },
        { label: 'widgets.names.book', value: WIDGET_TYPE.BOOK },
        { label: 'widgets.names.inspiration', value: WIDGET_TYPE.INSPIRATION },
        { label: 'widgets.names.exhibition', value: WIDGET_TYPE.EXHIBITION },
        { label: 'widgets.names.orgboard', value: WIDGET_TYPE.ORG_BOARD },
        { label: 'widgets.names.post', value: WIDGET_TYPE.POST },
        { label: 'widgets.names.image', value: WIDGET_TYPE.IMAGE },
        { label: 'widgets.names.video', value: WIDGET_TYPE.VIDEO }
      ],
      required: true,
      width: 6,
      depends: ifNotFromOtherCollection
    },
    {
      id: 'childType',
      input: 'select',
      options: [
        { label: 'widgets.names.standardPost', value: '' },
        { label: 'widgets.names.letter', value: WIDGET_TYPE.LETTER },
        { label: 'widgets.names.room', value: WIDGET_TYPE.ROOM },
      ],
      width: 6,
      depends: (fieldValues) => {
        return ifIsPost(fieldValues) && ifNotFromOtherCollection(fieldValues)
      },
    },
    {
      id: 'postsOf',
      depends: (fieldValues) => {
        return ifIsPost(fieldValues) && ifNotFromOtherCollection(fieldValues)
      },
      input: 'select',
      label: 'Display posts of',
      options: [
      //  { label: 'Events', value: PARENT_TYPE.EVENTS }, // @todo - can this whole functionality be removed?
        { label: 'This Widget Grid', value: PARENT_TYPE.WIDGET },
        { label: 'Center', value: PARENT_TYPE.CENTER },
        { label: 'Project', value: PARENT_TYPE.PROJECT },
        { label: 'Page', value: PARENT_TYPE.PAGE }
      ],
      width: 6,
      required: true,
    },
    {
      id: 'tag',
      depends: (fieldValues) => {
        return ifIsPostOfEvents(fieldValues) && ifNotFromOtherCollection(fieldValues)
      },
      input: 'text',
      label: 'Events Technical Tag'
    },
    {
      id: 'additionalEventParameters',
      label: 'Advanced: Additional Event Api Parameters (json)',
      input: 'text',
      depends: (fieldValues) => {
        return ifIsPostOfEvents(fieldValues) && ifNotFromOtherCollection(fieldValues)
      },
    },
    {
      id: 'parentId',
      depends: (fieldValues) => {
        return ifNotFromOtherCollection(fieldValues) && ifIsPostNotOfEvents(fieldValues) && fieldValues.postsOf !== 'widget';
      },
      label: 'Parent Id',
      input: 'text',
      width: 6,
      required: true,
    },
    {
      id: 'hideFollowButton',
      label: 'Hide Follow Button',
      input: 'boolean',
      depends: ifIsPost
    },
    {
      id: 'exportable',
      label: 'Exportable',
      input: 'boolean',
      depends: isBoardMembers
    },
    iconSelect,
    {
      id: 'headline',
      input: 'text',
      i18n: true,
      width: 8
    },
    {
      id: 'description',
      input: 'html',
      i18n: true
    },
    {
      id: 'widgetGridDisplay',
      input: 'select',
      options: [
        { label: 'widgets.names.grid', value: 'grid' },
        { label: 'widgets.names.accordion', value: 'accordion' },
        { label: 'widgets.names.slider', value: 'slider' },
      ],
      default: 'grid',
      width: 4,
      required: true,
    },
    {
      id: 'cols',
      label: 'How many items per row?',
      input: 'number',
      default: 3,
      width: 4,
      required: true,
      depends: ifIsGrid
    },
    {
      id: 'slideWidth',
      label: 'Slide item width in px',
      input: 'number',
      default: 200,
      width: 4,
      required: false,
      depends: ifIsSlider
    },
    {
      id: 'limit',
      description: 'forms.descriptions.limit',
      input: 'number',
      default: 0,
      width: 4,
    },
    {
      id: 'hideLink',
      input: 'boolean',
      depends: ifNotAllowCreate
    },
    {
      id: 'linkText',
      i18n: true,
      input: 'text',
      depends: (fieldValues) => {
        return ifNotAllowCreate(fieldValues) && !fieldValues.hideLink;
      }
    },

    {
      id: 'categories',
      input: 'categories',
      depends: (fieldValues) => {
        return ifNotFromOtherCollection(fieldValues) && ifIsPostNotOfEvents(fieldValues);
      },
      description: 'Manage here the available post categories. Categories can be used to filter and sort posts.',
      parentMap: {
        parentIdField: 'parentId',
        parentTypeField: 'postsOf'
      }
    },

    {
      id: 'limitByCategories',
      label: false,
      input: 'category-select',
      depends: (fieldValues) => {
        return ifNotFromOtherCollection(fieldValues) && ifIsPostNotOfEvents(fieldValues);
      },
      custom: {
        label: 'Only show posts with the following categories',
        description: 'If nothing is selected, all are displayed'
      }
    },

    {
      id: 'categories',
      input: 'complex-list',
      depends: (fieldValues) => {
        return ifAllowCreate(fieldValues) && ifNotFromOtherCollection(fieldValues) && ifIsBook(fieldValues);
      },
      subtype: [
        {
          id: 'id',
          input: 'text',
          required: true,
        },
        {
          id: 'label',
          input: 'text',
          i18n: true,
          required: true,
        },
      ],
    },
    {
      id: 'implicitFilter',
      label: 'Advanced: Implicit Filter (json)',
      input: 'text',
      depends: ifNotFromOtherCollection,
      description: 'Is used in an andX db filter. Please only use this field, if you know what you are doing. fields of the current user can be dynamically included via <strong>$user</strong>.[fieldName]'
    }

  ],
} as IWidgetDefinition;
