/**
 * myRecordings api!
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1.2.55
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MediaSpecial } from '../model/mediaSpecial';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MediaSpecialService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     * Return MediaSpecial details
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaSpecial(slug: string, observe?: 'body', reportProgress?: boolean): Observable<MediaSpecial>;
    public mediaSpecial(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MediaSpecial>>;
    public mediaSpecial(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MediaSpecial>>;
    public mediaSpecial(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling mediaSpecial.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MediaSpecial>(`${this.basePath}/api/v1/mediaSpecials/${encodeURIComponent(String(slug))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return MediaSpecial Audio Uri
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaSpecialAudioUrl(slug: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public mediaSpecialAudioUrl(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public mediaSpecialAudioUrl(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public mediaSpecialAudioUrl(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling mediaSpecialAudioUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/v1/mediaSpecials/${encodeURIComponent(String(slug))}/audioUri`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return MediaSpecial Video Uri
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaSpecialVideoUrl(slug: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public mediaSpecialVideoUrl(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public mediaSpecialVideoUrl(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public mediaSpecialVideoUrl(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling mediaSpecialVideoUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/v1/mediaSpecials/${encodeURIComponent(String(slug))}/videoUri`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return MediaSpecials of the User
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaspecials(observe?: 'body', reportProgress?: boolean): Observable<Array<MediaSpecial>>;
    public mediaspecials(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MediaSpecial>>>;
    public mediaspecials(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MediaSpecial>>>;
    public mediaspecials(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MediaSpecial>>(`${this.basePath}/api/v1/mediaSpecials`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
