/**
 * myRecordings api!
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1.2.55
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { AssetView } from '../model/assetView';
import { FavoriteAsset } from '../model/favoriteAsset';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {Observable} from "rxjs";


@Injectable()
export class AssetService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     * Return Asset Audio Uri
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public audioUrl(slug: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public audioUrl(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public audioUrl(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public audioUrl(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling audioUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/audioUri`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Download mp3
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAsset(slug: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadAsset(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadAsset(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadAsset(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling downloadAsset.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Download mp3
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAsset_1(slug: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadAsset_1(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadAsset_1(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadAsset_1(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling downloadAsset_1.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/download.mp3`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Toggles favorite
     * @param slug The slug of the asset
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public toggleFavoriteAsset(slug: string, observe?: 'body', reportProgress?: boolean): Observable<FavoriteAsset>;
    public toggleFavoriteAsset(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavoriteAsset>>;
    public toggleFavoriteAsset(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavoriteAsset>>;
    public toggleFavoriteAsset(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling toggleFavoriteAsset.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<FavoriteAsset>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/favorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return Asset Video Uri
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoUrl(slug: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public videoUrl(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public videoUrl(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public videoUrl(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling videoUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/videoUri`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return Asset Video Uri for DASH
     * @param slug
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoUrlDash(slug: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public videoUrlDash(slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public videoUrlDash(slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public videoUrlDash(slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling videoUrlDash.');
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/videoUriDash`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Saves the view state of a asset
     * @param slug The slug of the asset
     * @param position The Position where we are atm
     * @param assetViewId The assetViewId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viewAsset(slug: string, position?: number, assetViewId?: number, observe?: 'body', reportProgress?: boolean): Observable<AssetView>;
    public viewAsset(slug: string, position?: number, assetViewId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssetView>>;
    public viewAsset(slug: string, position?: number, assetViewId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssetView>>;
    public viewAsset(slug: string, position?: number, assetViewId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling viewAsset.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (position !== undefined && position !== null) {
            queryParameters = queryParameters.set('position', <any>position);
        }
        if (assetViewId !== undefined && assetViewId !== null) {
            queryParameters = queryParameters.set('assetViewId', <any>assetViewId);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<AssetView>(`${this.basePath}/api/v1/asset/${encodeURIComponent(String(slug))}/view`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
