import {Injectable } from '@angular/core';
import {Apollo } from 'apollo-angular';
import {GET_MEMBER_DATA } from './gql-query.service';
import {IUser} from "../../model/user.model";
import {StorageMap} from "@ngx-pwa/local-storage";
import {FetchPolicy} from "@apollo/client/core/watchQueryOptions";
import {firstValueFrom} from "rxjs";
import {cloneDeep} from "lodash-es";

const FETCH_POLICY_KEY = 'gql-fetch-policy';

@Injectable({
  providedIn: 'root',
})
export class GqlService {

  constructor(
      private apollo: Apollo,
      private storage: StorageMap
  ) {}

  async getCurrentFetchPolicy () : Promise<FetchPolicy> {
    const policy = await firstValueFrom(this.storage.get(FETCH_POLICY_KEY)) as FetchPolicy;

    console.log('fetch policy is', policy || 'cache-first');

    return policy || 'cache-first';
  }

  async setCurrentFetchPolicy (policy: string) {
    console.log('fetch policy change to', policy);
    await firstValueFrom(this.storage.set(FETCH_POLICY_KEY, policy));
  }

  async runQuery(gqlQuery: any, vars: object = {}) {
    return new Promise((resolve, reject) => {
      this.apollo
        .use('app')
        .watchQuery({
          query: gqlQuery,
          variables: vars
        })
        .valueChanges.subscribe((result) => {
          if (!result.errors) {
            resolve(cloneDeep(result.data));
          }
          reject();
        });
    });
  }

  async runSsoQuery(gqlQuery: any, vars: object = {}) {
    return new Promise((resolve, reject) => {
      this.apollo
        .use('sso')
        .watchQuery({
          query: gqlQuery,
          variables: vars,
        })
        .valueChanges.subscribe((result) => {
          if (!result.errors) {
            resolve(result.data);
          }
          reject();
        });
    });
  }

  async fetchUser(username: string) : Promise<IUser> {

    const result : any = await firstValueFrom(this.apollo.use('app').query({
      query: GET_MEMBER_DATA,
      variables: { userName: username }
    })).catch(() => {
      // silent fail
    })

    return result?.data?.member;
  }
}
