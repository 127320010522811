export function getPhoneAsTel(phoneNumber) {
    if (typeof phoneNumber === 'string') {
        return phoneNumber.replace(/[^0-9+]/g, '')
    }
    return '';
}

/**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getFileNameFromSrc(src) {
    return src.split('\\').pop().split('/').pop();
}

export function makeSlug(str:string, noTrim = false) {
    if (!noTrim) {
      str = str.replace(/^\s+|\s+$/g, '');
    } // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'ßàáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to   = 'saaaaeeeeiiiioooouuuunc------';
    for (let i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}
