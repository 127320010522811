import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    icon: { style: 'fa-solid', name: 'puzzle-piece'},
    fields: [
        {
            id: 'title',
            input: 'text',
            i18n: true,
            required: true
        },
        {
            id: 'important',
            input: 'boolean',
            label: 'Especially important event'
        },
        {
            id: 'startDate',
            input: 'date',
            required: true,
            width: 6
        },
        {
            id: 'endDate',
            input: 'date',
            required: false,
            width: 6
        },
        {
            id: 'accuracy',
            label: 'Date Accuracy',
            input: 'select',
            required: true,
            description: 'Is the date of this event known exactly or do we need to guess?',
            options: [
                {value: 'YMD', label: 'Exact'},
                {value: 'YM', label: 'Month'},
                {value: 'Y', label: 'Year'},
            ]
        },
        {
            id: 'location',
            input: 'geolocation',
            description: 'Please mark the location of the event - as exact as possible, kind of close by will be also fine.',
            required: true
        },
        {
            id: 'description',
            input: 'html',
            i18n: true
        },
        {
            id: 'images',
            input: 'complex-list',
            subtype: [
                {
                    id: 'image',
                    input: 'image_upload',
                    multiple: false
                },
                {
                    id: 'title',
                    input: 'text',
                },
                {
                    id: 'copyright',
                    input: 'text',
                    label: 'Image Copyright / Reference'
                }
            ],
        }
    ]
} as IWidgetDefinition)
