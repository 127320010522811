import { IWidgetDefinition } from '../../core/model/widget.model';
import iconSelect from '../../core/util/forms/icons.select';
import { MenuComponent } from './menu.component';

export default {
  icon: { style: 'fa-solid', name: 'bars' },
  getComponent: () => MenuComponent,
  category: 'structure',
  fields: [
    {
      id: 'headline',
      input: 'text',
      label: 'Headline',
      i18n: true,
    },
    {
      id: 'display',
      input: 'select',
      options: [
        { value: 'horizontal', label: 'Horizontal' },
        { value: 'vertical', label: 'Vertical' },
      ],
      label: 'Menu Display',
      default: 'horizontal',
      required: true,
    },
    {
      id: 'size',
      input: 'select',
      label: 'Size',
      options: [
        { value: 'large', label: 'Large (heading size)' },
        { value: 'standard', label: 'Standard (text size)' },
        { value: 'small', label: 'Small (additional links)' },
      ],
      default: 'standard',
      required: true,
    },
    {
      id: 'entries',
      input: 'complex-list',
      subtype: [
        {
          id: 'route',
          input: 'route',
          label: 'DW Connect Page',
          description:
            'Please make sure that the full relative dw-connect link of an existing page is entered.',
          required: true,
        },
        iconSelect,
        {
          id: 'label',
          input: 'text',
          label: 'Label',
          i18n: true,
          required: true,
          width: 8,
        },
      ],
    },
  ],
} as IWidgetDefinition;
