import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {PageFragment} from '../fragments/page.fragments';
import {MessageService} from '../message.service';
import {IWidget} from '../../model/widget.model';
import {WIDGET_TYPE} from '../../util/consts/widget.consts';
import {GqlService} from '../GQL/gql.service';
import {firstValueFrom} from "rxjs";
import {cloneDeep} from "lodash-es";

export const WidgetFragment = gql`
  fragment WidgetFragment on Widget {
    id
    type
    category
    data
    sort
  }
`;

const GET_WIDGET_CREATE_QUERY = gql`
    query widgetQuery ($id: Int) {
        widget(id: $id) {
          id
          created
          createdBy {
            id
            username
            givenName
            familyName
          }
          lastModified
          lastModifiedBy {
            id
            username
            givenName
            familyName
          }
        }
    }
`

const GET_WIDGET_DETAIL_QUERY = gql`
    query widgetQuery ($id: Int) {
        widget(id: $id) {
          ...WidgetFragment
          preventUnsubscribe
          pages {
            id
            route
            title
          }
          parent {
            ...WidgetFragment
            pages {
              id
              route
              title
            }
          }
        }
    }
    ${WidgetFragment}
`;

const GET_WIDGETS_QUERY = gql`
  query widgetQuery ($limit: Int!, $filter: String, $id: Int, $offset: Int, $sort: String, $dir: String) {
    widget_list(limit: $limit, filter: $filter, offset: $offset, sort: $sort, dir: $dir) {
      widgets {
        ...WidgetFragment
      }
    }
    widget(id: $id) {
      id
      pages {
        id
        route
      }
    }
  }
  ${WidgetFragment}
`;

const CREATE_WIDGET_MUTATION = gql`
  mutation addWidget($data: AddWidgetInput!) {
    addWidget (input: $data) {
       ...WidgetFragment
    }
  }
  ${WidgetFragment}
`;

const UPDATE_WIDGET_MUTATION = gql`
  mutation updateWidget($data: UpdateWidgetInput!) {
    updateWidget (input: $data) {
       ...WidgetFragment
    }
  }
  ${WidgetFragment}
`;

const DELETE_WIDGET_MUTATION = gql`
  mutation deleteWidget ($data: DeleteWidgetInput!) {
    deleteWidget (input: $data) {
       ...PageFragment
    }
  }
  ${PageFragment}
`;


@Injectable({
    providedIn: 'root'
})
export class WidgetService {

    constructor(
        private apollo: Apollo,
        private messageService: MessageService,
        private gqlService: GqlService
    ) { }

    calculateSortString (sort: string,type: string, data: any) {
        if (sort) return sort.toLocaleLowerCase();

        switch (type) {
            case WIDGET_TYPE.LETTER:
                sort = data.date.substr(0, 16);
                break;
        }

        return sort;
    }

    async getCollectionWidgetsByType (
        parentId: number,
        type: string,
        offset: number,
        limit: number,
        filter?: object,
        sort?: string,
        dir?: string
    ) {

        filter = filter ? {andX: [{eq: {parentId}}, filter]} : {eq: {parentId}};

        return await this.getWidgets(offset, limit, filter, parentId, sort, dir);
    }

    async getWidget (id: number) {
        const result : any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_WIDGET_DETAIL_QUERY,
                variables: {id}
            })).catch(() => {});

        return result ? cloneDeep(result.data.widget as IWidget) : null;
    }

    async getWidgetCreateInformation (id: number) {
        const result : any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_WIDGET_CREATE_QUERY,
                variables: {id}
            })).catch(() => {});

        return result ? (result.data.widget as IWidget) : null;
    }

    async getWidgets (offset: number, limit: number, filter: any, parentId: number, sort?: string, dir?: string) {
        const fetchPolicy = await this.gqlService.getCurrentFetchPolicy()
        const result : any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_WIDGETS_QUERY,
                variables: {offset, limit, filter: JSON.stringify(filter), id: parentId, sort, dir},
                fetchPolicy
            }));

        return result ? cloneDeep(result.data) : null;
    }

    /**
     * This function only reads the local cache
     */
    async get (id) {
        return this.apollo.use('app').client.readFragment({
            id, // `id` is any id that could be returned by `dataIdFromObject`.
            fragment: WidgetFragment
        });
    }

    async create(type: string, data: any, backendOnlyData: any, pageId: number, sort: string) {
        const result = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: CREATE_WIDGET_MUTATION,
            variables: {
                data: {
                    pageId,
                    data: JSON.stringify(data),
                    backendOnlyData: JSON.stringify(backendOnlyData ? backendOnlyData : {}),
                    type,
                    sort: this.calculateSortString(sort, type, data)
                }
            }
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result ? (result.data as any).addWidget : null
    }

    async insertToCollection(type: string, data: any, backendOnlyData: any, pageId: number, parentId: number, category: string, sort: string) {
        const result = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: CREATE_WIDGET_MUTATION,
            variables: {
                data: {
                    pageId,
                    data: JSON.stringify(data),
                    backendOnlyData: JSON.stringify(backendOnlyData ? backendOnlyData : {}),
                    type,
                    parentId,
                    category,
                    sort: this.calculateSortString(sort, type, data)
                }
            }
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result ? (result.data as any).addWidget : null
    }

    async update (widgetId: number, type: string, data: any, backendOnlyData: any, pageId: number, category?: string, sort?:string ) {
        const input = {
            pageId,
            data: JSON.stringify(data),
            backendOnlyData: JSON.stringify(backendOnlyData ? backendOnlyData : {}),
            widgetId,
            sort: this.calculateSortString(sort, type, data)
        } as any;

        if (typeof category === 'string') {
            input.category = category;
        }

        const result = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: UPDATE_WIDGET_MUTATION,
            variables: {data: input}
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result ? (result.data as any).updateWidget : null
    }

    async delete(widgetId: number, pageId: number) {
        const result = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: DELETE_WIDGET_MUTATION,
            variables: {data: {pageId, widgetId}}
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result ? (result.data as any).deleteWidget : null
    }
}
