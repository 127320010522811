<div class="members-list__member"
     [class.members-list__tooltip]="tooltip"
     [class.standalone]="standalone"
     [class.me-3]="!standalone"
     [ngbTooltip]="tooltip ? member.givenName + ' ' + member.familyName + (isYourContact ? ' (your contact)' : '') : null"
     placement="bottom-start auto"
     [closeDelay]="50"
     triggers="hover focus click"
>
  <a *ngIf="linkToProfile" [routerLink]="linkToProfile ? routingHelper.getMemberRoute(member) : null" [class.no-link]="!linkToProfile">
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </a>

  <ng-container *ngIf="!linkToProfile">
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </ng-container>
</div>

<ng-template #image>
  <img [src]="(member.userData?.picture?.url ? imageProxy.getImageProxyUrl(member.userData.picture.url, 120, 120, false, null, member.enabled === false) : '/assets/imgs/nophoto_member.png')" class="members-list__member-image d-block" alt="{{member.givenName + ' ' + member.familyName + (isYourContact ? ' (your contact)' : '')}}"/>
  <i *ngIf="member.enabled === false" class="fa-solid fa-skull members-list__contact-icon text-dark border-dark" title="disabled account (Guest user)"></i>
  <i *ngIf="member.enabled !== false && isYourContact" class="fa-solid fa-user members-list__contact-icon" title="is your contact"></i>
  <i *ngIf="member.enabled !== false && isYou" class="fa-solid fa-star members-list__contact-icon" title="it's you"></i>
</ng-template>
