import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IAppIcon} from "../../core/model/app-icon.model";

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() icon: IAppIcon = null;

  constructor() {}

  ngOnInit() {}

}
