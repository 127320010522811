import {IWidgetDefinition} from "../../core/model/widget.model";

// standard fields should be in sync with post.def.ts
export default ({
  fields: [
    {
      id: 'center',
      input: 'center-select',
      required: true
    },
    {
        id: 'price',
        input: 'text',
        required: true,
        placeholder: '$ 108',
        description: 'The price for the room per month in your local currency. It can be added as a range, if you have multiple rooms available.'
    },
    {
        id: 'title',
        input: 'text',
        required: true,
        i18n: true
    },
    {
        id: 'content',
        input: 'html',
        i18n: true
    },
    {
        id: 'images',
        input: 'complex-list',
        label: 'Images',
        subtype: [
            {
                id: 'image',
                input: 'image_upload',
                multiple: false,
                required: true
            },
            {
                id: 'title',
                label: 'Description',
                input: 'text',
            }
        ]
    }
]
} as IWidgetDefinition);
