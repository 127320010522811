<app-modal-frame [title]="'overlays.welcome.title' | translate" [showFooter]="true" [allowClose]="false">

  <div slot="body">

    <i class="dw-icon-dorje d-block fs-huge text-primary text-center"></i>

    <p>{{ 'overlays.welcome.intro' | translate:{firstName: user?.givenName} }}</p>

    <p>{{'overlays.welcome.line1' | translate}}</p>

    <p [innerHTML]="'overlays.welcome.line2' | translate"></p>
    <p [innerHTML]="'overlays.welcome.line3' | translate:{profileLink : routingHelper.getMemberRoute(user)}"></p>
  </div>

  <div slot="footer" class="grid g-2">
    <button type="button" class="btn btn-primary me-2" (click)="changeHiddenStatus(false)">{{'overlays.welcome.btnAgree' | translate}}</button>
    <button type="button" class="btn btn-outline-primary" (click)="changeHiddenStatus(true)">{{'overlays.welcome.stayHidden' | translate}}</button>
  </div>

</app-modal-frame>
