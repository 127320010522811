import {IPost} from '../../core/model/post.model';

export function transformPostToInspiration (post: IPost) {
    return {
        id: post.id,
        type: 'inspiration',
        // @ts-ignore
        parentType: post.parentType,
        parentId: post.parentId,
        data: post as any
    }
}