import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from "swiper/types";

@Directive({
  selector: '[appSwiper]'
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;
  @Output() slidechange = new EventEmitter();
  @Output() initialized = new EventEmitter();

  private defaultConfig : SwiperOptions = {
    speed: 400,
    initialSlide: 0,
    noSwiping: false,
    noSwipingClass: 'center-slider',
    spaceBetween: 30,
  }

  constructor(private el: ElementRef<SwiperContainer>) { }

  ngAfterViewInit(): void {

    const usedConfig = Object.assign({}, this.defaultConfig, this.config);

    // console.log('swiper init', usedConfig);

    Object.assign(this.el.nativeElement, usedConfig);

    this.el.nativeElement.initialize();

    if (usedConfig.initialSlide) {
      this.el.nativeElement.swiper.slideTo(usedConfig.initialSlide, 0);
    }

    this.el.nativeElement.swiper.on('slideChange', () => {
      this.slidechange.emit(this.el.nativeElement.swiper.activeIndex);
    });

    this.initialized.emit(this.el.nativeElement.swiper);
  }
}
