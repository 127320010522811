import {Component, OnInit, Input} from '@angular/core';
import {version} from 'src/environments/app.environments';
import {UserService} from "../../core/services/user.service";
import {EnvironmentService} from "../../core/services/environment.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    version = version;
    copyRightYear: number;

    constructor(
        public userProvider: UserService,
        public envService: EnvironmentService,
    ) {
        this.copyRightYear = (new Date()).getFullYear();
    }

    ngOnInit() {
    }

}
