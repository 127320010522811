/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClosedtimeEventsRead } from '../model/closedtimeEventsRead';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClosedtimeService {

    protected basePath = 'https://eventapi.dwbn.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves the collection of Closedtime resources.
     *
     * @param audiences
     * @param eventStatus
     * @param eventStatus2
     * @param centersId OrX Filter, combine with others! (DEPRECATED)
     * @param centersId2 OrX Filter, combine with others! (DEPRECATED)
     * @param centersSlug OrX Filter, combine with others! (DEPRECATED)
     * @param centersSlug2 OrX Filter, combine with others! (DEPRECATED)
     * @param relevantForRegionsId OrX Filter, combine with others!
     * @param relevantForRegionsId2 OrX Filter, combine with others!
     * @param relevantForRegionsSlug OrX Filter, combine with others!
     * @param relevantForRegionsSlug2 OrX Filter, combine with others!
     * @param relevantForCountriesId OrX Filter, combine with others!
     * @param relevantForCountriesId2 OrX Filter, combine with others!
     * @param relevantForCountriesCode OrX Filter, combine with others!
     * @param relevantForCountriesCode2 OrX Filter, combine with others!
     * @param international OrX Filter, combine with others!
     * @param organizedInRegionsId OrX Filter, combine with others!
     * @param organizedInRegionsId2 OrX Filter, combine with others!
     * @param organizedInRegionsSlug OrX Filter, combine with others!
     * @param organizedInRegionsSlug2 OrX Filter, combine with others!
     * @param organizedInCountriesId OrX Filter, combine with others!
     * @param organizedInCountriesId2 OrX Filter, combine with others!
     * @param organizedInCountriesCode OrX Filter, combine with others!
     * @param organizedInCountriesCode2 OrX Filter, combine with others!
     * @param _public Default is True
     * @param startDateBefore
     * @param startDateStrictlyBefore
     * @param startDateAfter
     * @param startDateStrictlyAfter
     * @param endDateBefore
     * @param endDateStrictlyBefore
     * @param endDateAfter
     * @param endDateStrictlyAfter
     * @param orderStartDate
     * @param page The collection page number
     * @param itemsPerPage The number of items per page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClosedtimeCollection(audiences?: string, eventStatus?: string, eventStatus2?: string, centersId?: string, centersId2?: string, centersSlug?: string, centersSlug2?: string, relevantForRegionsId?: string, relevantForRegionsId2?: string, relevantForRegionsSlug?: string, relevantForRegionsSlug2?: string, relevantForCountriesId?: string, relevantForCountriesId2?: string, relevantForCountriesCode?: string, relevantForCountriesCode2?: string, international?: boolean, organizedInRegionsId?: string, organizedInRegionsId2?: string, organizedInRegionsSlug?: string, organizedInRegionsSlug2?: string, organizedInCountriesId?: string, organizedInCountriesId2?: string, organizedInCountriesCode?: string, organizedInCountriesCode2?: string, _public?: boolean, startDateBefore?: string, startDateStrictlyBefore?: string, startDateAfter?: string, startDateStrictlyAfter?: string, endDateBefore?: string, endDateStrictlyBefore?: string, endDateAfter?: string, endDateStrictlyAfter?: string, orderStartDate?: string, page?: number, itemsPerPage?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ClosedtimeEventsRead>>;
    public getClosedtimeCollection(audiences?: string, eventStatus?: string, eventStatus2?: string, centersId?: string, centersId2?: string, centersSlug?: string, centersSlug2?: string, relevantForRegionsId?: string, relevantForRegionsId2?: string, relevantForRegionsSlug?: string, relevantForRegionsSlug2?: string, relevantForCountriesId?: string, relevantForCountriesId2?: string, relevantForCountriesCode?: string, relevantForCountriesCode2?: string, international?: boolean, organizedInRegionsId?: string, organizedInRegionsId2?: string, organizedInRegionsSlug?: string, organizedInRegionsSlug2?: string, organizedInCountriesId?: string, organizedInCountriesId2?: string, organizedInCountriesCode?: string, organizedInCountriesCode2?: string, _public?: boolean, startDateBefore?: string, startDateStrictlyBefore?: string, startDateAfter?: string, startDateStrictlyAfter?: string, endDateBefore?: string, endDateStrictlyBefore?: string, endDateAfter?: string, endDateStrictlyAfter?: string, orderStartDate?: string, page?: number, itemsPerPage?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClosedtimeEventsRead>>>;
    public getClosedtimeCollection(audiences?: string, eventStatus?: string, eventStatus2?: string, centersId?: string, centersId2?: string, centersSlug?: string, centersSlug2?: string, relevantForRegionsId?: string, relevantForRegionsId2?: string, relevantForRegionsSlug?: string, relevantForRegionsSlug2?: string, relevantForCountriesId?: string, relevantForCountriesId2?: string, relevantForCountriesCode?: string, relevantForCountriesCode2?: string, international?: boolean, organizedInRegionsId?: string, organizedInRegionsId2?: string, organizedInRegionsSlug?: string, organizedInRegionsSlug2?: string, organizedInCountriesId?: string, organizedInCountriesId2?: string, organizedInCountriesCode?: string, organizedInCountriesCode2?: string, _public?: boolean, startDateBefore?: string, startDateStrictlyBefore?: string, startDateAfter?: string, startDateStrictlyAfter?: string, endDateBefore?: string, endDateStrictlyBefore?: string, endDateAfter?: string, endDateStrictlyAfter?: string, orderStartDate?: string, page?: number, itemsPerPage?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClosedtimeEventsRead>>>;
    public getClosedtimeCollection(audiences?: string, eventStatus?: string, eventStatus2?: string, centersId?: string, centersId2?: string, centersSlug?: string, centersSlug2?: string, relevantForRegionsId?: string, relevantForRegionsId2?: string, relevantForRegionsSlug?: string, relevantForRegionsSlug2?: string, relevantForCountriesId?: string, relevantForCountriesId2?: string, relevantForCountriesCode?: string, relevantForCountriesCode2?: string, international?: boolean, organizedInRegionsId?: string, organizedInRegionsId2?: string, organizedInRegionsSlug?: string, organizedInRegionsSlug2?: string, organizedInCountriesId?: string, organizedInCountriesId2?: string, organizedInCountriesCode?: string, organizedInCountriesCode2?: string, _public?: boolean, startDateBefore?: string, startDateStrictlyBefore?: string, startDateAfter?: string, startDateStrictlyAfter?: string, endDateBefore?: string, endDateStrictlyBefore?: string, endDateAfter?: string, endDateStrictlyAfter?: string, orderStartDate?: string, page?: number, itemsPerPage?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (audiences !== undefined && audiences !== null) {
            queryParameters = queryParameters.set('audiences', <any>audiences);
        }
        if (eventStatus !== undefined && eventStatus !== null) {
            queryParameters = queryParameters.set('eventStatus', <any>eventStatus);
        }
        if (eventStatus2 !== undefined && eventStatus2 !== null) {
            queryParameters = queryParameters.set('eventStatus[]', <any>eventStatus2);
        }
        if (centersId !== undefined && centersId !== null) {
            queryParameters = queryParameters.set('centers.id', <any>centersId);
        }
        if (centersId2 !== undefined && centersId2 !== null) {
            queryParameters = queryParameters.set('centers.id[]', <any>centersId2);
        }
        if (centersSlug !== undefined && centersSlug !== null) {
            queryParameters = queryParameters.set('centers.slug', <any>centersSlug);
        }
        if (centersSlug2 !== undefined && centersSlug2 !== null) {
            queryParameters = queryParameters.set('centers.slug[]', <any>centersSlug2);
        }
        if (relevantForRegionsId !== undefined && relevantForRegionsId !== null) {
            queryParameters = queryParameters.set('relevantForRegions.id', <any>relevantForRegionsId);
        }
        if (relevantForRegionsId2 !== undefined && relevantForRegionsId2 !== null) {
            queryParameters = queryParameters.set('relevantForRegions.id[]', <any>relevantForRegionsId2);
        }
        if (relevantForRegionsSlug !== undefined && relevantForRegionsSlug !== null) {
            queryParameters = queryParameters.set('relevantForRegions.slug', <any>relevantForRegionsSlug);
        }
        if (relevantForRegionsSlug2 !== undefined && relevantForRegionsSlug2 !== null) {
            queryParameters = queryParameters.set('relevantForRegions.slug[]', <any>relevantForRegionsSlug2);
        }
        if (relevantForCountriesId !== undefined && relevantForCountriesId !== null) {
            queryParameters = queryParameters.set('relevantForCountries.id', <any>relevantForCountriesId);
        }
        if (relevantForCountriesId2 !== undefined && relevantForCountriesId2 !== null) {
            queryParameters = queryParameters.set('relevantForCountries.id[]', <any>relevantForCountriesId2);
        }
        if (relevantForCountriesCode !== undefined && relevantForCountriesCode !== null) {
            queryParameters = queryParameters.set('relevantForCountries.code', <any>relevantForCountriesCode);
        }
        if (relevantForCountriesCode2 !== undefined && relevantForCountriesCode2 !== null) {
            queryParameters = queryParameters.set('relevantForCountries.code[]', <any>relevantForCountriesCode2);
        }
        if (international !== undefined && international !== null) {
            queryParameters = queryParameters.set('international', <any>international);
        }
        if (organizedInRegionsId !== undefined && organizedInRegionsId !== null) {
            queryParameters = queryParameters.set('organizedInRegions.id', <any>organizedInRegionsId);
        }
        if (organizedInRegionsId2 !== undefined && organizedInRegionsId2 !== null) {
            queryParameters = queryParameters.set('organizedInRegions.id[]', <any>organizedInRegionsId2);
        }
        if (organizedInRegionsSlug !== undefined && organizedInRegionsSlug !== null) {
            queryParameters = queryParameters.set('organizedInRegions.slug', <any>organizedInRegionsSlug);
        }
        if (organizedInRegionsSlug2 !== undefined && organizedInRegionsSlug2 !== null) {
            queryParameters = queryParameters.set('organizedInRegions.slug[]', <any>organizedInRegionsSlug2);
        }
        if (organizedInCountriesId !== undefined && organizedInCountriesId !== null) {
            queryParameters = queryParameters.set('organizedInCountries.id', <any>organizedInCountriesId);
        }
        if (organizedInCountriesId2 !== undefined && organizedInCountriesId2 !== null) {
            queryParameters = queryParameters.set('organizedInCountries.id[]', <any>organizedInCountriesId2);
        }
        if (organizedInCountriesCode !== undefined && organizedInCountriesCode !== null) {
            queryParameters = queryParameters.set('organizedInCountries.code', <any>organizedInCountriesCode);
        }
        if (organizedInCountriesCode2 !== undefined && organizedInCountriesCode2 !== null) {
            queryParameters = queryParameters.set('organizedInCountries.code[]', <any>organizedInCountriesCode2);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (startDateBefore !== undefined && startDateBefore !== null) {
            queryParameters = queryParameters.set('startDate[before]', <any>startDateBefore);
        }
        if (startDateStrictlyBefore !== undefined && startDateStrictlyBefore !== null) {
            queryParameters = queryParameters.set('startDate[strictly_before]', <any>startDateStrictlyBefore);
        }
        if (startDateAfter !== undefined && startDateAfter !== null) {
            queryParameters = queryParameters.set('startDate[after]', <any>startDateAfter);
        }
        if (startDateStrictlyAfter !== undefined && startDateStrictlyAfter !== null) {
            queryParameters = queryParameters.set('startDate[strictly_after]', <any>startDateStrictlyAfter);
        }
        if (endDateBefore !== undefined && endDateBefore !== null) {
            queryParameters = queryParameters.set('endDate[before]', <any>endDateBefore);
        }
        if (endDateStrictlyBefore !== undefined && endDateStrictlyBefore !== null) {
            queryParameters = queryParameters.set('endDate[strictly_before]', <any>endDateStrictlyBefore);
        }
        if (endDateAfter !== undefined && endDateAfter !== null) {
            queryParameters = queryParameters.set('endDate[after]', <any>endDateAfter);
        }
        if (endDateStrictlyAfter !== undefined && endDateStrictlyAfter !== null) {
            queryParameters = queryParameters.set('endDate[strictly_after]', <any>endDateStrictlyAfter);
        }
        if (orderStartDate !== undefined && orderStartDate !== null) {
            queryParameters = queryParameters.set('order[startDate]', <any>orderStartDate);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (itemsPerPage !== undefined && itemsPerPage !== null) {
            queryParameters = queryParameters.set('itemsPerPage', <any>itemsPerPage);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ClosedtimeEventsRead>>(`${this.basePath}/api/v1/closedtimes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a Closedtime resource.
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClosedtimeItem(id: string, observe?: 'body', reportProgress?: boolean): Observable<ClosedtimeEventsRead>;
    public getClosedtimeItem(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClosedtimeEventsRead>>;
    public getClosedtimeItem(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClosedtimeEventsRead>>;
    public getClosedtimeItem(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getClosedtimeItem.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/ld+json',
            'application/json',
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ClosedtimeEventsRead>(`${this.basePath}/api/v1/closedtimes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
