import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {ICenter} from '../model/center.model';

const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_USER = 'ROLE_DWBN_MEMBER';

// permissions need to match with the backend permission helper permission
const PERMISSION_VIEW = 'view';
export const PERMISSION_CREATE = 'create';
export const PERMISSION_UPDATE = 'update';
export const PERMISSION_MEMBERS = 'members'; // is a member of the project
export const PERMISSION_ADMINS = 'admins'; // is a admin of the project
export const PERMISSION_FOLLOWERS = 'followers'; // see and invite follower
export const PERMISSION_POST = 'post';
export const PERMISSION_DELETE = 'delete';
const PERMISSION_ALL = 'all';

export const ENTITY_PAGE = 'pages';
export const ENTITY_PROJECT = 'projects';
const ENTITY_CENTER = 'centers';
const ENTITY_REGION = 'regions';
const ENTITY_COUNTRY = 'countries';

export const SCOPE_INTERNATIONAL = 'IN';
export const SCOPE_COUNTRY = 'CY';
export const SCOPE_REGION = 'RE';
export const SCOPE_CENTER = 'CE';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private editable$: BehaviorSubject<boolean>;

  constructor(
    private userProvider: UserService
  ) {
    this.editable$ = new BehaviorSubject(false);
  }

  get editableObservable() {
    return this.editable$.asObservable().pipe(distinctUntilChanged());
  }

  async getPermissions() {
    let permissions = this.userProvider.permissions$.value;

    if (!permissions) {
      await this.userProvider.fetchLoggedInUser();
      permissions = this.userProvider.permissions$.value;
    }

    return permissions;
  }

  async canCreateProject() {
    return await this.isAdmin();
  }

  async hasRightForCenter(center: ICenter, rightName) {
    const checkRight = (right) => right === PERMISSION_ALL || right === rightName;
    const permissions = await this.getPermissions();

    if (permissions) {
      if (
        (permissions[PERMISSION_ALL] && permissions[PERMISSION_ALL].some(checkRight)) ||
        // tslint:disable-next-line:max-line-length
        (permissions[ENTITY_CENTER] && permissions[ENTITY_CENTER][center.slug] && permissions[ENTITY_CENTER][center.slug].some(checkRight)) ||
        // tslint:disable-next-line:max-line-length
        (center.region?.slug && permissions[ENTITY_REGION] && permissions[ENTITY_REGION][center.region.slug] && permissions[ENTITY_REGION][center.region.slug].some(checkRight)) ||
        // tslint:disable-next-line:max-line-length
        (permissions[ENTITY_COUNTRY] && permissions[ENTITY_COUNTRY][center.country.code] && permissions[ENTITY_COUNTRY][center.country.code].some(checkRight))
      ) {
        return true;
      }
    }

    return false;
  }

  async hasEditRightsForPage(pageId) {

    // @todo - make the post right dependend on the existence of a post widget grid

    const editable = await this.hasEntityRight(ENTITY_PAGE, pageId, PERMISSION_UPDATE)
      || await this.hasEntityRight(ENTITY_PAGE, pageId, PERMISSION_POST);
    return this.updateEditable(editable);
  }

  async hasEditRightsForProject(projectId) {
    // @todo - make the post right dependend on the existence of a post widget grid
    const editable = await this.hasEntityRight(ENTITY_PROJECT, projectId, PERMISSION_UPDATE)
      || await this.hasEntityRight(ENTITY_PROJECT, projectId, PERMISSION_POST);
    return this.updateEditable(editable);
  }

  async hasRightsToSeeProjectFollowers(projectId): Promise<boolean> {
    return await this.hasEntityRight(ENTITY_PROJECT, projectId, PERMISSION_FOLLOWERS);
  }

  async hasEntityRight(entityType: string, entityId: string | number, rightName: string) {
    const checkRight = (right: string) => right === PERMISSION_ALL || right === rightName;

    // do we have an admin?
    const permissions = await this.getPermissions();

    const hasPermission = permissions &&
      (
        (permissions[PERMISSION_ALL] && permissions[PERMISSION_ALL].some(checkRight)) ||
        (permissions[entityType] && permissions[entityType][entityId] && permissions[entityType][entityId].some(checkRight))
      );

    return hasPermission;
  }

  async isProjectMember(projectId) {
    const permissions = await this.getPermissions();

    return permissions && permissions[ENTITY_PROJECT] &&
      permissions[ENTITY_PROJECT][projectId] &&
      permissions[ENTITY_PROJECT][projectId].some((permission) => {
        return permission === PERMISSION_ALL || permission === PERMISSION_VIEW;
      });
  }

  async isProjectAdmin(projectId) {
    const permissions = await this.getPermissions();
    return permissions && permissions[ENTITY_PROJECT] &&
      permissions[ENTITY_PROJECT][projectId] &&
      permissions[ENTITY_PROJECT][projectId].some((permission) => {
        return permission === PERMISSION_ALL;
      });
  }

  async isAdmin() {
    const levels = await this.getAdminLevels();
    return levels.length > 0;
  }

  async isValidMember(): Promise<boolean> {
    const user = await this.userProvider.fetchLoggedInUser();
    return this.userProvider.roles$.value.indexOf(ROLE_USER) > -1 && user.enabled && !user.centerAccount && !!user.center;
  }

  async getAdminLevels() {
    const permissions = await this.getPermissions();

    const levels = [];

    if (!permissions) {
      return levels;
    }

    const hasPermissionLevel = (key) => {
      return permissions[key] && Object.keys(permissions[key]).length > 0;
    };

    if (permissions[PERMISSION_ALL].length) {
      return [SCOPE_CENTER, SCOPE_REGION, SCOPE_COUNTRY, SCOPE_INTERNATIONAL];
    }

    if (hasPermissionLevel(ENTITY_CENTER)) {
      levels.push(SCOPE_CENTER)
    }
    if (hasPermissionLevel(ENTITY_REGION)) {
      levels.push(SCOPE_REGION)
    }
    if (hasPermissionLevel(ENTITY_COUNTRY)) {
      levels.push(SCOPE_COUNTRY)
    }
    if (permissions[ENTITY_PROJECT]?.international?.length > 0) {
      levels.push(SCOPE_INTERNATIONAL);
    }

    return levels;
  }

  public updateEditable(editable: boolean) {
    this.editable$.next(editable);
    return editable;
  }


}
