import {Injectable} from '@angular/core';
import {AppEnv, ENVs} from '../../../environments/app.environments';
import {Environment} from '../auth/environment.service';

const identifyEnvironment = (currentUrl: string) : string => {
  const url = new URL(currentUrl);
  let envName;

  ENVs.forEach( (env, key) => {
    // console.log('testing %o against %o - %o', env.ssoWebRedirectUri, url.origin, env.ssoWebRedirectUri.indexOf(url.origin));
    if (env.ssoWebRedirectUri.indexOf(url.origin) === 0) {
      envName = key;
    }
  });

  // our fallback is the live web ;)
  return envName ? envName : 'production_pwa';
};


@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  public currentEnv: AppEnv;

  constructor(
    private environment: Environment<AppEnv>,
  ) {
    // we need to use window.location here, because the angular router is not jet initalized
    const envName = identifyEnvironment(window.location.href);

    console.log('Initiating Environment as %o', envName);

    this.environment.init(ENVs, envName);

    const env = ENVs.get(envName);

    if (!env) {
      throw new Error(`Could not find environment configuration for ${envName}.`)
    }

    this.currentEnv = env;
  }
}
