import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {IMembership} from '../../core/model/membership.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Apollo} from 'apollo-angular';
import {UPDATE_PROJECT_MEMBERSHIP_MUTATION} from '../../core/services/GQL/gql-query.service';
import {AccessService, ENTITY_PROJECT, PERMISSION_ADMINS, PERMISSION_MEMBERS} from '../../core/services/access.service';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-membership-labels',
  templateUrl: './membership-labels.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembershipLabelsComponent implements OnInit {

  @Input() availableLabels: string[] = [];
  @Input() projectId: number;
  @Input() membership: IMembership;

  form: FormGroup;

  suggestions: string[];
  suggestionIndex = -1;

  hasMembersAccess = false;
  hasAdminsAccess = false;

  constructor(
      private formBuilder: FormBuilder,
      private accessService: AccessService,
      private apollo: Apollo,
      private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      label: new FormControl()
    });

    this.accessService.hasEntityRight(ENTITY_PROJECT, this.projectId, PERMISSION_MEMBERS).then((access) => {
      this.hasMembersAccess = access;
      this.ref.markForCheck();
    });
    this.accessService.hasEntityRight(ENTITY_PROJECT, this.projectId, PERMISSION_ADMINS).then((access) => {
      this.hasAdminsAccess = access;
      this.ref.markForCheck();
    });
  }

  async persistMembership () {
    await firstValueFrom(this.apollo.use('app').mutate({
      mutation: UPDATE_PROJECT_MEMBERSHIP_MUTATION,
      variables: {
        input: {
          projectId: this.projectId,
          userId: this.membership.user.id,
          labels: this.membership.labels
        }
      }
    }))
    this.ref.markForCheck();
  }

  async removeLabel (index) {
    this.membership.labels.splice(index, 1);
    await this.persistMembership();
  }

  async addLabel (label) {
    if (label && this.membership.labels.indexOf(label) < 0) {

      this.membership.labels.push(label);
      this.persistMembership();

      if (this.availableLabels.indexOf(label) < 0) {
        this.availableLabels.push(label);
      }
    }

    this.form.patchValue({
      label: ''
    });

    this.suggestions = [];
    this.suggestionIndex = -1;
  }

  canEdit () {
    return this.membership.status === 2 && this.hasAdminsAccess || this.hasMembersAccess;
  }

  onKeyDown (event) {
    const key = event.key;

    switch (key) {
      case 'ArrowUp':
        if (!this.suggestions.length) {
          this.suggestionIndex = -1
        } else {
          this.suggestionIndex = this.suggestionIndex - 1 < 0 ? this.suggestions.length - 1 :  this.suggestionIndex - 1;
        }
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'ArrowDown':
        if (!this.suggestions.length) {
          this.suggestionIndex = -1
        } else {
          this.suggestionIndex = this.suggestionIndex + 1 >= this.suggestions.length ? 0 : this.suggestionIndex + 1;
        }
        event.preventDefault();
        event.stopPropagation();
        break;
    }
  }

  onKeyUp (event) {
    const key = event.key;
    const label = this.form.get('label').value.toString().trim();

    switch (key) {
      case ',':
        this.form.patchValue({
          label: label.replace(',', '')
        });
        break;
      case 'Enter':
        if (this.suggestionIndex > -1 && this.suggestions[this.suggestionIndex]) {
          this.addLabel(this.suggestions[this.suggestionIndex]);
        } else {
          this.addLabel(label);
        }
        break;
      default:
        // preview items in the filter

        if (label) {
          this.suggestions = this.availableLabels.filter( (existingLabel) => {
            return existingLabel.toLocaleLowerCase().indexOf(label.toLocaleLowerCase()) > -1 &&
                this.membership.labels.indexOf(existingLabel) < 0;
          });
        } else {
          // reset
          this.suggestions = [];
          this.suggestionIndex = -1;
        }

        break;
    }
  }

}
