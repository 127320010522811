import { Injectable } from '@angular/core';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {StorageMap} from "@ngx-pwa/local-storage";


export interface SsoEnvironment {
  ssoPath: string;
  ssoNativeClientId: string;
  ssoNativeRedirectUri: string;
  ssoJsClientId: string;
  ssoWebRedirectUri: string;
  endSessionRedirectUrl: string;
  proxyBasePath: string;
}

@Injectable({
  providedIn: 'root'
})
export class Environment<T> {
  private environments: Map<string, SsoEnvironment> = new Map();
  private env$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private storage: StorageMap
  ) {
  }

  get activeEnv$() {
    if (!this.environments) {
      throw new Error('No environments initialized! Did you call init() ?');
    }
    return this.env$.asObservable().pipe(distinctUntilChanged());
  }

  get activeEnv(): SsoEnvironment|undefined {
    if (!this.environments) {
      throw new Error('No environments initialized! Did you call init() ?');
    }
    if (!this.env$.getValue()) {
      throw new Error('No activeEnv$ initialized! Did you call init() ?');
    }
    return this.environments.get(this.env$.getValue());
  }

  get keys() {
    if (!this.environments) {
      throw new Error('No environments initialized! Did you call init() ?');
    }
    return this.environments.keys();
  }

  async init(envs: Map<string, SsoEnvironment>, initialEnv: string) {
    if (!initialEnv) {
      throw new Error('No initial environment given! Did you call init(ENVs, initialEnv) with second parameter?');
    }
    if (!envs.has(initialEnv)) {
      throw new Error('The initial environment given does not exist: ' + initialEnv);
    }
    this.env$ = new BehaviorSubject(initialEnv);
    this.environments = envs;
    const env = await firstValueFrom(this.storage.get('app.environment')) as string;
    if (env && envs.has(initialEnv)) { // env is null after first init!
      await this.setEnv(env);
    }
  }

  async setEnv(env: string) {
    if (env !== this.env$.getValue()) {
      this.env$.next(env);
      await firstValueFrom(this.storage.set('app.environment', env));
    }
    console.log('Current Env:', this.env$.getValue());
  }


  get current() {
    if (!this.env$.getValue()) {
      throw new Error('No activeEnv$ initialized! Did you call init() ?');
    }
    return this.env$.getValue();
  }

}
