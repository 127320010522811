import {IFormDefinition} from '../../model/formDefinition.model';

export default ({
    id: 'currencyCode',
    input: 'select',
    options: [
        {value: 'EUR', label: 'Euro (EUR)'},
        {value: 'USD', label: 'US$ (USD)'},
        {value: 'GBP', label: 'Pounds (GBP)'},
        {value: 'MXN', label: 'Mexican Peso (MXN)'}
    ],
    required: true,
} as IFormDefinition);