<span [formGroup]="form">

  <span *ngFor="let label of membership.labels; let i = index" class="badge bg-gray300 text-dark me-2 py-1 px-2">
    {{label}} <button *ngIf="canEdit()" (click)="removeLabel(i)" title="Remove label '{{label}}'" type="button" class="btn btn-text"><app-icon [icon]="{style: 'fa-solid', name: 'xmark'}"/></button>
  </span>

  <span class="line">
    <input *ngIf="canEdit()"
           formControlName="label"
           class="line__input"
           type="text"
           placeholder="type to add a label"
           (keyup)="onKeyUp($event)"
           (keydown)="onKeyDown($event)"
    />

    <ul class="line__suggestions" [class.show]="suggestions?.length || form.get('label').value?.toString().trim()">
      <li *ngFor="let label of suggestions; let i = index"
          (click)="addLabel(label)"
          class="line__li"
          [class.active]="suggestionIndex === i"
      >{{label}}</li>
      <li *ngIf="form.get('label').value?.toString().trim() && suggestionIndex < 0" class="line__desc"><em><span *ngIf="suggestions?.length">...or</span> press enter to add</em>&nbsp;<strong>{{form.get('label').value?.toString().trim()}}</strong></li>
    </ul>

  </span>

</span>
