<div class="d-flex align-items-start {{ noBlock ? '' : 'block--' + align }}" [class.no-block]="noBlock" [class.text-container]="icon" [class.text-block]="!icon">
  <div *ngIf="icon" class="icon-col text-primary fs-large me-3">
    <app-icon [icon]="icon"></app-icon>
  </div>
  <div class="content flex-grow-1">
    <h2 *ngIf="headline">{{headline}}</h2>
    <!-- ng-content will put all the children -->
    <ng-content></ng-content>
  </div>
</div>
