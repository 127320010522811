import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {MessageService} from './message.service';
import {GET_PROJECT_MEMBERS, GET_PROJECT_MEMBERS_FULL} from './GQL/gql-query.service';
import {GqlService} from './GQL/gql.service';
import {IProject} from '../model/project.model';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {IUser} from "../model/user.model";
import {ModalService} from "../util/ui/modal.service";
import {AddMembersModalComponent} from "../../components/add-members-modal/add-members-modal.component";
import {cloneDeep} from "lodash-es";
import {distinctUntilChanged} from "rxjs/operators";

export const ProjectListFragment = gql`
  fragment ProjectListFragment on Project {
    id
    international
    archived
    name
    slug
    picture {
      src
      aspectRatio
      focal
    }
    scope
    center {
      id
      displayName
      slug
      country {
        id
        code
      }
    }
    region {
      id
      name
    }
    country {
      id
      code
    }
  }
`;

export const ProjectDetailFragment = gql`
${ProjectListFragment}
fragment ProjectDetailFragment on Project {
   ...ProjectListFragment
   contactEmail
   cloudSyncEnabled
   autoMembership
   memberRights
   categoryBundles
}
`;

const GET_PROJECT_LIST_QUERY = gql`
  query projectList {
    my_project_list {
      my_projects {
        ...ProjectListFragment
      }
      my_followed_projects {
        ...ProjectListFragment
      }
      other_projects {
        ...ProjectListFragment
      }
    }
  }
  ${ProjectListFragment}
`;

const CREATE_PROJECT_MUTATION = gql`
  mutation addProject ($input: CreateProjectInput!) {
    addProject (input: $input) {
      ...ProjectDetailFragment
    }
  }
  ${ProjectDetailFragment}
`;

const EDIT_PROJECT_MUTATION = gql`
  mutation updateProject($id: Int!, $input: UpdateProjectInput!) {
    updateProject(id: $id, input: $input) {
      ...ProjectDetailFragment
    }
  }
  ${ProjectDetailFragment}
`;

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projectObservables : Record<string, BehaviorSubject<IProject|null>> = {}

  constructor(
    private apollo: Apollo,
    private messageService: MessageService,
    private gqlService: GqlService,
    private modalService: ModalService,
  ) { }

  updateProjectObservable (project: IProject) {
    const pId = project.id.toString();

    if (this.projectObservables[pId]) {
      this.projectObservables[pId].next(project);
    }
  }

  getProjectObservable (projectId: number) : Observable<IProject|null> {
    const pId = projectId.toString();

    if (!this.projectObservables[pId]) {
      this.projectObservables[pId] = new BehaviorSubject(null);
    }

    return this.projectObservables[pId].asObservable().pipe(distinctUntilChanged());
  }

  presentAddMemberModal (project: IProject) {
    this.modalService.open(
      AddMembersModalComponent,
      {
        project
      },
      {size: 'lg'}
    )
  }

  async getProject (projectId: number) {
    const result : any = await firstValueFrom(this.apollo.use('app').query({
      query: gql`
            query appQuery ($id: Int){
                project(id: $id) {
                    ...ProjectDetailFragment
                }
            }
            ${ProjectDetailFragment}
            `,
      variables: {id: projectId},
    }));

    const project = result?.data?.project as IProject;
    this.updateProjectObservable(project);

    return project;
  }

  async getProjectMembers (projectId, full = false) {
    const fetchPolicy = await this.gqlService.getCurrentFetchPolicy();
    const result : any = await firstValueFrom(this.apollo.use('app').query({
      query: full ? GET_PROJECT_MEMBERS_FULL : GET_PROJECT_MEMBERS,
      variables: {id: projectId},
      fetchPolicy
    }));

    return cloneDeep(result?.data?.project.members) as {user: IUser, labels: string[]}[];
  }

  async projectList () {
    const result = await firstValueFrom(this.apollo
      .use('app')
      .query({
        query: GET_PROJECT_LIST_QUERY
      }));

    return result ? (result.data as any).my_project_list : null;

  }

  async createProject(data: any) {
    const result = await firstValueFrom(this.apollo
      .use('app')
      .mutate({
        mutation: CREATE_PROJECT_MUTATION,
        variables: {input: data}
      })).catch((reason) => {
      this.messageService.error(this.messageService.apolloErrorToMessage(reason));
    });

    return result ? (result.data as any).addProject : null;
  }

  async editProject(projectId: number, data: any) {

    const updateData = {};

    ['name', 'scope', 'contactEmail', 'international', 'picture', 'archived', 'memberRights', 'categoryBundles'].forEach((key) => {
      if (typeof data[key] !== 'undefined') {

        if (key === 'categoryBundles') {
          updateData[key] = (data[key] || []).map((taxonomy) => {
            return taxonomy.bundle;
          });
        } else if (key === 'picture' && updateData[key]) {
          updateData[key] = {
            src: data[key].src,
            aspectRatio: data[key].aspectRatio
          }

          if (data[key].focal) {
            updateData[key].focal = data[key].focal;
          }

        } else {
          updateData[key] = data[key];
        }
      }
    });

    const result = await firstValueFrom(this.apollo
      .use('app')
      .mutate({
        mutation: EDIT_PROJECT_MUTATION,
        variables: {input: updateData, id: projectId}
      })).catch((reason) => {
      this.messageService.error(this.messageService.apolloErrorToMessage(reason));
    });

    const project = result ? (result.data as any).updateProject : null
    this.updateProjectObservable(project);

    return project;
  }
}
