import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslationHelper} from '../../core/util/translation.helper';
import {BrowserService} from '../../core/services/browser.service';
import {ButtonHelper} from "../../core/util/ui/button.helper";

@Component({
  selector: 'app-prominent-link',
  templateUrl: './prominent-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProminentLinkComponent implements OnInit {
  data: any;
  link: string;
  title: string;
  buttonText: string;
  align: string;

  constructor(
      private translationHelper: TranslationHelper,
      private browserService: BrowserService,
      public buttonHelper: ButtonHelper,
  ) {}

  ngOnInit() {

    if (!this.data) {
      this.data = {}
    }

    this.link = this.data?.href;
    this.buttonText = this.translationHelper.getBestLanguageValue(this.data?.buttonText);
    this.align = this.data?.align;
  }

  async navigate(){
    await this.browserService.navigateLink(this.link);
  }
}
