import {IWidgetDefinition} from "../../core/model/widget.model";
import {QuoteComponent} from "./quote.component";

export default {
  getComponent: () => QuoteComponent,
  category: 'content',
  icon: {style: 'fa-solid', name: 'link'},
  fields: [

    {
      id: 'quote',
      label: 'The actual quote',
      input: 'text',
      i18n: true,
      required: true
    },

    {
      id: 'author',
      label: 'The author of the quote',
      input: 'text'
    },

  ]
} as IWidgetDefinition;
