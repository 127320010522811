<div *ngIf="data.icon || headline || description" [class.intro]="description" [class.as-slider]="display === 'slider'">

  <app-follow-button *ngIf="!data.hideFollowButton && !preventUnsubscribe && ['post', 'letter'].indexOf(childType) > -1" class="pull-right" entityType="{{isManualPosts ? parentData.postsOf : 'widget'}}" [entityId]="parentId" entityName="{{headline}}"></app-follow-button>

  <app-icon-box [icon]="data.icon" [headline]="headline" [align]="display === 'slider' ? 'stack': 'left'" [noBlock]="!description && childType !== 'letter'">
    <div *ngIf="description" [innerHTML]="description" class="description"></div>
    <a *ngIf="allItemsRoute && !data.hideLink" class="btn btn-primary" [href]="allItemsRoute" [routerLink]="allItemsRoute">
      {{linkText ? linkText : ('widgets.collection.seeAll' | translate:{type: ('widgets.names.' + childType | translate)}) }}
      <i class="fa-solid fa-chevron-right"></i>
    </a>

    <!-- @todo: this should not be hardcoded, but configurable and generic -->
    <div *ngIf="childType === 'letter' && data.allowCreate" class="filter">
      <select (change)="filterChange('date', $event)" class="filter__input" #yearInput>
        <option value="">by year</option>
        <option *ngFor="let i of getLoop(getCurrentYear() - 1996); let y = index" value="{{ getCurrentYear() - y }}">{{ getCurrentYear() - y }}</option>
      </select>
      <select (change)="filterChange('type', $event)" class="filter__input" #typeInput>
        <option value="">by type</option>
        <option *ngFor="let type of letterTypesFilter" value="{{type.value}}">{{type.label | translate}}</option>
      </select>

      <button *ngIf="isFiltered" (click)="clearFilters()" type="button" class="btn btn-outline-dark">clear filters</button>

      <div *ngIf="filtering" class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

  </app-icon-box>

</div>

<em *ngIf="!id; else hasId">Please save the page, before you can add a new {{'widgets.names.' + childType | translate}}.</em>

<ng-template #hasId>
  <div *ngIf="loading; else widgetListBlock" class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>

<ng-template #widgetListBlock>

  <div *ngIf="editable && updateAllowed && data.exportable" class="d-flex justify-content-end mb-2">
    <!-- https://gitlab.dwbn.org/dwbn-it/intranet/dw-connect2/dwconnect2-app/-/issues/512 Export functionality -->
    <button (click)="exportData()" type="button" class="btn btn-outline-dark">
      <i *ngIf="!exporting" class="fa-solid fa-download me-2"></i>
      <span *ngIf="exporting" class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
      Export
    </button>
  </div>

  <div class="d-flex mb-2">

    <button *ngIf="editMode && updateAllowed && data.allowCreate"
            type="button"
            class="btn btn-primary"
            style="flex-grow: 1;"
            (click)="createEntity()"
    >
      <i class="fa-solid fa-circle-plus me-2"></i>
      {{'widgets.collection.add' | translate:{type: ( ('widgets.names.' + childType) | translate)} }}
    </button>

    <button *ngIf="editMode && allowsBulkCreation()"
            type="button"
            class="btn btn-dark ms-3"
            (click)="bulkCreateEntities()"
    >
      <i class="fa-solid fa-cloud-arrow-up me-2"></i>
      Bulk Upload
    </button>
  </div>


  <div *ngIf="!filtering">
    <div *ngFor="let section of sections; let s = index">
      <h2 *ngIf="section.widgetType !== 'orgboard' && section.headline" class="h3 section-header">
        {{section.headline}}<span class="text-medium"> {{section.widgets.length}}</span>
      </h2>

      <div *ngIf="isFiltered && !section.widgets.length" class="filter__no-results">
        We could not find any entries for your filter. Please choose a different selection.
      </div>

      <ng-container [ngSwitch]="display">

        <div *ngSwitchDefault class="row">
          <div *ngFor="let widget of section.widgets; let i = index"
               class="{{getColSizeClasses(displaySizes) + ' mb-4'}}"
               [class.edit]="editMode && updateAllowed"
               [class.position-relative]="editAllowed(widget)"
          >
            <ng-container *ngTemplateOutlet="editButtons;context:{widget, i, display}"></ng-container>
            <app-generic-widget [widget]="widget" [widgetList]="widgets" [colSizes]="innerColSizes" display="{{widgetDisplay}}"></app-generic-widget>
          </div>
        </div>

        <div *ngSwitchCase="'accordion'" ngbAccordion [closeOthers]="true">
          <div *ngFor="let widget of section.widgets; let i = index" ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <app-generic-widget [widget]="widget" [widgetList]="widgets" [colSizes]="innerColSizes" display="accordeon-title"></app-generic-widget>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="position-relative">
                <ng-container *ngTemplateOutlet="editButtons;context:{widget, i, display}"></ng-container>
                <app-generic-widget [widget]="widget" [widgetList]="widgets" [colSizes]="innerColSizes" display="{{widgetDisplay}}"></app-generic-widget>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'slider'" class="collection-slider position-relative">
          <swiper-container appSwiper #slides [config]="sliderOptions" (slidechange)="updateSlideUi($event);">
            <swiper-slide *ngFor="let widget of section.widgets; let i = index" class="position-relative" style="max-width: {{data.slideWidth || 200}}px;">
              <ng-container *ngTemplateOutlet="editButtons;context:{widget, i, display}"></ng-container>
              <app-generic-widget [widget]="widget" [widgetList]="widgets" [colSizes]="innerColSizes" display="slide"></app-generic-widget>
            </swiper-slide>
          </swiper-container>

          <button *ngIf="!disablePrevBtn" type="button" class="btn btn-lg btn-dark text-white position-absolute top-50 start-0 translate-middle-y rounded-circle z-3 inspiration__button" (click)="prevSlide()">
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <button *ngIf="!disableNextBtn" type="button" class="btn btn-lg btn-dark text-white position-absolute top-50 end-0 translate-middle-y rounded-circle z-3 inspiration__button" (click)="nextSlide()">
            <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>


      </ng-container>
    </div>
  </div>

  <div *ngIf="lazyLoading && !filtering" class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

</ng-template>

<ng-template #editButtons
             let-widget="widget"
             let-i="i"
             let-display="display"
>

  <div *ngIf="editAllowed(widget)" class="edit-buttons edit-buttons--{{display}} text-end">

    <button type="button" class="btn btn-success me-2" (click)="editEntity(widget, i)">
      <i class="fa-solid fa-pen"></i>
    </button>
    <button type="button" class="btn btn-danger" (click)="removeEntity(widget, i)">
      <i class="fa-solid fa-trash"></i>
    </button>
  </div>


</ng-template>
