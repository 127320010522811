import gql from 'graphql-tag';

export const CenterFragment = gql`
  fragment CenterFragment on Center {
    id
    displayName
    name
    slug
    type
    homepage
    latitude
    longitude
    neigbourDistance
    transregionalDistance
    email
    active
    private
    region {
      id
      slug
      name
    }
    country {
      id
      code
      name
    }
    phoneNumbers {
      phone
      phone_type
    }
    twitter
    facebook
    pictures {
      url
    }
    mainAddress {
      city
      street_address
      postal_code
      country
    }
  }`;
