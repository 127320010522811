import {IWidgetDefinition} from '../../core/model/widget.model';
import iconSelect from '../../core/util/forms/icons.select';

export default ({
    icon: { style: 'fa-solid', name: 'user-group' },
    category: 'special',

    fields: [
        iconSelect,
        {
            id: 'headline',
            input: 'text',
            i18n: true,
            width: 8
        },
        {
            id: 'description',
            label: 'Description',
            input: 'html',
            required: false,
            i18n: true
        },
        {
            id: 'members',
            input: 'member_search',
            required: false // in order to allow also only additional members, this is not required
        },
        {
            id: 'additionalMembers',
            label: 'Additional Members',
            input: 'complex-list',
            description: 'Please try first to inspire people to login to dw-connect :). Manual Members will have no profile link and also not be synchronized.',
            subtype: [
                {
                    id: 'givenName',
                    input: 'text',
                    required: true
                },
                {
                    id: 'familyName',
                    input: 'text',
                    required: false
                },
                {
                    id: 'image',
                    input: 'image_upload',
                    required: false
                }
            ]
        }
    ]
} as IWidgetDefinition);
