import {Component, HostListener, OnInit} from '@angular/core';
import {availableLanguages, defaultLanguage} from "./core/util/translation.constants";
import {TranslateService} from "@ngx-translate/core";
import {StorageMap} from "@ngx-pwa/local-storage";
import {firstValueFrom} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {RoutingHelper} from "./core/util/routing.helper";
import {StructureService} from "./core/services/cms/structure.service";
import {GqlService} from "./core/services/GQL/gql.service";
import {UserService} from "./core/services/user.service";
import {IntercomService} from "./core/services/intercom.service";
import {ModalService} from "./core/util/ui/modal.service";
import {WelcomeOverlayComponent} from "./components/welcome-overlay/welcome-overlay.component";
import {IUser} from "./core/model/user.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  initializeEditButton = false;

  constructor(
    private translator: TranslateService,
    private storage: StorageMap,
    private router: Router,
    private routingHelper: RoutingHelper,
    private userProvider: UserService,
    private structureService: StructureService,
    private gqlService: GqlService,
    private intercomService: IntercomService,
    private modalService: ModalService,
  ) {
  }

  ngOnInit(): void {

    // this is comes after the authguard was processed
    this.userProvider.fetchLoggedInUser().then((user) => {
      this.initializeEditButton = true

      if (!user.hasLoggedIn) {
        this.showWelcomeOverlay(user);
      }
    });

    this.initTranslator().then(() => {
      // this.splashScreen.hide();
    });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe( (event) => {

      const pageRoute = this.routingHelper.getCurrentPageRoute()

      if (this.structureService.getCurrentPage() && this.structureService.getCurrentPage().getRoute() !== pageRoute) {
        // fresh and new on every actually new page
        this.structureService.reset();
      }
      this.gqlService.setCurrentFetchPolicy(null);

      if (pageRoute) {
        this.structureService.revalidateEditModeByRoute(pageRoute);
      }
    });

  }

  // @todo - this should rely first of all on the user language
  private async initTranslator() {
    this.translator.setDefaultLang(defaultLanguage);

    let language: string;

    // https://gitlab.dwbn.org/dwbn-it/intranet/dw-connect2/dwconnect2-app/-/issues/533
    // to prevent weired issues resulting in undefined current language
    try {
      language = await firstValueFrom(this.storage.get('language')) as string;

      if (!language) {
        language = this.translator.getBrowserLang() || defaultLanguage;
      }

      let languageAvailable = false;
      availableLanguages.forEach((lang) => {
        if (lang.code === language) {
          languageAvailable = true;
        }
      });

      language = languageAvailable ? language : defaultLanguage;

      await firstValueFrom(this.storage.set('language', language));
    } catch (e) {
      language = defaultLanguage;
    }
    this.translator.use(language);

    console.log('current interface language: %o', language);
  }


  showWelcomeOverlay (user: IUser) {
    this.modalService.open(WelcomeOverlayComponent, {
      user
    }, {keyboard: false, backdrop: 'static'});
  }


  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event: any) {
    this.intercomService.scrollEvents.next(event);
  }

}
