import { HttpClient, HttpClientModule } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GraphQLModule } from './modules/graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import { MatomoModule } from "ngx-matomo-client";
import { AppComponentsModule } from "./modules/app-components.module";

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { DeviceDetectorModule } from 'ngx-device-detector'; - not sure what this is for anymore

import { ApiModule as EventApiModule, Configuration as EventApiConfiguration } from './api/eventapi';
import { ApiModule as MyrecordingsApiModule, Configuration as MyrecordingsApiConfiguration } from './api/myrecordings';


import { registerLocaleData } from '@angular/common';

import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';
import {DefaultRouteReuseStrategy} from "./core/util/default-route-reuse-strategy";
import {RouteReuseStrategy} from "@angular/router";
import {TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";
import {EnvironmentService} from "./core/services/environment.service";

// swiper register
import { register } from 'swiper/element/bundle';

register();

registerLocaleData(localeBg);
registerLocaleData(localeCs);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localePl);
registerLocaleData(localeRu);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppComponentsModule,
    EventApiModule,
    MyrecordingsApiModule,
    HttpClientModule,
    NgbModule,
    NgbToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatomoModule.forRoot({
      siteId: '6', // your Matomo's site ID (find it in your Matomo's settings)
      trackerUrl: 'https://stats.dwbn.org/', // your matomo server root url
    }),
    GraphQLModule,
    // DeviceDetectorModule.forRoot(),
  ],
  providers: [
    // for the usage of translations
    TranslatePipe,
    // https://stackoverflow.com/a/62557825/2776727 - this route reuse strategy is forcing angular to reinit a component on every route change
    {
      provide: RouteReuseStrategy,
      useClass: DefaultRouteReuseStrategy,
    },
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    {
      provide: EventApiConfiguration,
      useFactory: (env: EnvironmentService) => new EventApiConfiguration(
        {
          basePath: env.currentEnv.eventApiBasePath,
        }
      ),
      deps: [EnvironmentService],
      multi: false
    },
    {
      provide: MyrecordingsApiConfiguration,
      useFactory: (env: EnvironmentService) => new MyrecordingsApiConfiguration(
        {
          basePath: env.currentEnv.myrecordingsApiBasePath,
        }
      ),
      deps: [EnvironmentService],
      multi: false
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
