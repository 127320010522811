import iconSelect from '../../core/util/forms/icons.select';
import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    icon: {style: 'fa-solid', name: 'table-list'},
    fields: [
        iconSelect,
        {
            id: 'headline',
            input: 'text',
            i18n: true,
            width: 8
        },
        {
            id: 'content',
            label: 'Description',
            input: 'html',
            required: false,
            i18n: true
        },
        {
            id: 'defaultSort',
            label: 'Default Sort',
            input: 'select',
            options: [
                {value: 'name', label: 'Name'},
                {value: 'center', label: 'Center'},
                {value: 'country', label: 'Country'},
            ],
            width: 3,
            default: 'name'
        },
        {
            id: 'showTags',
            label: 'Show member tags next to name',
            input: 'boolean'
        },
        {
            id: 'excludeMembers',
            label: 'Excluded Members',
            description: 'Listed members will never appear in the list. Can be used to exclude members, that are part of the group for rather technical reasons.',
            input: 'member_search'
        },
        {
            id: 'filterByTag',
            label: 'Only show certain tags',
            input: 'text',
            description: 'If nothing is added, all members are shown. Separate multiple tags with ,'
        }
    ]
} as IWidgetDefinition)
