import {Component, Input} from '@angular/core';
import {IColSizes} from "../../core/model/colSizes.model";
import {RoutingHelper} from "../../core/util/routing.helper";
import {IProject} from "../../core/model/project.model";

@Component({
  selector: 'app-project-tile',
  templateUrl: './project-tile.component.html',
})
export class ProjectTileComponent {

  @Input() project: IProject
  @Input() colSizes: IColSizes

  constructor (
    public routingHelper: RoutingHelper
  ) {

  }

}
