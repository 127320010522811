import {sampleTime, Subject} from 'rxjs';
import { Injectable } from '@angular/core';
import {debounceTime} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
  })
export class IntercomService {
    public subject = new Subject<any>();

    public scrollEvents = new Subject<any>();

    fireScrolledToBottomEvents = true;

    constructor() {
      this.scrollEvents.pipe(sampleTime(400)).subscribe((event) => {
        if (this.fireScrolledToBottomEvents) {

          if (event.target.scrollingElement.scrollTop > event.target.documentElement.scrollHeight - event.target.documentElement.clientHeight * 2.5) {
            this.sendMessage('scrolled-to-the-end');
          }
        }
      })
    }

    sendMessage(message: string) {
      switch (message) {
        case 'scrolled-to-the-end':
          this.fireScrolledToBottomEvents = false;
          break;
        case 'resume-firing-scrolled-to-bottom-events':
          this.fireScrolledToBottomEvents = true;
          break;
      }

      this.subject.next(message);
    }
}
