import { Injectable } from '@angular/core';
import {IAppIcon} from "../../model/app-icon.model";


const legacyIcons = {
  '/assets/icons/dwbn/alert.svg': {label: 'Alert', dwIcon: 'alert'},
  '/assets/icons/dwbn/bell.svg': {label: 'Bell', dwIcon: 'bell'},
  '/assets/icons/dwbn/calendar-2.svg': {label: 'Calendar', dwIcon: 'calendar-month'},
  '/assets/icons/dwbn/calendar.svg': {label: 'Calendar Date', dwIcon: 'calendar'},
  '/assets/icons/dwbn/coffee.svg': {label: 'Coffee', dwIcon: 'coffee'},
  '/assets/icons/dwbn/mac.svg': {label: 'Computer', dwIcon: 'mac'},
  '/assets/icons/dwbn/diamond-2.svg': {label: 'Diamond', dwIcon: 'diamond'},
  '/assets/icons/dwbn/group-friends.svg': {label: 'Friends', dwIcon: 'friends'},
  '/assets/icons/dwbn/mudra.svg': {label: 'Hand', dwIcon: 'hand'},
  '/assets/icons/dwbn/inspiration.svg': {label: 'Inspiration', dwIcon: 'inspiration'},
  '/assets/icons/dwbn/letter.svg': {label: 'Letter', dwIcon: 'letter'},
  '/assets/icons/dwbn/location.svg': {label: 'Location', dwIcon: 'location'},
  '/assets/icons/dwbn/mala.svg': {label: 'Mala', dwIcon: 'mala'},
  '/assets/icons/dwbn/mandala.svg': {label: 'Mandala', dwIcon: 'mandala'},
  '/assets/icons/dwbn/meditation.svg': {label: 'Meditation', dwIcon: 'meditation'},
  '/assets/icons/dwbn/phone-ringing.svg': {label: 'Mobile Phone', dwIcon: 'phone'},
  '/assets/icons/dwbn/news.svg': {label: 'News', dwIcon: 'news'},
  '/assets/icons/dwbn/phone.svg': {label: 'Phone', dwIcon: 'phone1'},
  '/assets/icons/dwbn/project.svg': {label: 'Project', dwIcon: 'project'},
  '/assets/icons/dwbn/tools.svg': {label: 'Tools', dwIcon: 'tools'},
  '/assets/icons/dwbn/notification.svg': {label: 'Trumpet', dwIcon: 'notification'}
}

export const dwIcons = {
  'alert': {label: 'Alert', tags: 'bell'},
  'bell': {label: 'Bell', tags: 'tantra'},
  'calendar-month': {label: 'Calendar Month', tags: ''},
  'calendar': {label: 'Calendar', tags: ''},
  'ec': {label: 'EC', tags: ''},
  'center': {label: 'Center', tags: ''},
  'coffee': {label: 'Coffee', tags: 'cup, hot'},
  'diamond': {label: 'Diamond', tags: ''},
  'dorje': {label: 'Dorje', tags: 'tantra'},
  'facebook': {label: 'Facebook', tags: ''},
  'twitter': {label: 'Twitter', tags: ''},
  'play': {label: 'Video', tags: 'play, youtube'},
  'location': {label: 'Location', tags: 'pin'},
  'friends': {label: 'Friends', tags: ''},
  'inspiration': {label: 'Inspiration', tags: ''},
  'letter': {label: 'Letter', tags: ''},
  'mac': {label: 'Computer', tags: 'mac, screen'},
  'mala': {label: 'Mala', tags: ''},
  'mandala': {label: 'Mandala', tags: ''},
  'meditation': {label: 'Meditation', tags: ''},
  'hand': {label: 'Hand', tags: 'mudra, tantra'},
  'news': {label: 'Newspaper', tags: ''},
  'notification': {label: 'Trumpet', tags: ''},
  'phone': {label: 'Mobile Phone', tags: ''},
  'phone1': {label: 'Phone', tags: ''},
  'image': {label: 'Image', tags: 'picture, photo'},
  'profile-photo': {label: 'Photo Person', tags: 'image, picture, photo'},
  'project': {label: 'Project', tags: 'drawing'},
  'tools': {label: 'Tools', tags: ''},
  'wheelbarrow': {label: 'Wheelbarrow', tags: 'tools'},
  'woman': {label: 'Woman', tags: ''},
  'man': {label: 'Man', tags: ''},
  'welcome': {label: 'Welcome', tags: 'joy, greeting'},
}


@Injectable({
  providedIn: 'root'
})
export class IconHelper {

  constructor() { }

  assureIcon (potentialLegacyIcon: string|IAppIcon) : IAppIcon {

    if (!potentialLegacyIcon) {
      return null;
    }

    if (typeof potentialLegacyIcon === 'string') {
      if (legacyIcons[potentialLegacyIcon]) {
        return {
          style: 'dw',
          name: legacyIcons[potentialLegacyIcon].dwIcon
        };
      }

      return null;
    }

    return potentialLegacyIcon as IAppIcon;
  }

  getIconClass (iconDef: IAppIcon) {
    if (iconDef) {
      switch (iconDef.style) {
        case "dw":
          return 'dw-icon-' + iconDef.name;
        case 'fa-regular':
        case 'fa-solid':
          return iconDef.style + ' fa-' + iconDef.name;
      }
    }
    return '';
  }
}
