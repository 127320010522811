import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import { IWidget } from 'src/app/core/model/widget.model';
import {ImageProxy} from '../../core/util/image-proxy';
import {TranslationHelper} from "../../core/util/translation.helper";
import {RoutingHelper} from "../../core/util/routing.helper";
import {SwiperContainer} from "swiper/element";
import {ModalService} from "../../core/util/ui/modal.service";
import {fromEvent, Subscription} from "rxjs";

@Component({
  selector: 'app-inspiration',
  templateUrl: './inspiration.component.html',
})
export class InspirationComponent implements OnInit, OnDestroy, DWCWidgetComponent {

  @ViewChild('slides', {static: false}) slides: ElementRef<SwiperContainer>;

  id: number;
  colSizes: object;
  data: any;
  type: string;
  display: string;
  widgetList: IWidget[];
  clickedWidgetId: number;

  disablePrevBtn = true;
  disableNextBtn = false;

  slideObjects = [];

  currentSlide = 0;

  sliderOptions = {
    slidesPerView: 1,
    initialSlide: 0,
  };

  title = '';

  keyListener: Subscription = null;

  constructor(
      public modalService: ModalService,
      public translationHelper: TranslationHelper,
      public imageProxy: ImageProxy,
      public routingHelper: RoutingHelper,
  ) {
  }

  ngOnInit() {
    if (this.display === 'modal') {
      this.keyListener = fromEvent(document, 'keyup').subscribe((e: Event) => {

        switch ((e as KeyboardEvent).code) {
          case 'ArrowRight':
            this.nextSlide();
            break;
          case 'ArrowLeft':
            this.prevSlide();
            break;
        }
      })


      const sourceWidgetId = this.clickedWidgetId;

      this.widgetList.forEach((widget) => {
        if (widget.data.images.length) {
          widget.data.images.forEach(img => {

            const hasHeadline = this.translationHelper.getBestLanguageValue(img.title) &&
                this.translationHelper.getBestLanguageValue(img.title) !==
                this.translationHelper.getBestLanguageValue(widget.data.title);

            this.slideObjects.push({
              image: img,
              data: {
                title: img.title || widget.data.title,
                headline: hasHeadline ? widget.data.title : null,
                description: widget.data.description || widget.data.content,
                location: widget.data.location
              }
            })
          })
        }
      });

      this.currentSlide = this.getInitialSliderIndex(this.widgetList, sourceWidgetId);
      this.sliderOptions.initialSlide = this.currentSlide;

      this.updateSlideUi(this.currentSlide);
    }

    this.title = this.translationHelper.getBestLanguageValue(this.data.title);
  }

  ngOnDestroy(): void {
    if (this.keyListener) {
      this.keyListener.unsubscribe();
    }
  }

  getInitialSliderIndex(widgets, sourceWidgetId){
    let slideIndex = 0;
    if (widgets) {
      widgets.findIndex((widget) => {
        if (widget.id === sourceWidgetId) {
          return true;
        } else {
          slideIndex += widget.data.images.length;
        }

        return false;
      });
    }

    return slideIndex;
  }

  async openModal (currentWidgetId) {

    if (['preview', 'slide'].indexOf(this.display) > -1) {

      this.modalService.open(InspirationComponent, {
        data: this.data,
        display: 'modal',
        widgetList: this.widgetList,
        id: this.id,
        type: this.type,
        clickedWidgetId: currentWidgetId
      }, {
        size: "xl",
        modalDialogClass: 'modal-dialog--inspiration'
      });
    }
  }

  nextSlide() {
    this.slides.nativeElement.swiper.slideNext();
  }

  prevSlide() {
    this.slides.nativeElement.swiper.slidePrev();
  }

  updateSlideUi(index){
    this.currentSlide = index;
    this.data = '';
    this.data = this.slideObjects[index].data;

    this.disablePrevBtn = this.currentSlide === 0;
    this.disableNextBtn = this.currentSlide === this.slideObjects.length - 1;
  }

}
