import {Injectable} from '@angular/core';
// import {TooltipComponent} from '../../components/tooltip/tooltip.component';

interface IToast {
  text: string;
  delay?: number;
  severity?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public toasts: IToast[] = [];

  constructor(

  ) { }

  async error (message: string) {
    this.toasts.push({ text: message, delay: 30000, severity: 'danger' });
    console.log('Message: ' + message);
  }

  async success (message: string) {
    this.toasts.push({ text: message, delay: 10000, severity: 'success' });
    console.log('Message: ' + message);
  }

  remove (toast: IToast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  apolloErrorToMessage (reason: {message: string, debugMessage?: string}) {
    return reason.message + (reason.debugMessage ? ` (${reason.debugMessage})` : '' );
  }

}
