import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {MemberPublicInfoFragment} from './GQL/gql-query.service';
import {IUser} from '../model/user.model';
import {firstValueFrom} from "rxjs";


class PotentialMultiQuery {
    constructor(
        private dependsOnQueries: number[],
        public resolve,
        public reject
    ) { }

    resolveIfPossible (finishedQuery: number) {
        if (this.canResolve(finishedQuery)) {
            this.resolve();
            return true;
        }

        return false;
    }

    canResolve (finishedQuery: number) {
        const dependIndex = this.dependsOnQueries.indexOf(finishedQuery);
        if (dependIndex > -1) {
            this.dependsOnQueries.splice(dependIndex, 1);
        }

        return this.dependsOnQueries.length === 0;
    }
}

export const GET_FOLLOWERS = gql`
query appQuery($entityId: String, $entityType: String, $limit: Int, $offset: Int){
  followers(entityId: $entityId, entityType: $entityType, limit: $limit, offset: $offset, dir: "DESC"){
    count
    members {
      id
      username
      givenName
      familyName
      userData{
        picture{
          url
        }
      }
    }
  }
}`;


@Injectable({
    providedIn: 'root'
})
export class MemberService {

    private membersToQuery = {};

    // @todo, this might become too big?
    private publicMemberCache = {};
    private timeout;
    private callbacks : PotentialMultiQuery[] = [];

    private nextCallId = 1;

    constructor(
        private apollo: Apollo,
    ) { }


    /**
     * The call and callback organization logic for the actual call
     */
    private doMembersCall () {
        const currentCallId = this.nextCallId;
        this.nextCallId++;
        const memberIds = [];

        Object.keys(this.membersToQuery).forEach( id => {
            if (this.membersToQuery[id] === currentCallId) {
                memberIds.push(parseInt(id, 10));
            }
        });

        const updateOrResolveAllCallbacks = () => {
            // run all the callbacks
            this.callbacks = this.callbacks.filter( (pmq) => {
                return !pmq.resolveIfPossible(currentCallId);
            })
        };

        if (memberIds.length) {
            this.apollo
                .use('app')
                .query({
                    query: gql`{
                      members_list (ids: ${JSON.stringify(memberIds)}) {
                         ...MemberPublicInfoFragment
                      }
                    }
                    ${MemberPublicInfoFragment}
                    `
                }).toPromise()
                .then( result => {
                    // fill the cache
                    const members = (result as any)?.data.members_list;

                    members.forEach( (member: IUser) => {
                        this.publicMemberCache[member.id] = member;
                    });

                    // clean the queryCache
                    memberIds.forEach( id => {
                        delete this.membersToQuery[id];
                        if (!this.publicMemberCache.hasOwnProperty(id)) {
                            this.publicMemberCache[id] = false;
                        }
                    });

                    updateOrResolveAllCallbacks();
                });
        } else {
            updateOrResolveAllCallbacks();
        }
    }

    /**
     * only runs the actual call, 20 ms after the last request for member data was done
     */
    private debouncedMembersCall (idsToCall: number[]) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        // we check, if some of the ids are already calling
        const dependsOn = [];
        idsToCall.forEach( (id) => {
            if (dependsOn.indexOf(this.membersToQuery[id]) === -1) {
                dependsOn.push(this.membersToQuery[id]);
            }
        });

        return new Promise((resolve, reject) => {
            if (dependsOn.length) {
                this.callbacks.push(new PotentialMultiQuery(dependsOn, resolve, reject));
            } else {
                resolve(null);
            }

            this.timeout = setTimeout(this.doMembersCall.bind(this), 20);
        })
    }


    /**
     * This function has a build in debounce, so that multiple requests can be handled in a efficient manner
     */
    async getMembers (memberIds: number[]) {
        if (memberIds?.length) {
            const idsToCall = [];

            memberIds.forEach((id) => {
                if (!this.publicMemberCache.hasOwnProperty(id)) {
                    idsToCall.push(id);

                    if (!this.membersToQuery[id]) {
                        // we mark the call, in which this member is beeing pulled
                        this.membersToQuery[id] = this.nextCallId;
                    }
                }
            });

            if (idsToCall.length) {
                await this.debouncedMembersCall(idsToCall);
            }

            // now we have them all
            return memberIds.map( id => {
                return this.publicMemberCache[id];
            }).filter( member => {
                return !!member
            });
        }

        return [];
    }

    async getFollowers (entityId, entityType, limit = 108, offset = 0) {
        const result : any = await firstValueFrom(this.apollo.use('app').query({
            query: GET_FOLLOWERS,
            variables: {entityId, entityType, limit, offset}
        }));

        return result?.data?.followers;
    }

}
