import { Component, OnInit } from '@angular/core';
import { DWCWidgetComponent } from '../../core/model/dwc-widget.model';
import { TranslationHelper } from '../../core/util/translation.helper';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit, DWCWidgetComponent {
  colSizes: object;
  data: any;
  id: number;
  type: string;

  routerLinkActiveExact = {};

  constructor(public translationHelper: TranslationHelper) {}

  ngOnInit() {
    // make sure that sub pages are highlighted in a good way
    this.data.entries.forEach((entry) => {
      this.routerLinkActiveExact[entry.route] = !!this.data.entries.some(
        (otherEntry) => {
          return (
            otherEntry.route !== entry.route &&
            otherEntry.route.indexOf(entry.route) === 0
          );
        }
      );
    });
  }
}
