import {Injectable} from "@angular/core";
import {getWidgetFields} from "./dwc-widget-component-map";

@Injectable({
  providedIn: 'root'
})
export class DataHelper {

  assureFieldDefinitionData(dwcWidgetType: string, potentiallyCorruptData: Record<string, any>) {
    const fields = getWidgetFields(dwcWidgetType);
    const cleanData = {};

    console.log(potentiallyCorruptData);

    fields.forEach(fieldDef => {
      const fieldName = fieldDef.id;
      if (potentiallyCorruptData[fieldName] !== undefined && potentiallyCorruptData[fieldName] !== null) {
        // @todo: it might be worth a shot to check also if the value matches the fieldtype
        cleanData[fieldName] = potentiallyCorruptData[fieldName];
      }
    })

    return cleanData;
  }
}
