import { VideoWidgetComponent } from './video.component';
import { IWidgetDefinition } from '../../core/model/widget.model';

const ifIsExternal = (fieldValues) => {
  return fieldValues.type === 'external';
};

const ifIsJwPlayer = (fieldValues) => {
  return fieldValues.type === 'dwbn_jwplayer';
};

export default {
  getComponent: () => VideoWidgetComponent,
  icon: { name: 'circle-play', style: 'fa-solid' },
  category: 'content',
  fields: [
    {
      id: 'type',
      required: true,
      input: 'select',
      label: 'Video Source Type',
      default: 'external',
      options: [
        { label: 'External (like YouTube or Videmo)', value: 'external' },
        { label: 'DWBN JW Player', value: 'dwbn_jwplayer' },
      ],
    },
    {
      id: 'src',
      depends: ifIsExternal,
      label: 'Youtube or Vimeo video share link',
      input: 'text',
      description:
        'Please use the full link starting with <strong>https://</strong>.',
      required: true,
    },
    {
      id: 'm3u8',
      depends: ifIsJwPlayer,
      label: 'The actual m3u8 or cloud.dwbn.org link used by JwPlayer',
      input: 'text',
      description:
        'Please use the full link starting with <strong>https://</strong>.',
      required: true,
    },
    {
      id: 'cover',
      depends: ifIsJwPlayer,
      label: 'Cover Picture',
      input: 'image_upload',
      required: false,
    },
    {
      id: 'aspectRatio',
      label: 'Aspect Ration of the Video (Height divided by Width)',
      input: 'number',
      required: true,
      default: 0.56,
    },
    {
      id: 'maxWidth',
      input: 'text',
    },
    {
      id: 'description',
      input: 'html',
      i18n: true,
      required: false,
    },
  ],
} as IWidgetDefinition;
