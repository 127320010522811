import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IAppImage} from "../../core/model/app-image.model";
import {IColSizes} from "../../core/model/colSizes.model";

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent {

  @Input() title: string;
  @Input() route: string;
  @Input() picture: IAppImage
  @Input() blackAndWhite = false;
  @Input() colSizes: IColSizes = {xs: 12, sm: 6, md: 4, xl: 3};
  @Input() aspectRatio = .6;

}
