import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {MenuService} from './menu.service';
import gql from 'graphql-tag';
import {CREATE_PAGE_MUTATION, DELETE_PAGE_MUTATION, PageFragment, UPDATE_PAGE_MUTATION} from '../fragments/page.fragments';
import {MessageService} from '../message.service';
import {StructureService} from './structure.service';
import {ProjectDetailFragment} from '../project.service';
import {GqlService} from '../GQL/gql.service';
import {IPermissionContext} from '../../model/genericPage.model';
import {firstValueFrom} from "rxjs";
import {cloneDeep} from "lodash-es";
import {ICategory} from "../../model/category.mode";

const PAGE_BY_ROUTE = gql`
  query pageQuery ($route: String!){
    page (route: $route) {
      ...PageFragment
      project {
        ...ProjectDetailFragment
      }
    }
  }
  ${PageFragment}
  ${ProjectDetailFragment}
`;

const PAGE_BY_ID = gql`
query pageQuery ($pageId: Int!){
  page (id: $pageId) {
    ...PageFragment
    project {
      ...ProjectDetailFragment
    }
  }
}
${PageFragment}
${ProjectDetailFragment}
`;

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
      private apollo: Apollo,
      private menuService: MenuService,
      private messageService: MessageService,
      private structureService: StructureService,
      private gqlService: GqlService
  ) { }

  async getPageById (pageId:number) {
    const fetchPolicy = await this.gqlService.getCurrentFetchPolicy()
    const result = await firstValueFrom(this.apollo
        .use('app')
        .query({
          query: PAGE_BY_ID,
          variables: {pageId},
          fetchPolicy
        }));

    return result ? (result.data as any).page : null;
  }


  async getPageByRoute (route: string) {
    const result = await firstValueFrom(this.apollo
        .use('app')
        .query({
          query: PAGE_BY_ROUTE,
          variables: {route}
        }));

    return result ? cloneDeep((result.data as any).page) : null;
  }

  async getProjectPageByRoute (route: string) {
    const fetchPolicy = await this.gqlService.getCurrentFetchPolicy();
    const result = await firstValueFrom(this.apollo
        .use('app')
        .query({
          query: PAGE_BY_ROUTE,
          variables: {route},
          fetchPolicy
        }));


    const page = result ? (result.data as any).page : null;
    this.structureService.setCurrentPage(page);

    return page;
  }

  async getPageIdFromPath (path: string) {

    let pageId = null;
    const parts = path.split('/');
    const menu = await this.menuService.getMenu({ident: 'news'});

    if (menu) {
      const recursiveComparePath = (items:[]) => {
        const pathPart = parts.shift();
        for (const item of items) {

          // @ts-ignore
          if (item.slug === pathPart) {
            if (parts.length > 0) {
              // @ts-ignore
              return recursiveComparePath(item.children);
            } else {
              // @ts-ignore
              return item.page.id
            }
          }
        }
        // no match
        return null
      };

      parts.shift(); // the initial shift is to remove the first /

      // is it the first menu item? hardcoded to home
      if (parts.length === 1 && parts[0] === menu.slug) {

        pageId = menu.page.id;
      } else {

        pageId = recursiveComparePath(menu.children);
      }
    }

    if (!pageId) {
      const page = await this.getPageByRoute(path);

      if (page) {
        pageId = page.id;
      }
    }


    return new Promise(resolve => {
      resolve(pageId);
    });
  }

  async update (pageId: number, attributeSelectionToUpdate) {
    // data preperation
    attributeSelectionToUpdate.pageId = pageId;
    if (attributeSelectionToUpdate.structure) {
      attributeSelectionToUpdate.structure = JSON.stringify(attributeSelectionToUpdate.structure);
    }

    if (attributeSelectionToUpdate.data) {
      attributeSelectionToUpdate.data = JSON.stringify(attributeSelectionToUpdate.data);
    }

    if (attributeSelectionToUpdate.categories) {
      attributeSelectionToUpdate.categories = attributeSelectionToUpdate.categories.map((cat) => {
        return cat.id;
      })
    }

    // off ot the backend ;)
    const result = await firstValueFrom(this.apollo.use('app').mutate({
      mutation: UPDATE_PAGE_MUTATION,
      variables: {data: attributeSelectionToUpdate}
    })).catch((reason) => {
      this.messageService.error(this.messageService.apolloErrorToMessage(reason));
    });

    return result ? cloneDeep((result.data as any).updatePage) : null
  }

  async create (data : {
    route: string,
    title: string,
    template: string,
    permissionContext: IPermissionContext|string,
    data?: Record<string, any>|string
    categories?: ICategory[]|number[]
  }) {

    data.permissionContext = JSON.stringify(data.permissionContext);
    if (data.data) {
      data.data = JSON.stringify(data.data);
    }

    if (data.categories) {
      data.categories = data.categories.map((cat) => {
        return cat.id;
      })
    }

    const result : any = await firstValueFrom(this.apollo.use('app').mutate({
      mutation: CREATE_PAGE_MUTATION,
      variables: {data}
    })).catch((reason) => {
      this.messageService.error(this.messageService.apolloErrorToMessage(reason));
    });

    return cloneDeep(result?.data?.createPage)
  }

  async delete(pageId:  number) {
    const result : any = await firstValueFrom(this.apollo.use('app').mutate({
      mutation: DELETE_PAGE_MUTATION,
      variables: {pageId}
    })).catch((reason) => {
      this.messageService.error(this.messageService.apolloErrorToMessage(reason));
    });

    return result?.data?.deletePage
  }
}
