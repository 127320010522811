import {IWidgetDefinition} from '../../core/model/widget.model';
import countryFieldDef from '../../core/util/forms/country.select';

const countryField = {...countryFieldDef}; // this expands the country field dev into a new object
countryField.options.unshift(
    {value: 'INT-F', label: 'country.INT-F'},
    {value: 'INT-EC', label: 'country.INT-EC'},
    {value: 'INT-SC', label: 'country.INT-SC'}
)
countryField.required = true;
export default ({
    fields: [
        {
            label: 'Country or International Organization',
            ...countryField
        },
        {
            id: 'region',
            label: 'Region',
            input: 'select',
            options: [
                {value: 'international', label: 'region.international'},
                {value: 'americas', label: 'region.americas'},
                {value: 'australasia', label: 'region.australasia'},
                {value: 'europe', label: 'region.europe'}
            ],
            required: true
        },
        {
            id: 'organizationName',
            label: 'Leading Organization Name',
            input: 'text',
            required: true,
            description: 'The official name of the leading (national) organization, in case there is more then one.'
        },
        {
            id: 'notes',
            label: 'Short Notes',
            input: 'html',
            required: false
        },
        {
            id: 'supervisoryMembersIds',
            label: 'Supervisory Board Members',
            input: 'member_search',
            required: false
        },
        {
            id: 'managementMembersIds',
            label: 'Management Board Members',
            input: 'member_search',
            required: false
        }
    ]
} as IWidgetDefinition);
