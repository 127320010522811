<app-modal-frame title="{{text}}">
  <textarea slot="body" class="form-control mb-3 h-8" [class.d-none]="!collectMessage" placeholder="{{messagePlaceholder}}" id="message" #messageInput></textarea>

  <div class="d-flex justify-content-start" slot="body">

    <button *ngIf="showConfirmButton" (click)="confirm(messageInput.value)" type="button" class="btn btn-primary">Confirm</button>
    <button (click)="dismiss()" type="button" class="btn btn-outline-dark ms-2">Close</button>

  </div>

</app-modal-frame>
