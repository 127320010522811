import {Component, OnInit, Input } from '@angular/core';
import {UserService } from '../../core/services/user.service';
import {ImageProxy} from '../../core/util/image-proxy';
import {UserMenuService} from '../../core/services/user-menu.service';
import { AccessService } from '../../core/services/access.service';
import {IUser} from "../../core/model/user.model";
import {MenuService} from "../../core/services/cms/menu.service";
import {CachedStorageService} from "../../core/services/cached-storage.service";
import {IMenuItem} from "../../core/model/menuItem.model";
import {Oauth2Service} from "../../core/auth/oauth2.service";
import {IDwbnApp} from "../../core/model/dwbnApp.model";

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html'
})
export class MenuBarComponent implements OnInit {
  avatar: string;
  id: any;
  username: any;
  loading = true;
  menuOptions: IDwbnApp[] = [];

  isCollapsed: any = true;

  profileMenuPoints: any[];

  batchCount = 0;
  subscriptionBatchCount = 0;
  subscriptionsCount = 0;

  projectInvitationCount = 0;
  contactRequestCount = 0;

  menu: IMenuItem;

  isMember = false;

  @Input() caption;

  constructor(
    private userProvider: UserService,
    private accessService: AccessService,
    private imageProxy: ImageProxy,
    private userMenuService: UserMenuService,
    private menuService: MenuService,
    private cachedStorage: CachedStorageService,
    public auth: Oauth2Service
  ) {

    this.userMenuService.profileMenuObservable.subscribe(menuPoints => this.profileMenuPoints = menuPoints)

    this.userProvider.dwbnAppsObservable.subscribe(apps => this.menuOptions = apps);

    this.userProvider.projectInvitationObservable.subscribe(
      (projectInvitations) => this.projectInvitationCount = projectInvitations ? projectInvitations.length : 0
    );

    this.userProvider.fetchLoggedInUser().then(async (userData) => {

      this.userProvider.contactRequestsObservable.subscribe( contactRequests => {
        this.contactRequestCount = contactRequests ?
            contactRequests.filter( (request) => request.receiver.username === userData.username ).length : 0
      });

      this.menu = await this.cachedStorage.getAndRefresh('mainMenu', async () => {
        return await this.menuService.getMenu({ ident: 'news' });
      }, (menu) => {
        this.menu = menu;
      })

      this.loading = false;

      await this.getUserInfo(userData);
    });

    this.userProvider.subscriptionsObservable.subscribe( subscriptions => {
      this.subscriptionsCount = subscriptions.length;
      this.subscriptionBatchCount = subscriptions.filter(subscription => {
        return subscription.status === 'error';
      }).length;
      this.recalculateBatch();
    })
  }

  ngOnInit() {
  }

  private recalculateBatch () {
    // in case other batches are coming up
    this.batchCount = this.subscriptionBatchCount;
  }

  public shouldDisplayBatch (item : IMenuItem, key : string) : boolean {
    if (item.settings && item.settings[key]) {
      return this[key] > 0;
    }

    return false;
  }

  public async getUserInfo (userData: IUser) {
    this.username = userData.username;
    this.avatar = (userData.userData.picture.url ? this.imageProxy.getImageProxyUrl(userData.userData.picture.url, 90, 90) : '/assets/imgs/nophoto_member.png');
    this.isMember = await this.accessService.isValidMember();
    this.loading = false;
  }
}
