import {IFormDefinition} from '../../model/formDefinition.model';

export default ({
    id: 'language',
    input: 'select',
    options: [
        {value: 'af', label: 'language.af'},
        {value: 'sq', label: 'language.sq'},
        {value: 'ar', label: 'language.ar'},
        {value: 'ast', label: 'language.ast'},
        {value: 'az', label: 'language.az'},
        {value: 'eu', label: 'language.eu'},
        {value: 'be', label: 'language.be'},
        {value: 'bn', label: 'language.bn'},
        {value: 'bs', label: 'language.bs'},
        {value: 'br', label: 'language.br'},
        {value: 'bg', label: 'language.bg'},
        {value: 'my', label: 'language.my'},
        {value: 'ca', label: 'language.ca'},
        {value: 'hr', label: 'language.hr'},
        {value: 'cs', label: 'language.cs'},
        {value: 'da', label: 'language.da'},
        {value: 'nl', label: 'language.nl'},
        {value: 'en', label: 'language.en'},
        {value: 'en-au', label: 'language.en-au'},
        {value: 'en-gb', label: 'language.en-gb'},
        {value: 'eo', label: 'language.eo'},
        {value: 'et', label: 'language.et'},
        {value: 'fi', label: 'language.fi'},
        {value: 'fr', label: 'language.fr'},
        {value: 'fy', label: 'language.fy'},
        {value: 'gl', label: 'language.gl'},
        {value: 'ka', label: 'language.ka'},
        {value: 'de', label: 'language.de'},
        {value: 'el', label: 'language.el'},
        {value: 'he', label: 'language.he'},
        {value: 'hi', label: 'language.hi'},
        {value: 'hu', label: 'language.hu'},
        {value: 'is', label: 'language.is'},
        {value: 'io', label: 'language.io'},
        {value: 'id', label: 'language.id'},
        {value: 'ia', label: 'language.ia'},
        {value: 'ga', label: 'language.ga'},
        {value: 'it', label: 'language.it'},
        {value: 'ja', label: 'language.ja'},
        {value: 'kab', label: 'language.kab'},
        {value: 'kn', label: 'language.kn'},
        {value: 'kk', label: 'language.kk'},
        {value: 'km', label: 'language.km'},
        {value: 'ko', label: 'language.ko'},
        {value: 'lv', label: 'language.lv'},
        {value: 'lt', label: 'language.lt'},
        {value: 'dsb', label: 'language.dsb'},
        {value: 'lb', label: 'language.lb'},
        {value: 'mk', label: 'language.mk'},
        {value: 'ml', label: 'language.ml'},
        {value: 'mr', label: 'language.mr'},
        {value: 'mn', label: 'language.mn'},
        {value: 'ne', label: 'language.ne'},
        {value: 'nb', label: 'language.nb'},
        {value: 'nn', label: 'language.nn'},
        {value: 'os', label: 'language.os'},
        {value: 'fa', label: 'language.fa'},
        {value: 'pt', label: 'language.pt'},
        {value: 'pt-br', label: 'language.pt-br'},
        {value: 'pl', label: 'language.pl'},
        {value: 'pa', label: 'language.pa'},
        {value: 'ro', label: 'language.ro'},
        {value: 'ru', label: 'language.ru'},
        {value: 'gd', label: 'language.gd'},
        {value: 'sr', label: 'language.sr'},
        {value: 'sr-latn', label: 'language.sr-latn'},
        {value: 'zh-hans', label: 'language.zh-hans'},
        {value: 'sk', label: 'language.sk'},
        {value: 'sl', label: 'language.sl'},
        {value: 'es', label: 'language.es'},
        {value: 'es-ar', label: 'language.es-ar'},
        {value: 'es-co', label: 'language.es-co'},
        {value: 'es-mx', label: 'language.es-mx'},
        {value: 'es-ni', label: 'language.es-ni'},
        {value: 'es-ve', label: 'language.es-ve'},
        {value: 'sw', label: 'language.sw'},
        {value: 'sv', label: 'language.sv'},
        {value: 'ta', label: 'language.ta'},
        {value: 'tt', label: 'language.tt'},
        {value: 'te', label: 'language.te'},
        {value: 'th', label: 'language.th'},
        {value: 'zh-hant', label: 'language.zh-hant'},
        {value: 'tr', label: 'language.tr'},
        {value: 'udm', label: 'language.udm'},
        {value: 'uk', label: 'language.uk'},
        {value: 'hsb', label: 'language.hsb'},
        {value: 'ur', label: 'language.ur'},
        {value: 'vi', label: 'language.vi'},
        {value: 'cy', label: 'language.cy'}
    ]
} as IFormDefinition)
