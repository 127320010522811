import {Component, OnInit} from '@angular/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import {TranslationHelper} from "../../core/util/translation.helper";
import {ImageTilingHelper} from "../../core/util/image-tiling.helper";
import {IAppImage} from "../../core/model/app-image.model";
import {VideoHelperService} from "../../core/services/video-helper.service";
import {IAppVideo} from "../../core/model/app-video.model";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html'
})
export class PostComponent implements OnInit, DWCWidgetComponent {

  colSizes: object;
  data: any;
  display: string;
  id: number;
  type: string;

  preview: string

  maxLength = 120
  isShortened = false

  cover: IAppImage

  video: IAppVideo

  constructor(
      public translationHelper: TranslationHelper,
      public imageTilingHelper: ImageTilingHelper,
      private videoHelper: VideoHelperService
  ) {
  }

  ngOnInit() {
    this.preview = this.translationHelper.getBestLanguageValue(this.data.content).replace(/<\/?[^>]+(>|$)/g, ' ');
    this.isShortened = this.preview.length > this.maxLength;
    this.preview = this.preview.substring(0, this.maxLength) + (this.isShortened ? '...' : '');

    if (this.data.includeVideo) {

        this.video = {
            type: this.data.videoType,
            src: this.data.videoSrc,
            cover: this.data.videoCover,
            m3u8: this.data.videoM3u8,
            aspectRatio: this.data.videoAspectRatio,
        }

        this.cover = this.videoHelper.getVideoCover(this.video);
    } else if (this.data.images?.length) {
        this.cover = this.data.images[0].image;
    }

    if (!this.data.created) {
        this.data.created = new Date();
    }
  }

}
