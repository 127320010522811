import gql from 'graphql-tag';

export const PageFragment = gql`
  fragment PageFragment on Page {
    id
    title
    template
    route
    data
    structure
    widgets {
      id
      type
      data
      preventUnsubscribe
    }
    categories {
      id
      name
      bundle
    }
    visibility {
      all
      center
      project
    }
    editAllowed
  }`;

export const UPDATE_PAGE_MUTATION = gql`
  mutation updatePage($data: UpdatePageInput!) {
    updatePage (input: $data) {
       ...PageFragment
    }
  }
  ${PageFragment}
`;

export const CREATE_PAGE_MUTATION = gql`
  mutation createPage($data: CreatePageInput!) {
    createPage (input: $data) {
       ...PageFragment
    }
  }
  ${PageFragment}
`;

export const DELETE_PAGE_MUTATION = gql`
mutation deletePage($pageId: Int!) {
  deletePage(id: $pageId)
}
`;
