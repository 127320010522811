import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit, DWCWidgetComponent {

  data: any;
  id: number;
  type: string;
  colSizes: object; // information how wide the wrapper column of the component is

  constructor() { }

  ngOnInit() {}

}
