import { IFollowable } from 'src/app/core/model/followable.model';

export interface INewsFilter {
  ref: NewsFilterRef,
  displayName: string,
  followables: IFollowable[], // post parents to filter by
  current?: boolean
}

export type NewsFilterRef = string;  // unique identifier of the filter, eg. 'letters'. Not for display.

export function createNewsFilters(filtersConfiguredOnWidget: string, requestedRef: NewsFilterRef): INewsFilter[] {

  // we expect serialized JSON from the widget data so the filters can be defined in the CMS
  let filters = JSON.parse(filtersConfiguredOnWidget);

  // prepend the default filter to the filters configured on the widget
  const filtersWithDefault = [].concat(defaultFilter, filters);

  // set which filter is current
  return setCurrentOnFilters(filtersWithDefault, requestedRef);
}

export function followablesFromCurrentNewsFilter(filters: INewsFilter[]): IFollowable[] {
  const currentFilter = filters.filter(f => f.current === true)[0];
  return currentFilter.followables;
}

const defaultFilter: INewsFilter = {
  ref: 'all',
  displayName: 'All',
  followables: []
}

function setCurrentOnFilters(filters: INewsFilter[], requestedRef: NewsFilterRef): INewsFilter[] {
  // validate the ref because we don't know if it corresponds to a defined filter
  const validatedRequestedRef = validateFilterRef(filters, requestedRef, defaultFilter);

  // mark each filter as current or not
  return filters.map(f => {
    f.current = validatedRequestedRef === f.ref;
    return f;
  })
}

function validateFilterRef(filters: INewsFilter[], requestedRef: NewsFilterRef, defaultFilter: INewsFilter): NewsFilterRef {
  // see if the requested ref matches a defined filter
  const matchingFilter = filters.filter(f => f.ref === requestedRef )[0]

  // if it doesn't, return the default filter as the ref to use
  return matchingFilter ? matchingFilter.ref : defaultFilter.ref;
}
