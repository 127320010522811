import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap/modal/modal-config";
import {StructureService} from "../../services/cms/structure.service";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private defaultOptions : NgbModalOptions = { size: 'lg', centered: true, modalDialogClass: 'modal-dialog--dwc', scrollable: true }

  constructor(
    private ngbModalService: NgbModal,
    private structureService: StructureService,
  ) {

    this.structureService.currentPageObservable.subscribe(() => {
      // if we change the page, all modals need to be closed
      this.dismiss();
    })

  }

  public open (component: any, componentProps: Record<string, any> = {}, options: NgbModalOptions = {}) : NgbModalRef {
    const finalOptions = Object.assign({}, this.defaultOptions, options);

    const modalRef = this.ngbModalService.open(component, finalOptions);

    Object.keys(componentProps).forEach((key) => {
      modalRef.componentInstance[key] = componentProps[key];
    });

    return modalRef;
  }

  dismiss() {
    this.ngbModalService.dismissAll();
    this.structureService.endOverlayAjax();
  }
}
