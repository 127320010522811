<div class="menu-container"
  [ngClass]="{
    'pb-2': data.display === 'horizontal'
  }"
>
  <h2
    *ngIf="translationHelper.getBestLanguageValue(data.headline)"
    class="mt-0"
  >
    {{ translationHelper.getBestLanguageValue(data.headline) }}
  </h2>
  <ul
    class="d-flex mb-0 list-unstyled border-primary"
    [ngClass]="{
      'flex-wrap border-bottom': data.display === 'horizontal',
      'flex-column': data.display === 'vertical',
      'pb-3': data.size === 'large',
      'pb-2': data.size === 'standard',
      'pb-1': data.size === 'small',
    }"
  >
    <li *ngFor="let entry of data.entries" class="me-lg-4 me-3">
      <a
        href="{{ entry.route }}"
        [routerLink]="entry.route"
        [ngClass]="{
          'd-flex align-items-center': data.display === 'horizontal',
          'd-inline-block': data.display === 'vertical'
        }"
      >
        <app-icon
          *ngIf="entry.icon"
          [icon]="entry.icon"
          class="me-1 me-lg-2"
          [ngClass]="{
            'fs-5': data.size === 'standard',
            'fs-1': data.size === 'large'
          }"
        ></app-icon>
        <span
          [ngClass]="{
            'fs-7 fw-medium': data.size === 'small',
            'fs-6 fw-medium': data.size === 'standard',
            'fs-2 fw-medium': data.size === 'large'
          }"
          [routerLinkActive]="['text-decoration-underline']"
          [routerLinkActiveOptions]="{ exact: true }"
          >{{ translationHelper.getBestLanguageValue(entry.label) }}</span
        >
      </a>
    </li>
  </ul>
</div>
