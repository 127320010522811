import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {ImageProxy} from '../../core/util/image-proxy';
import {IUser} from '../../core/model/user.model';
import {RoutingHelper} from '../../core/util/routing.helper';

@Component({
  selector: 'app-member-preview',
  templateUrl: './member-preview.component.html',
  styleUrls: [],
})
export class MemberPreviewComponent implements OnInit {

  @Input() member: IUser;
  @Input() linkToProfile = true;
  @Input() tooltip = true;
  @Input() standalone = false;

  isYourContact = false;
  isYou = false;

  constructor(
      public userProvider: UserService,
      public imageProxy: ImageProxy,
      public routingHelper: RoutingHelper
  ) { }

  ngOnInit() {
    this.isYourContact = this.userProvider.userIsYourContact(this.member.id);
    this.userProvider.fetchLoggedInUser().then(user => {
      this.isYou = user.id === this.member.id;
    });
  }

}
