import {
    AfterViewChecked,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DWCWidgetComponent} from 'src/app/core/model/dwc-widget.model';
import {IWidget} from 'src/app/core/model/widget.model';
import {ICenter} from 'src/app/core/model/center.model';
import {IPage} from 'src/app/core/model/page.model';
import {IProject} from 'src/app/core/model/project.model';
import {INewsPostsCriteria, PostsService} from '../../core/services/posts.service';
import {UserService} from '../../core/services/user.service';
import {PostHelper} from 'src/app/core/util/post.helper';
import {ActivatedRoute} from '@angular/router';
import {followablesFromCurrentNewsFilter, INewsFilter, createNewsFilters, NewsFilterRef} from './news-filter.helper';
import {IPost} from 'src/app/core/model/post.model';
import {ImageTilingHelper} from 'src/app/core/util/image-tiling.helper';
import {RoutingHelper} from '../../core/util/routing.helper';

// for the image attachments
import { ModalService } from 'src/app/core/util/ui/modal.service';

// for talking to the GenericPage about the scroll position
import {IntercomService} from '../../core/services/intercom.service';
import {Subscription} from 'rxjs';
import {TranslationHelper} from '../../core/util/translation.helper';
import {InspirationComponent} from "../inspiration/inspiration.component";


@Component({
    selector: 'app-news-feed',
    templateUrl: './news-feed.component.html',
    encapsulation: ViewEncapsulation.None, // in order to style the innerHTML post content
})

export class NewsFeedComponent implements OnInit, OnDestroy, AfterViewChecked, DWCWidgetComponent {

    @ViewChild('feed') feed: ElementRef;

    id: number;
    type: string;
    data: any;
    colSizes: object;
    display?: string;
    widgetList?: IWidget[];
    posts: IPost[] = [];
    newsFilters: INewsFilter[];
    queryCriteria: INewsPostsCriteria;
    suggestFollowing: boolean;
    filteredFollowableTitle: string;
    filteredFollowableRoute: string;

    $intercom: Subscription;

    observedPosts: Record<number, string> = {};
    intersectionObserver: IntersectionObserver;

    routeOfWidget: string;

    constructor(
        private postsService: PostsService,
        private postHelper: PostHelper,
        public userProvider: UserService,
        public routingHelper: RoutingHelper,
        private modalService: ModalService,
        public imageTilingHelper: ImageTilingHelper,
        private route: ActivatedRoute,
        private intercomService: IntercomService,
        public translationHelper: TranslationHelper,
    ) {

    }

    ngOnInit() {

        this.routeOfWidget = this.routingHelper.getCurrentPageRoute();

        // this gets triggered on initial load and whenever the query params change
        this.route.queryParams.subscribe(params => {
            this.initializeState(params.filterRef);
        })

        // this gets triggered when scrolling to the bottom of the page
        this.$intercom = this.intercomService.subject.subscribe(message => {
            if (message === 'scrolled-to-the-end' && this.routeOfWidget === this.routingHelper.getCurrentPageRoute()) {
                this.queryCriteria = this.pageForward(this.queryCriteria);
                this.fetchAndAppendPosts();
            }
        });

        // this observer triggers view events on scroll - if a member starts to view a certain post
        this.intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {

                        const postId = parseInt(entry.target.getAttribute('data-post-id'), 10);

                        if (this.observedPosts[postId] === 'n') {
                            this.posts.find(post => post.id === postId).views++;
                        }

                        if (this.observedPosts[postId] !== 'x') {
                            // trigger the post view
                            this.postsService.postView(postId).then(viewCount => {
                                if (viewCount) {
                                    this.posts.find(post => post.id === postId).views = viewCount;
                                }
                            })

                            // mark as ignore and forget
                            this.observedPosts[postId] = 'x';
                            this.intersectionObserver.unobserve(entry.target);
                        }
                    }
                });
            },
            {threshold: 0.2}
        );
    }

    ngAfterViewChecked() {
        this.posts.forEach((post) => {
            if (!this.observedPosts[post.id]) {
                this.observedPosts[post.id] = post.lastViewed ? 'u' : 'n'; // u - observe update, n - observe new

                this.intersectionObserver.observe(this.feed.nativeElement.querySelector('#post-' + post.id));
            }
        })
    }

    ngOnDestroy() {
        if (this.$intercom) {
            this.$intercom.unsubscribe();
        }

        this.intersectionObserver.disconnect();
    }

    private initializeState(filterRef: NewsFilterRef): void {
        this.newsFilters = createNewsFilters(this.data.filterConfig, filterRef);

        this.queryCriteria = {
            followables: followablesFromCurrentNewsFilter(this.newsFilters),
            limit: 10,
            offset: 0
        };

        this.posts = [];

        this.fetchAndAppendPosts();

        const filteredFollowable = followablesFromCurrentNewsFilter(this.newsFilters)[0]
        // taking the first one because we originally thought a filter might correspond to multiple followables.
        // but this now looks unnecessary, and we will assume there is only one. we should change the code everywhere to adjust for this

        if (filteredFollowable) {
            this.userProvider.isFollowing(
                filteredFollowable.followedEntityType, filteredFollowable.followedEntityId.toString()
            ).then((following) => {
                this.suggestFollowing = !following
            });
            this.postsService.fetchParent(
                filteredFollowable.followedEntityType, filteredFollowable.followedEntityId.toString()
            ).then((followable) => {
                this.filteredFollowableTitle = this.getFollowableTitle(filteredFollowable.followedEntityType, followable);
                this.filteredFollowableRoute = this.getFollowableRoute(filteredFollowable.followedEntityType, followable);
            });
        } else {
            this.suggestFollowing = false;
        }
    }

    private getFollowableTitle(followableType: string, followable: IProject | IWidget | ICenter | IPage): string {
        // note this is almost an exact duplicate of the method in PostHelper
        switch (followableType) {
            case 'Project':
                return (followable as IProject)?.name;
            case 'Widget':
                return (followable as IWidget)?.pages[0]?.title;
            case 'Center':
                return (followable as ICenter)?.displayName;
            case 'Page':
                return (followable as IPage)?.title;
            default:
                return null
        }
    }

    private getFollowableRoute(followableType: string, followable: IProject | IWidget | ICenter | IPage): string {
        // note this is almost an exact duplicate of the method in PostHelper
        switch (followableType) {
            case 'Project':
                return this.routingHelper.getProjectRoute(followable as IProject);
            case 'Widget':
                return (followable as IWidget)?.pages[0]?.route;
            case 'Center':
                return this.routingHelper.getCenterRoute(followable as ICenter);
            case 'Page':
                return (followable as IPage)?.route;
            default:
                return null
        }
    }

    private fetchAndAppendPosts(): void {
        this.postsService.getNewsPosts(this.queryCriteria).then((posts) => {
            const preparedPosts = posts.map(post => this.postHelper.prepareForPresentation(post));
            this.posts = this.posts.concat(preparedPosts);
            this.resumeScrollEvents(preparedPosts);
        });
    }

    private resumeScrollEvents(preparedPosts: IPost[]): void {
        // GenericPage stops sending scrolled-to-the-end events
        // after the first one. We need to tell it when it's okay
        // to start sending them again.

        // If we got a full batch of posts, then we're ready to scroll down again.
        if (preparedPosts?.length === this.queryCriteria.limit) {
            this.intercomService.sendMessage('resume-firing-scrolled-to-bottom-events');
        }
    }

  async openModal(post: IPost, imageIndex: number) {

    const inspirations : IWidget[] = post.images.map((img, i) => {
      return {
        id: i,
        type: 'inspiration',
        data: {
          title: img.title || this.translationHelper.getBestLanguageValue(post.title),
          images: [img]
        }
      }
    })

    this.modalService.open(InspirationComponent, {
        data: inspirations[imageIndex].data,
        display: 'modal',
        widgetList: inspirations,
        id: inspirations[imageIndex].id,
        type: inspirations[imageIndex].type,
        clickedWidgetId: imageIndex
      },
      {
        size: "xl",
        modalDialogClass: 'modal-dialog--inspiration'
      }
    );

  }

    private pageForward(criteria: INewsPostsCriteria): INewsPostsCriteria {
        return {
            followables: criteria.followables,
            limit: criteria.limit,
            offset: criteria.offset + criteria.limit
        }
    }
}
