import {IWidgetDefinition} from '../../core/model/widget.model';

export default {
  icon: {style: 'fa-solid', name: 'chart-bar'},
  category: 'special',
  fields: [
    {
      id: 'buttonText',
      label: 'Enter the text of the button:',
      input: 'text',
      i18n: true,
    },
    {
      id: 'color',
      input: 'select',
      label: 'Button Color',
      default: 'primary',
      width: 4,
      options: [
        { label: 'Red', value: 'primary' },
        { label: 'Yellow', value: 'secondary' },
        { label: 'Light Gray', value: 'light' },
        { label: 'Medium Gray', value: 'medium' },
        { label: 'Dark Gray', value: 'dark' },
      ]
    },
    {
      id: 'fill',
      input: 'select',
      label: 'Button Style',
      default: 'solid',
      width: 4,
      options: [
        { label: 'Solid', value: 'solid' },
        { label: 'Outline', value: 'outline' },
        { label: 'Text only', value: 'clear' }
      ]
    },
    {
      id: 'align',
      input: 'select',
      width: 4,
      options: [
        { label: 'Left', value: 'align-left' },
        { label: 'Center', value: 'align-center' },
        { label: 'right', value: 'align-right' },
      ],
    },

    {
      id: 'formUrl',
      label: 'Display Url of the google form',
      input: 'text',
      description: 'You can use a prefill url, as <a href="https://support.google.com/a/users/answer/9308781?hl=en" target="_blank">described here</a>. Please use the url without dynamic parameters, they can be added below.',
      required: true
    },
    {
      id: 'parameterMapping',
      label: 'Form Parameter Mapping (field to data of the current user)',
      input: 'complex-list',
      subtype: [
        {
          id: 'param',
          label: 'Prefill Form Parameter Name (eg. entry.2005620554)',
          input: 'text'
        },
        {
          id: 'userProperty',
          label: 'Property name of the user for data prefill',
          input: 'text'
        }
      ]
    }
  ],
} as IWidgetDefinition;
