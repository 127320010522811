import {ImageComponent} from './image.component';
import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    getComponent: () => ImageComponent,
    category: 'content',
    icon: { style: 'fa-regular', name: 'image'},
    fields: [
        {
            id: 'image',
            input: 'image_upload',
            multiple: false,
            required: true
        },
        {
            id: 'title',
            input: 'text',
            i18n: true,
            description: 'If the title is set, it will appear on the bottom left of the picture with a gradient. Leave this field empty, if just the picture should be visible.'
        },
        {
          id: 'description',
          input: 'html',
          i18n: true,
          description: 'The description appears underneath the image.'
        },
        {
            id: 'linkToPage',
            input: 'text' // @todo, should be a page selector input at some point
        },
        {
            id: 'maxWidth',
            input: 'text',
            width: 6
        },
        {
            id: 'manualAspectRatio',
            label: 'optional manual aspect ratio',
            description: 'aspect ratio is calculated height / width (1 is a square image)',
            input: 'number',
            width: 6
        }
    ]
} as IWidgetDefinition)
