<footer *ngIf="!userProvider.asPlainContentPage" class="footer text-bg-primary fs-7">
  <div class="container">
    <div class="px-lg-4 py-3">
        <div class="row">
          <div class="col-12 col-md-6 text-center text-md-start">
            <span>&copy; {{ copyRightYear }} - Diamond Way Buddhism Foundation, v{{version}}</span>
          </div>
            <div class="col-12 col-md-6">
              <ul class="nav justify-content-md-end justify-content-center">

                <li *ngIf="envService.currentEnv.features.showStyleguide" class="nav-item">
                  <a href="/styleguide" class="nav-link" routerLink="/styleguide" [routerLinkActive]="['active']">Dev Styleguide</a>
                </li>

                <li class="nav-item"><a routerLink="/privacy" [routerLinkActive]="['active']" class="nav-link">Privacy policy</a></li>
              </ul>
            </div>
        </div>
    </div>
  </div>
</footer>
