<app-modal-frame [title]="(project?.autoMembership?.exclusive || !hasMembersAccess ? 'projects.titles.editTags' : 'projects.titles.editOverlay') | translate:{projectName: project?.name}">

  <div slot="body">

    <p *ngIf="project?.autoMembership?.exclusive" class="mt-3">
      This project is configured as exclusive project and members are added via specific include rules in the backend.
    </p>

    <div class="position-relative">
      <input #searchInput (keyup)="onKeyUpFilterAndSearch(searchInput.value)" class="form-control" placeholder="Search and filter by name"/>

      <div *ngIf="loadingMembers || loadingSearch" class="position-absolute top-50 end-0 translate-middle-y pe-2">
        <div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="searchMessage" *ngIf="searchMessage.length > 0"> {{searchMessage}} </div>
    </div>

    <div *ngIf="searchInput.value && searchInput.value.length > 2 && !loadingSearch"
         class="border border-medium rounded-2 mt-2 overflow-hidden"
    >
      <div *ngFor="let member of membersListFromSearch;let indexOfelement=index" (click)="addToProject(member, indexOfelement)" class="d-flex align-items-center p-1 cell--hover justify-content-between cursor-pointer">
        <div class="d-flex align-items-center">
          <app-member-preview [member]="member" [linkToProfile]="false" [tooltip]="false" [standalone]="true"></app-member-preview>
          <div class="ms-2">
            <app-highlighted-string string="{{member.givenName + ' ' + member.familyName}}" [toHighlight]="searchInput.value.toString()"/><span *ngIf="member.center" class="text-medium">, <app-highlighted-string string="{{member.center?.name}}" [toHighlight]="searchInput.value.toString()"/></span>
          </div>
        </div>
        <span class="btn btn-sm btn-primary">Add To Project</span>
      </div>
      <i *ngIf="requestError" class="p-2">
        <strong>Error adding user</strong>
        <div>{{this.errorMessage}}</div>
      </i>
      <i *ngIf="!membersListFromSearch.length" class="p-2">
        Could not find any member for <strong>{{searchInput.value}}</strong>
      </i>
    </div>

    <div *ngIf="!loadingMembers">

      <div *ngIf="allInvitedProjectMembers.length > 0" class="mt-3">
        <strong class="category-title">Invited<span *ngIf="allInvitedProjectMembers.length !== invitedProjectMembers.length"> ({{invitedProjectMembers.length}}/{{allInvitedProjectMembers.length}})</span>: </strong>
        <div *ngFor="let member of invitedProjectMembers" class="d-flex align-items-center p-1 cell--hover justify-content-between border-bottom">

          <div class="d-flex align-items-center">
            <app-member-preview [member]="member.user" [tooltip]="false" [standalone]="true"></app-member-preview>
            <div class="ms-2">
              <app-highlighted-string string="{{member.user.givenName + ' ' + member.user.familyName}}" [toHighlight]="searchInput.value.toString()"/><span *ngIf="member.user.center" class="text-medium">, <app-highlighted-string string="{{member.user.center?.name}}" [toHighlight]="searchInput.value.toString()"/></span>
              <div class="mt-1">
                <span *ngIf="member.user.enabled === false" class="badge bg-dark text-white text-uppercase me-2 py-1 px-2">
                  Guest (disabled account)
                </span>
                <app-membership-labels [availableLabels]="availableTags" [membership]="member" [projectId]="project.id"></app-membership-labels>
              </div>
            </div>
          </div>

          <button *ngIf="hasMembersAccess || member.user.id === user.id"
                  (click)="member.user.id === user.id ? userProvider.leaveProject(project.id) : confirmRemoveMember(member.user)"
                  class="btn btn-sm btn-outline-primary"
          >
            <span *ngIf="member.user.id !== user.id">Revoke Invitation</span>
            <span *ngIf="member.user.id === user.id">Decline</span>
          </button>
        </div>
      </div>

      <div class="mt-3">
        <strong class="mb-3">Project Members<span *ngIf="allProjectMembers.length !== projectMembers.length"> ({{projectMembers.length}}/{{allProjectMembers.length}})</span>:</strong>
        <div *ngIf="allProjectMembers.length > 0; else noMembers">
          <div *ngFor="let member of projectMembers" class="d-flex align-items-center p-1 cell--hover justify-content-between border-bottom">

            <div class="d-flex align-items-center">
              <app-member-preview [member]="member.user" [tooltip]="false" [standalone]="true"></app-member-preview>
              <div class="ms-2">
                <app-highlighted-string string="{{member.user.givenName + ' ' + member.user.familyName}}" [toHighlight]="searchInput.value.toString()"/><span *ngIf="member.user.center" class="text-medium">, <app-highlighted-string string="{{member.user.center?.name}}" [toHighlight]="searchInput.value.toString()"/></span>
                <div class="mt-1">
                  <span *ngIf="member.user.enabled === false" class="badge bg-dark text-white text-uppercase me-2 py-1 px-2">
                    Guest (disabled account)
                  </span>
                  <span *ngIf="member.status === 2" class="badge bg-primary me-2 py-1 px-2">
                    Admin
                    <button *ngIf="adminAccessAllowed(member)" (click)="changeMembershipStatus(member, member.status === 2 ? 1 : 2)" title="Remove Admin status" type="button" class="btn btn-text"><app-icon [icon]="{style: 'fa-solid', name: 'xmark'}"/></button>
                  </span>
                  <app-membership-labels [availableLabels]="availableTags" [membership]="member" [projectId]="project.id"></app-membership-labels>
                </div>
              </div>
            </div>

            <div class="d-grid gap-2">
               <button *ngIf="adminAccessAllowed(member)"
                       (click)="changeMembershipStatus(member, member.status === 2 ? 1 : 2)"
                       class="btn btn-sm btn-primary">
                 {{member.status === 2 ? 'Make Member' : 'Make Admin'}}
              </button>
              <button *ngIf="memberAccessAllowed(member)"
                      (click)="member.user.id === user.id ? userProvider.leaveProject(project.id) : confirmRemoveMember(member.user)"
                      class="btn btn-sm btn-outline-primary"
              >
                <span *ngIf="member.user.id !== user.id">Remove</span>
                <span *ngIf="member.user.id === user.id">Leave Project</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ng-template #noMembers>
        <h1 class="noResults">No Members</h1>
      </ng-template>
    </div>
  </div>

</app-modal-frame>
