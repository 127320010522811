import { Injectable } from '@angular/core';


export interface IButtonDef {
  color: 'primary' | 'secondary' | 'light' | 'medium' | 'dark',
  fill: 'solid' | 'outline' | 'clear'
}


@Injectable({
  providedIn: 'root'
})
export class ButtonHelper {

  constructor() { }

  getButtonDef (color: string, fill : string) : IButtonDef {

    if (color === 'secondary') {
      color = 'yellow';
    }

    return {
      color: (color as 'primary' | 'secondary' | 'light' | 'medium' | 'dark') || 'primary',
      fill : (fill as 'solid' | 'outline' | 'clear') || 'solid'
    }
  }

  buildButtonClass(def: IButtonDef) {
    const classes = ['btn'];

    if (def.fill === 'clear') {
      classes.push('btn-link');

      if (def.color !== 'primary') {
        classes.push('text-' + def.color);
      }
    } else if (def.fill === 'outline') {
      classes.push('btn-outline-' + def.color);
    } else {
      classes.push('btn-' + def.color);
    }

    return classes.join(' ');
  }




}
